export const workflowToken = {
  codeSnippet: "Code Snippet",
  create: "Create token",
  createTitle: "Create token",
  deleted: "Token '{name}' deleted.",
  details: "Token details",
  name: "Name",
  noTokens: "No tokens found",
  remove: "Delete '{name}'?",
  saved: "Token '{name}' saved.",
  title: "Token",
  token: "Token",
  tokenPlaceholder: "Please select at least one token",
  whitelistIps: "Whitelist IP's",
  whitelistingActive: "IP Check enabled"
};
