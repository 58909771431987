export const communication = {
  bcc: "BCC",
  cc: "CC",
  code: "Code",
  content: "Content",
  dateFrom: "Date from",
  dateTo: "Date to",
  datetime: "Date/Time",
  details: "Details",
  email: "E-Mail address",
  filter: "Filter",
  information: "Basic Information",
  mailableClassname: "Classname",
  parameter: "Parameter",
  preview: "Preview",
  receiver: "Receiver",
  recipient: "Recipient",
  replyTo: "Reply to",
  search: "Search",
  selectedFilter: "Selected filter",
  selectFilter: "Select filter",
  sender: "Sender",
  subject: "Subject",
  title: "Communication",
  toDetail: "To detail page"
};
