export const deltaStatus = {
  configuration: {
    status: "Mark as",
    statusOptions: {
      clean: "Clean",
      dirty: "Dirty"
    }
  },
  input: {
    object: "Object"
  },
  error: {
    error_handling: "Error handling",
    error_handlingOptions: {
      process_continues: "Workflow continues",
      process_aborts: "Workflow aborts"
    }
  }
};
