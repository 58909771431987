export const cache = {
  configuration: {
    validTime: "Validity period",
    fieldToValidate: "Field to validate"
  },
  input: {
    dataToValidate: "Data to be validate"
  },
  error: {
    log_level: "Log Level",
    log_levelOptions: {
      process: {
        info: "Info",
        warning: "Warning",
        error: "Error"
      }
    },
    error_handling: "Error handling",
    error_handlingOptions: {
      process_continues: "Workflow continues",
      process_aborts: "Workflow aborts"
    }
  }
};
