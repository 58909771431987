export const support = {
  client: "Client: {value}",
  comment: "Comment",
  contact: "Contact",
  contactPerson: "Contact person: {value}",
  contactPersons: "Contact persons",
  email: "E-Mail-Address",
  files: "Files",
  name: "Name",
  partner: "Partner: {value}",
  requestTo: "Request to {to}",
  saved: "Supportticket created",
  subject: "Subject",
  title: "Support"
};
