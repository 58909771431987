export const processCodeEditor = {
  processCodeEditor: "Code-Editor",
  save: "Speichern",
  className: "Klassenname",
  temporaryClassName: "Temporärer Klassenname",
  updated: "Prozesscode gespeichert",
  lastModifiedBy: "Zuletzt geändert von",
  lastModifiedOn: "Zuletzt geändert am",
  showCommits: "Commits anzeigen",
  showDiffs: "Unterschiede zeigen",
  showFileContent: "Dateiinhalt anzeigen",
  commitedDate: "Committed am",
  authorName: "Autorenname",
  authorEmail: "Autor E-Mail",
  commitShortId: "Commit kurze id",
  totalCommits: "Gesamt commits",
  commitsTable: "Commits-Tabelle",
  fileDifferences: "Datei-Unterschiede",
  gitFileContent: "Git-Dateiinhalt",
  copyAboveCodeToActualCode:
    "Kopieren Sie den obigen Code und fügen Sie ihn in den eigentlichen Code ein",
  history: "Historie",
  copyParameter:
    "Kopieren Sie die Parameterdaten aus diesem Job in die Zwischenablage",
  parametersJsonFormat: "Parameters (JSON format)",
  runJob: "Ausführen",
  status: "Status",
  startedBy: "Gestartet von",
  queue: "Warteschlange",
  runnedTime: "Laufzeit",
  gitProjectNotFound:
    "Wir können das Git-Projekt für diesen Mandanten nicht finden. Bitte stellen Sie sicher, dass das Projekt existiert und der Projekt-Slug mit der Tenant-UUID übereinstimmt",
  noParametersFound: "Keine parameters gefunden"
};
