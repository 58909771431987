import ApiService from "@/core/services/api.service";
// import store from "@/core/services/store";
/*
ApiService.setHeader("x-api-key", store.getters.apiToken);
if (store.getters.apiTokenPartner) {
  ApiService.setHeader(
      "x-partner-key",
      store.getters.apiTokenPartner
  );
}
*/
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}

class Project {
  getAll(params, apiKey = "", cancelToken) {
    let optionalHeaders = {};

    if (apiKey.length) optionalHeaders = this.formatOptionalHeaders(apiKey);
    let options = new URLSearchParams(params).toString();
    if (cancelToken) {
      optionalHeaders.cancelToken = cancelToken;
    }
    return ApiService.get(url, "projects?" + options, optionalHeaders);
  }

  get(id, apiKey = "") {
    let optionalHeaders = {};
    if (apiKey.length) optionalHeaders = this.formatOptionalHeaders(apiKey);
    return ApiService.get(url, "projects/" + id, optionalHeaders);
  }

  store(data, apiKey = "") {
    let optionalHeaders = {};
    if (apiKey.length) optionalHeaders = this.formatOptionalHeaders(apiKey);
    return ApiService.post(url + "/projects", data, optionalHeaders);
  }

  update(id, data, apiKey = "") {
    let optionalHeaders = {};
    if (apiKey.length) optionalHeaders = this.formatOptionalHeaders(apiKey);
    return ApiService.post(url + "/projects/" + id, data, optionalHeaders);
  }

  delete(id, apiKey = "") {
    let optionalHeaders = {};
    if (apiKey.length) optionalHeaders = this.formatOptionalHeaders(apiKey);
    return ApiService.delete(url + "/projects/" + id, optionalHeaders);
  }

  getWorkflows(params, id, apiKey = "") {
    let optionalHeaders = {};
    if (apiKey.length) optionalHeaders = this.formatOptionalHeaders(apiKey);
    const options =
      "?page=" + params.page + "&perPage=" + params.size + "&project_id=" + id;
    return ApiService.get(url + "/workflows" + options, optionalHeaders);
  }

  formatOptionalHeaders(apiKey) {
    return {
      headers: {
        "x-api-key": apiKey
      }
    };
  }
}

export default new Project();
