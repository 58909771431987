export const processLog = {
  iterationFinishedAt: "Finished at",
  iterationHideDetails: "Hide job details",
  iterationId: "Job ID",
  iterationLoading: "Loading job details...",
  iterationParameters: "Parameters",
  iterationQueue: "Queue",
  iterationStartedAt: "Started at",
  iterationStartedBy: "Started by",
  iterationStatus: "Status",
  logCreatedAt: "Created at",
  logId: "Log ID",
  logMessage: "Message",
  logParameters: "Parameters",
  logType: "Type",
  logUpdatedAt: "Updated at",
  noJobs: "No jobs available.",
  noProcesses: "No processes available.",
  processLogs: "Process logs",
  selectJob: "Select job",
  selectProcess: "Select process",
  tabDetailsTitle: "Job details",
  tabLogLoading: "Loading log details..."
};
