export const validation = {
  unique: {
    email: "This email has already been taken",
    name: "This name has already been taken",
    workspace: "The workspace '{domain}' has already been taken"
  },
  required: {
    email: "The email address is required",
    name: "The name is required",
    user: "The user is required",
    firstname: "The Firstname is required",
    lastname: "The Lastname is required",
    display_name: "The Display Name is required",
    recipient: "The recipient is required",
    mailable_classname: "The Mail Class is required",
    code: "The code is required",
    password: "The password is required",
    ipAddress: "The IP Address is required",
    queryName: "The query name is required",
    label: "The description is required",
    general: "{label} is required",
    token: "The Token is required",
    technicalName: "Technical name is required",
    route: "The route is required",
    ipAddresses: "IP-Addresses are required",
    title: "Title is required",
    note: "Note is required"
  },
  email: {
    email: "The email address is invalid",
    recipient: "The recipient is invalid"
  },
  phone: {
    phone: "The phone number is invalid."
  },
  pleaseCorrectData:
    "The data provided was invalid. Please correct the fields marked in red",
  minChars: "Please insert minimum {chars} characters",
  maxChars: "Please insert maximum {chars} characters",
  samePassword: "Passwords must be identical.",
  dates: "The end date must be greater than the start date.",
  alphaNum: "The value must be alphanumeric",
  upLowCase:
    "The value must contain at least one uppercase and one lowercase letter",
  dataStructure: {
    classname: {
      validation: {
        unique: "The class name already exists ! Must be unique"
      }
    },
    tablename: {
      validation: {
        unique: "The table name already exists ! Must be unique"
      }
    },
    label: {
      validation: {
        unique: "The label is required"
      }
    }
  }
};
