import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";
import MenuService from "@/core/services/menu.service";

/*
ApiService.setHeader("x-api-key", store.getters.apiToken);
if (store.getters.apiTokenPartner) {
  ApiService.setHeader(
      "x-partner-key",
      store.getters.apiTokenPartner
  );
}
*/

const url = process.env.VUE_APP_API_ADMIN;
const urlTenant = process.env.VUE_APP_API_TENANT;

class Clients {
  get(id) {
    let area = MenuService.getActiveArea();

    const userType = store.getters.userType;
    if (
      userType === "admin" &&
      !["areaTenant", "areaPartner"].includes(area.name)
    ) {
      return ApiService.get(url, "clients/" + id);
    } else if (userType === "partner") {
      const partner = store.getters.selectedPartner;
      return ApiService.get(
        urlTenant,
        "clients/" + id,
        this.formatOptionalHeaders(partner.api_token)
      );
    }
    return ApiService.get(urlTenant, "clients/" + id);
  }

  getAll(params, partnerId = null, cancelToken = undefined) {
    let area = MenuService.getActiveArea();

    if (partnerId != null) {
      params.partner_id = partnerId;
    }
    let options = new URLSearchParams(params).toString();
    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }
    if (
      store.getters.userType === "admin" &&
      !["areaTenant", "areaPartner"].includes(area.name)
    ) {
      return ApiService.get(url, "clients?" + options, headers);
    }
    return ApiService.get(urlTenant, "clients?" + options, headers);
  }

  store(data) {
    return ApiService.post(url + "/clients", data);
  }

  update(id, data) {
    let area = MenuService.getActiveArea();

    if (
      store.getters.userType === "admin" &&
      !["areaTenant", "areaPartner"].includes(area.name)
    ) {
      return ApiService.put(url + "/clients/" + id, data);
    }
    return ApiService.put(urlTenant + "/clients/" + id, data);
  }

  delete(id) {
    return ApiService.delete(url + "/clients/" + id);
  }

  async checkDBAuth(client) {
    let tenantConnections = client.tenant.tenantConnections;
    let mysqlConnection = tenantConnections.filter(tenantConnection => {
      return tenantConnection.name === "tenant";
    })[0];
    let host = mysqlConnection.host + ":" + mysqlConnection.port;
    let user = mysqlConnection.username;
    let password = mysqlConnection.password;
    let database = mysqlConnection.database;

    let data = new FormData();

    data.append("auth[driver]", "server");
    data.append("auth[server]", host);
    data.append("auth[username]", user);
    data.append("auth[password]", password);
    data.append("auth[db]", database);

    let adminerUrl =
      "/adminer.php" +
      "?server=" +
      encodeURI(host) +
      "&username=" +
      encodeURI(user) +
      "&db=" +
      encodeURI(database) +
      "&pw=" +
      encodeURI(password);

    try {
      let response = await ApiService.post(
        adminerUrl,
        data,
        this.adminerHeaders
      );

      return {
        success: true,
        url: adminerUrl,
        response: response
      };
    } catch (e) {
      return {
        success: false,
        error: e
      };
    }
  }

  formatOptionalHeaders(apiKey) {
    return {
      headers: {
        "x-api-key": apiKey
      }
    };
  }
}

export default new Clients();
