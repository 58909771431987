export const myFactory = {
  authentication: {
    connectorConfig_host: "Host",
    connectorConfig_username: "Benutzername",
    connectorConfig_password: "Passwort",
    connectorConfig_database: "Datenbank",
    connectorConfig_division: "Betriebsstätten-ID",
    connectorActionTestConnection: "Verbindung zur myFactory-Instanz testen",
    connectorActionTestConnectionSuccess: "Verbindungsaufbau zur myFactory-Instanz erfolreich",
    connectorActionTestConnectionError: "Fehler beim Verbindungsaufbau zur myFactory-Instanz",
    connectorActionGetRequests: "Verfügbare Requests laden",
    connectorActionGetRequestsError: "Requests konnten nicht geladen werden",
    connectorActionGetRequestsSuccess: "Requests erfolreich geladen",
    version: "Konnektor-Version",
    help: {
      myfactoryWsdlHelp: "e.g. https://public.myfactory.cloud"
    }
  },
  configuration: {
    request: "Request auswählen",
    connectorConfig_businessUnitId: "Geschäftseinheit des Mandanten",
    connectorConfig_languageId: "Sprach-Code",
    connectorConfig_options: "Weitere Optionen",
    connectorConfig_optionsValues: {
      key: "Schlüssel",
      value: "Wert"
    },
    dataInput: "Dateneingabe via",
    createRequestDataStructure: "Datenstruktur aus Request erstellen",
    createRequestDataStructureSuccess: "Datenstruktur erfolgreich angelegt",
    createRequestDataStructureError: "Fehler beim Erstellen der Datenstruktur"
  },
  input: {
    data: "Daten (Output-Variable)"
  },
  output: {
    createResponseDataStructure: "Datenstruktur aus Response erstellen",
    createResponseDataStructureError: "Fehler beim Erstellen der Datenstruktur",
    createResponseDataStructureSuccess: "Datenstruktur erfolgreich angelegt"
  }
}