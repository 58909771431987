export const mailRecipient = {
  mailRecipient: "Mail Recipient",
  recipient: "Recipient",
  emailClass: "EMail Class",
  process: "Process",
  mailRecipientUpdate: "Update Mail Recipient",
  deleteConfirm: 'Delete recipient "{recipient}" ?',
  deleteConfirmText:
    'Do you really want to delete the recipient "{recipient}"? You cannot restore this after delete.',
  deleteSucessText: 'Recipient "{recipient}" has been deleted',
  recipientCreate: "Create recipient",
  recipientCreated: "Recipient created successfully",
  recipientUpdate: "Recipient update",
  recipientUpdated: "Recipient updated successfully",
  users: "Users",
  admins: "Admins"
};
