export const languages = {
  languages: "Sprachen",
  languageCreate: "Sprache anlegen",
  languageCreated: "Sprache wurde erfolgreich angelegt",
  languageCreateText: "Ein neues Sprache zur Übersicht hinzufügen.",
  languageDelete: "Sprache löschen",
  languageDeleted: "Sprache wurde erfolgreich entfernt",
  languageDeleteText:
    'Möchten Sie das Sprache "{name}" wirklich unwiderruflich löschen?',
  languageUpdate: "Sprache bearbeiten",
  languageUpdated: "Sprache wurde erfolgreich aktualisiert",
  languageUpdateText: "Ein Sprache überarbeiten.",
  disableTax: "Steuer deaktivieren",
  euMember: "EU-Mitglied",
  iso: "ISO-Code",
  name: "Bezeichnung",
  nameInternational: "Internationale Bezeichnung",
  tax: "Steuersatz (in %)"
};
