export const processStatus = {
  aborted: "Abgebrochen",
  error: "Fehlerhaft",
  info: "Information",
  pending: "Bevorstehend",
  started: "Gestartet",
  stopped: "Erfolgreich beendet",
  waiting: "Wartend",
  warning: "Warnung",
  working: "Arbeitend"
};
