export const LOAD_DATA_STRUCTURES = "getDataStructures";
export const LOAD_CONFIG_VALUES = "getConfigValues";
export const LOAD_PARAMETER_VALUE = "getParameterValue";
export const REMOVE_PARAMETER_VALUE = "removeParameterValue";
export const UPDATE_PARAMETER_VALUE = "updateParameterValue";
export const LOAD_DATA_PIPELINE_CONFIG = "loadDataPipelineConfig";

export const SET_DATA_PIPELINE_CREATED = "setDataPipelineCreated";
export const SET_DATA_STRUCTURES = "setDataStructures";
export const SET_CONFIG_VALUE = "setConfigValue";
export const SET_PARAMETER_VALUE = "setParameterValue";
export const SET_DATA_PIPELINE_OPERATOR_GROUPS = "setDataPipelineOperators";
export const SET_DATA_PIPELINE_AGGREGATIONS = "setDataPipelineAGGREGATIONS";

export const SET_SELECTED_DATA_STRUCTURE = "setSelectedDataStructure";
export const SET_MAIN_DATA_STRUCTURE = "setMainDataStructure";

export const INCREMENT_IS_BUSY_INITIAL = "incrementIsBusyInitial";
export const DECREMENT_IS_BUSY_INITIAL = "decrementIsBusyInitial";

export const INCREMENT_IS_BUSY = "incrementIsBusy";
export const DECREMENT_IS_BUSY = "decrementIsBusy";

export const SESSION_KEY_DATA_PIPELINE_CREATED = "dataPipeline.created";

export const STAGE_TYPE_QUERY = 0;
export const STAGE_TYPE_TRANSFORMER = 1;
export const STAGE_TYPE_FILTER = 2;
export const STAGE_TYPE_AGGREGATION = 3;
export const STAGE_TYPE_JOIN = 4;
export const STAGE_TYPE_ON = 5;

export default {
  state: {
    dataPipelineCreated: JSON.parse(
      window.sessionStorage.getItem(SESSION_KEY_DATA_PIPELINE_CREATED) ?? "{}"
    ),
    dataStructures: [],
    configValues: [],
    parameterValues: [],
    dataPipelineOperatorGroups: [],
    dataPipelineAggregations: [],
    isBusyInitial: 0,
    isBusy: 0,
    selectedDataStructure: null,
    mainDataStructure: {}
  },
  getters: {
    dataPipelineCreated(state) {
      return state.dataPipelineCreated;
    },
    dataStructures(state) {
      return state.dataStructures;
    },
    configValues(state) {
      return state.configValues;
    },
    parameterValues(state) {
      return state.parameterValues;
    },
    dataPipelineOperatorGroups(state) {
      return state.dataPipelineOperatorGroups;
    },
    dataPipelineAggregations(state) {
      return state.dataPipelineAggregations;
    },
    isBusyInitial(state) {
      return state.isBusyInitial > 0;
    },
    isBusy(state) {
      return state.isBusy > 0;
    },
    selectedDataStructure(state) {
      return state.selectedDataStructure;
    },
    mainDataStructure(state) {
      return state.mainDataStructure;
    }
  },
  actions: {
    [SET_DATA_PIPELINE_CREATED](state, payload) {
      state.commit(SET_DATA_PIPELINE_CREATED, payload);
    },
    [LOAD_DATA_STRUCTURES](state, payload) {
      state.commit(INCREMENT_IS_BUSY_INITIAL);

      payload
        .getAll({ noPagination: 1 })
        .then(response => {
          state.commit(SET_DATA_STRUCTURES, response.data.data);
        })
        .finally(() => {
          state.commit(DECREMENT_IS_BUSY_INITIAL);
        });
    },
    [LOAD_CONFIG_VALUES](state, payload) {
      state.commit(INCREMENT_IS_BUSY_INITIAL);

      payload
        .getAll({ noPagination: 1 })
        .then(response => {
          state.commit(SET_CONFIG_VALUE, response.data.data);
        })
        .finally(() => {
          state.commit(DECREMENT_IS_BUSY_INITIAL);
        });
    },
    [LOAD_PARAMETER_VALUE](state, payload) {
      state.commit(INCREMENT_IS_BUSY_INITIAL);
      state.commit(SET_PARAMETER_VALUE, payload);
      state.commit(DECREMENT_IS_BUSY_INITIAL);
    },
    [REMOVE_PARAMETER_VALUE](state, payload) {
      state.commit(INCREMENT_IS_BUSY_INITIAL);
      state.commit(REMOVE_PARAMETER_VALUE, payload);
      state.commit(DECREMENT_IS_BUSY_INITIAL);
    },
    [UPDATE_PARAMETER_VALUE](state, payload) {
      state.commit(UPDATE_PARAMETER_VALUE, payload)
    },
    [LOAD_DATA_PIPELINE_CONFIG](state, payload) {
      state.commit(INCREMENT_IS_BUSY_INITIAL);

      payload
        .getConfig()
        .then(response => {
          state.commit(
            SET_DATA_PIPELINE_OPERATOR_GROUPS,
            response.data.data.operatorGroups
          );
          state.commit(
            SET_DATA_PIPELINE_AGGREGATIONS,
            response.data.data.aggregations
          );
        })
        .finally(() => {
          state.commit(DECREMENT_IS_BUSY_INITIAL);
        });
    },
    async [SET_SELECTED_DATA_STRUCTURE](state, payload) {
      const classname = payload ? payload.classname : null;
      const dataStructureService = payload
        ? payload.dataStructureService
        : null;
      const queryStage = payload ? payload.queryStage : null;

      if (classname === null) {
        state.commit(SET_SELECTED_DATA_STRUCTURE, null);
        return;
      }

      let dataStructure = state.getters.dataStructures.find(
        ds => ds.classname === classname
      );

      if (dataStructure.fields === undefined) {
        state.commit(INCREMENT_IS_BUSY);
        await dataStructureService
          .get(dataStructure.id)
          .then(data => {
            dataStructure.fields = data.data.data.fields;
          })
          .finally(() => {
            state.commit(DECREMENT_IS_BUSY);
          });
      }
      if (queryStage !== undefined && queryStage !== null) {
        dataStructure.fields.forEach(field => {
          const queryField = queryStage.config.fields.find(
            f => f.field === field.name
          );
          if (!queryField) {
            return;
          }
          field.alias = queryField.alias;
        });
        if (
          (queryStage.parentId === undefined || queryStage.parentId === null) &&
          queryStage.config.model === dataStructure.classname
        ) {
          state.dispatch(SET_MAIN_DATA_STRUCTURE, {
            dataStructure: dataStructure,
            stage: queryStage
          });
        }
      }

      state.commit(SET_SELECTED_DATA_STRUCTURE, dataStructure);
    },

    [SET_MAIN_DATA_STRUCTURE](state, payload) {
      let dataStructure = payload ? payload.dataStructure : null;
      const stage = payload ? payload.stage : null;
      if (dataStructure !== null) {
        dataStructure.fields.forEach(field => {
          const queryField = stage.config.fields.find(
            f => f.field === field.name
          );
          if (!queryField) {
            return;
          }
          field.alias = queryField.alias;
        });
      }

      state.commit(SET_MAIN_DATA_STRUCTURE, dataStructure);
    }
  },
  mutations: {
    [SET_DATA_PIPELINE_CREATED](state, payload) {
      state.dataPipelineCreated = payload;
      window.sessionStorage.setItem(
        SESSION_KEY_DATA_PIPELINE_CREATED,
        JSON.stringify(payload)
      );
    },
    [SET_DATA_STRUCTURES](state, payload) {
      state.dataStructures = payload;
    },
    [SET_CONFIG_VALUE](state, payload) {
      state.configValues = payload;
    },
    [SET_PARAMETER_VALUE](state, payload) {
      if (typeof payload === "undefined") {
        state.parameterValues = [];
        return;
      }
      state.parameterValues.push(payload);
    },
    [REMOVE_PARAMETER_VALUE](state, payload) {
      state.parameterValues.splice(payload, 1);
    },
    [UPDATE_PARAMETER_VALUE](state, payload) {
      state.parameterValues[payload[1]] = payload[0];
    },
    [SET_DATA_PIPELINE_OPERATOR_GROUPS](state, payload) {
      state.dataPipelineOperatorGroups = payload;
    },
    [SET_DATA_PIPELINE_AGGREGATIONS](state, payload) {
      state.dataPipelineAggregations = payload;
    },
    [INCREMENT_IS_BUSY_INITIAL](state) {
      state.isBusyInitial++;
    },
    [DECREMENT_IS_BUSY_INITIAL](state) {
      state.isBusyInitial--;
    },
    [INCREMENT_IS_BUSY](state) {
      state.isBusy++;
    },
    [DECREMENT_IS_BUSY](state) {
      state.isBusy--;
    },
    [SET_SELECTED_DATA_STRUCTURE](state, payload) {
      state.selectedDataStructure = payload;
    },
    [SET_MAIN_DATA_STRUCTURE](state, payload) {
      state.mainDataStructure = payload;
    }
  }
};
