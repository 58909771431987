export const enventaConnector = {
  authentication: {
    connectorConfig_wsdl: "WSDL",
    connectorConfig_location: "Location / Endppoint",
    connectorConfig_username: "Username",
    connectorConfig_password: "Password",
    connectorConfig_tokenExpires:
      "Expiration time of ContextToken (in minutes)",
    connectorConfig_persistToken: "Save ContextToken",
    connectorActionTestConnection: "Test connection to eNVenta instance",
    connectorActionTestConnectionSuccess: "Connection successfully",
    connectorActionTestConnectionError: "Connection attempt failed",
    connectorActionGetRequests: "Load available requests",
    connectorActionGetRequestsError: "Requests couldn't be loaded",
    connectorActionGetRequestsSuccess: "Requests loaded successfully",
    connectorConfig_apiVersion: "API version of eNVenta instance",
    version: "Connector version"
  },
  configuration: {
    request: "Select request",
    connectorConfig_businessUnitId: "Business Unit ID of tenant",
    connectorConfig_languageId: "Language ID",
    connectorConfig_options: "Additional options",
    connectorConfig_optionsValues: {
      key: "Key",
      value: "Value"
    },
    dataInput: "Provide data via",
    createRequestDataStructure: "Create datastructure from request",
    createRequestDataStructureSuccess: "Datastructure created successfully",
    createRequestDataStructureError: "Failed to create datastructure"
  },
  input: {
    data: "Data (output variable)"
  },
  output: {
    createResponseDataStructure: "Create datastructure from response",
    createResponseDataStructureError: "Failed to create datastructure",
    createResponseDataStructureSuccess: "Datastructure created successfully"
  }
};
