import menu from "@/core/config/menu.json";

export const SET_DEFAULT = "setDefaultItems";
export const SET_MENU = "setMenuItems";

export default {
  state: {
    items: []
  },
  getters: {
    /**
     * Get the list of menu items
     * @param state
     * @returns {any}
     */
    menuItems(state) {
      return state.items;
    }
  },
  actions: {
    [SET_DEFAULT](state, payload) {
      // Set label as i18n identifier so language will be automatically updated
      payload = menu;
      state.commit(SET_MENU, payload);
    }
  },
  mutations: {
    [SET_MENU](state, payload) {
      state.items = payload;
    }
  }
};
