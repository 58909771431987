import ApiService from "@/core/services/api.service";

const tenantUrl = process.env.VUE_APP_API_TENANT;

// action types
export const LOAD_DEBUG_ITERATIONS = "loadDebugIterations";
export const LOAD_DEBUG_ITERATION_DATA = "loadDebugIterationData";

// mutation types
export const SET_DEBUG_ITERATIONS = "setDebugIterations";
export const ADD_DEBUG_ITERATION = "addDebugIteration";
export const SET_DEBUG_ITERATION_DATA = "setDebugIterationData";
export const SET_CURRENT_DEBUG_ITERATION = "setCurrentDebugIteration";

// store to cache debugiterations with debugdata in WFD
export default {
  state: {
    debugIterations: [],
    currentDebugIteration: {}
  },
  getters: {
    getDebugIterations(state) {
      console.log(state.debugIterations)
      return state.debugIterations;
    },
    getCurrentDebugIteration(state) {
      return state.currentDebugIteration;
    }
  },
  actions: {
    [LOAD_DEBUG_ITERATIONS](context, processId) {
      context.commit(SET_DEBUG_ITERATIONS, []);
      ApiService.get(tenantUrl, "process_debug_data/" + processId)
        .then(response => {
          context.commit(SET_DEBUG_ITERATIONS, response.data.data);
        })
        .catch(error => {
          console.log(error);
        });
    },
    [LOAD_DEBUG_ITERATION_DATA](context, debugIterationId) {
      let iteration = this.getters.getDebugIterations.find(
        i => i.id === debugIterationId
      );
      if (iteration.debugData) {
        context.commit(SET_CURRENT_DEBUG_ITERATION, iteration);
        return;
      }
      ApiService.post(tenantUrl + "/process_debug_data", {
        process_iteration_id: debugIterationId
      })
        .then(response => {
          context.commit(SET_DEBUG_ITERATION_DATA, {
            debugIterationId: debugIterationId,
            debugData: response.data.data
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mutations: {
    [SET_DEBUG_ITERATIONS](state, payload) {
      state.debugIterations = payload;
    },
    [ADD_DEBUG_ITERATION](state, payload) {
      if (state.debugIterations === null) {
        state.debugIterations = [];
      }
      state.debugIterations.unshift(payload);
    },
    [SET_DEBUG_ITERATION_DATA](state, payload) {
      const debugIterationId = payload.debugIterationId;
      const debugData = payload.debugData;

      let iteration = state.debugIterations.find(
        i => i.id === debugIterationId
      );
      if (iteration) {
        iteration.debugData = debugData;
        this.commit(SET_CURRENT_DEBUG_ITERATION, iteration);
      }
    },
    [SET_CURRENT_DEBUG_ITERATION](state, payload) {
      state.currentDebugIteration = payload;
    }
  }
};
