export const graphQL = {
  authentication: {
    password: "Passwort",
    testConnection: "Verbindung testen",
    testConnectionSuccess: "Verbindung testen erfolgreich",
    testConnectionError: "Verbindung testen fehlgeschlagen",
    type: "Typ der Authentifizierung",
    typeOptions: {
      apikey: "Apikey",
      none: "Keine Authentifizierung",
      password: "Passwort",
      bearer: "Bearer Token"
    },
    url: "URL",
    username: "Benutzername",
    key: "Schlüssel",
    value: "Wert",
    addTo: "Senden via",
    addToOptions: {
      headers: "Headers",
      queryParameters: "Query Parameter"
    },
    token: "Token"
  },
  configuration: {},
  input: {
    additional_config: "Zusätzliche Konfig",
    additional_configValues: {
      name: "Name",
      value: "Wert"
    },
    data: "Data",
    query: "Query",
    variables: "Variablen",
    variablesValues: {
      name: "Name",
      value: "Wert"
    },
    dataValues: {
      name: "Name",
      value: "Wert"
    },
    endpoint: "Endpunkt",
    headers: "Header",
    headersValues: {
      name: "Name",
      value: "Wert"
    },
    method: "Methode",
    methodOptions: {
      delete: "delete",
      get: "get",
      post: "post",
      put: "put"
    },
    query_parameters: "Abfrageparameter",
    query_parametersValues: {
      name: "Name",
      value: "Wert"
    },
    testConnection: "Verbindung testen",
    testConnectionSuccess: "Verbindung testen erfolgreich",
    testConnectionError: "Verbindung testen fehlgeschlagen"
  },
  output: {
    response: "Antwort"
  },
  error: {
    log_level: "Log Level",
    log_levelOptions: {
      process: {
        error: "Error",
        info: "Info",
        warning: "Warning"
      }
    },
    log_method: "Logging-Stufe",
    error_handling: "Fehlerbehandlung",
    error_handlingOptions: {
      process_continues: "Workflow läuft weiter",
      process_aborts: "Workflow bricht automatisch ab"
    }
  }
};
