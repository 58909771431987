export const zip = {
  configuration: {
    archiveFile: "Datei archivieren",
    type: "Verfahren",
    typeOptions: {
      zipFile: "In ZIP-Datei komprimieren",
      unzipFile: "Datei entpacken"
    }
  },
  input: {
    help: {
      filePath: 'Bitte einen relativen Pfad angeben (z.B. "in/test.csv").',
      destinationPath:
        'Bitte einen relativen Pfad angeben (z.B. "out/test.csv"). Wenn kein Pfad angegeben wird, wird im selben Verzeichnis entpackt.'
    },
    filePath: "Dateipfad",
    destinationPath: "Zielpfad"
  },
  error: {
    log_method: "Logging-Stufe",
    error_handling: "Fehlerbehandlung",
    error_handlingOptions: {
      process_continues: "Workflow läuft weiter",
      process_aborts: "Workflow bricht automatisch ab"
    }
  }
};
