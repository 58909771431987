export const scheduleParams = {
  day: "Day",
  days: "Days",
  endTime: "End time",
  expression: "Expression",
  first: "First time",
  offset: "Minutes after full hour",
  position: "Position",
  second: "Second time",
  startTime: "Start time",
  time: "Time",
  timezone: "Timezone",
  value: "Value"
};
