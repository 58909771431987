export const datanorm = {
  configuration: {
    version: "Version",
    versionOptions: {
      v4: "Version 4"
    },
    method: "Methode",
    methodOptions: {
      article: "Artikel"
    },
    title: "Titel",
    description: "Beschreibung"
  },
  input: {
    articlesA: "A-Artikel",
    articlesB: "B-Artikel",
    dimensions: "Dimensionen",
    longTexts: "Langtexte"
  },
  error: {
    log_method: "Log Priorität",
    log_message: "Log Nachricht",
    log_methodOptions: {
      error: "Fehler",
      log: "Info",
      none: "Keine",
      warn: "Warnung"
    }
  }
};
