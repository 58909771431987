export const xlsxBatch = {
  configuration: {
    archiveXlsxFile: "XLSX-Datei archivieren",
    batchSize: "Stapelgröße",
    fileContainsHeaderRow: "Datei enthält Überschriften",
    fileContainsHeaderRowOptions: {
      true: "Ja",
      false: "Nein"
    },
    limit: "Limit",
    offset: "Offset"
  },
  input: {
    headerFields: "Überschriften",
    headerFieldsValues: {
      name: "Name"
    },
    help: {
      sheet:
        "Wenn keine Tabelle angegeben, wird die Erste aus der XLSX-Datei genommen.",
      xlsxPath: 'Bitte einen relativen Pfad angeben (z.B. "in/test.xlsx").'
    },
    sheet: "Tabelle",
    xlsxPath: "XLSX-Pfad"
  },
  error: {
    error_handling: "Fehlerbehandlung",
    error_handlingOptions: {
      process_continues: "Workflow läuft weiter",
      process_aborts: "Workflow bricht automatisch ab"
    }
  }
};
