export const role = {
  description: "Beschreibung",
  name: "Name",
  nameDisplay: "Anzeigename",
  roleCreate: "Rolle hinzufügen",
  roleCreated: "Rolle erstellt",
  roleDeletedTitle: "Rolle gelöscht!",
  roleDeletedDescription: "Rolle wurde erfolgreich gelöscht.",
  roleDeleteTitle: "Rolle löschen",
  roleDeleteDescription:
    'Möchten Sie die Rolle "{name}" wirklich löschen? Dieser Vorgang ist unwiderruflich.',
  roleUpdate: "Rolle bearbeiten",
  roleUpdated: "Rolle aktualisiert",
  roles: "Rollen",
  selectClient: "Bitte wählen Sie zuerst einen Kunden aus."
};
