export const SET_DATA_STRUCTURE_CREATED = "setDataStructure";
export const SESSION_KEY_DATA_STRUCTURE_CREATED = "dataStructure.created";

export default {
  state: {
    dataStructureCreated: JSON.parse(
      window.sessionStorage.getItem(SESSION_KEY_DATA_STRUCTURE_CREATED) ?? "{}"
    )
  },
  getters: {
    dataStructureCreated(state) {
      return state.dataStructureCreated;
    }
  },
  actions: {
    [SET_DATA_STRUCTURE_CREATED](state, payload) {
      state.commit(SET_DATA_STRUCTURE_CREATED, payload);
    }
  },
  mutations: {
    [SET_DATA_STRUCTURE_CREATED](state, payload) {
      state.dataStructureCreated = payload;
      window.sessionStorage.setItem(
        SESSION_KEY_DATA_STRUCTURE_CREATED,
        JSON.stringify(payload)
      );
    }
  }
};
