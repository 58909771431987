export const time = {
  configuration: {
    schedule_config: "Config",
    schedule_config_dayOfMonth: "Day",
    schedule_config_dayOfWeek: "Day of week",
    schedule_config_hour: "Hour",
    schedule_config_minute: "Minute",
    schedule_config_minuteOptions: {
      "*": "Every minute",
      everyXMinutes: "Every x minutes"
    },
    schedule_config_month: "Month",
    schedule_config_monthOptions: {
      "*": "Every month",
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December"
    },
    schedule_config_dayOfMonthOptions: {
      "*": "Every day of the month"
    },
    schedule_config_dayOfWeekOptions: {
      "*": "Every day of the week",
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
      7: "Sunday"
    },
    schedule_config_hourOptions: {
      "*": "Every hour",
      everyXHours: "Every x hours"
    },
    schedule_config_everyXMinutes: "Every X minutes config",
    schedule_config_everyXHours: "Every X hours config",
    schedule_config_everyXHoursOptions: {
      "*": "Every minute",
      everyXMinutes: "Every x minutes"
    },
    schedule_configValues: {
      day: "Day",
      days: "Days",
      endTime: "End time",
      expression: "Expression",
      first: "First time",
      offset: "Offset",
      position: "Position",
      second: "Second time",
      startTime: "Sart time",
      time: "Zeit",
      timezone: "Timezone",
      value: "Value",
      minutes: "Minutes",
      hours: "Hours"
    },
    schedule_method: "Method",
    schedule_methodOptions: {
      cron: "Cronjob",
      dailyAt: "Daily at specified time",
      everyXHours: "Every x hours",
      everyXMinutes: "Every x minutes",
      hourlyAt: "Hourly at specified time",
      twiceDaily: "Twice daily"
    }
  }
};
