export const storage = {
  local: "Lokal",
  ftp: "FTP",
  sftp: "S-FTP",
  host: "Host",
  port: "Port",
  username: "Benutzername",
  password: "Passwort",
  ssl: "SSL",
  passive: "Passive",
  timeout: "Timeout",
  root: "Root",
  typeSelect: "Speichertyp wählen"
};
