export const xmlBatch = {
  configuration: {
    archiveXmlFile: "Archive XML-file",
    batchSize: "Batch size",
    dataStructure: "Datastructure",
    encoding: "Encoding",
    forceArrayOn: "Force array on",
    forceArrayOnValues: {
      name: "Name"
    },
    help: {
      type: "XML file evaluation based on a data structure or a manual configuration."
    },
    limit: "Limit",
    offset: "Offset",
    selector: "Target XML tag",
    type: "Type",
    typeOptions: {
      dataStructure: "Datastructure",
      manually: "Manually"
    }
  },
  input: {
    filePath: "XML-Path",
    help: {
      filePath: 'Please enter a relative path (e.g. "in/test.xml").'
    }
  },
  error: {
    error_handling: "Error handling",
    error_handlingOptions: {
      process_continues: "Workflow continues",
      process_aborts: "Workflow aborts"
    }
  }
};
