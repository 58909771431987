export const usersGrantIps = {
  usersGrantedIps: "Users granted IP addresses",
  addNewEntry: "Add a new entry",
  updateEntry: "Update entry",
  user: "User",
  ipAddress: "IP Address",
  description: "Description",
  userGrantedIpCreated: "User granted ip entry created",
  userGrantedIpUpdated: "User granted ip entry updated",
  confirmDeleteTitle: "Delete user granted ip record",
  confirmDeleteTextUser:
    'Do you really want to delete the ip "{ip}" for user "{user}"?',
  confirmDeleteText: 'Do you really want to delete the ip "{ip}"?',
  deleteSuccessText: "IP record deleted successfully"
};
