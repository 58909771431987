export const journal = {
  entry: "Entry",
  title: "Journal",
  general: "General",
  jobReportingDetails: "Job reporting details",
  logs: "Logs",
  jobsTimeline: "Jobs timeline",
  contentIdentifier: "Content identifier",
  periodFilter: "Period filter",
  workflowFilter: "Workflow filter",
  workflow: "Workflow",
  selectWorkflow: "All workflows",
  statusFilter: "Status filter",
  selectStatus: "All",
  noDataFound: "No data found with the selected search criterias",
  period: "Period",
  twentyFourHours: "24 h",
  lastWeek: "Last week",
  lastMonth: "Last month",
  searchFilter: "Search filter",
  enterContentIdentifier: "Enter Content identifier",
  searchLogs: "Search logs"
};
