export const xmlBatch = {
  configuration: {
    archiveXmlFile: "XML-Datei archivieren",
    batchSize: "Stapelgröße",
    dataStructure: "Datenstruktur",
    encoding: "Codierung",
    forceArrayOn: "Array erzwingen bei",
    forceArrayOnValues: {
      name: "Name"
    },
    help: {
      type: "XML Datei Auswertung anhand einer Datenstruktur oder einer manuellen Konfiguration."
    },
    limit: "Limit",
    offset: "Offset",
    selector: "Ziel XML-Tag",
    type: "Typ",
    typeOptions: {
      dataStructure: "Datenstruktur",
      manually: "Manuell"
    }
  },
  input: {
    filePath: "XML-Pfad",
    help: {
      filePath: 'Bitte einen relativen Pfad angeben (z.B. "in/test.xml").'
    }
  },
  error: {
    error_handling: "Fehlerbehandlung",
    error_handlingOptions: {
      process_continues: "Workflow läuft weiter",
      process_aborts: "Workflow bricht automatisch ab"
    }
  }
};
