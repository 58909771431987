export const queryEditor = {
  dataTable: "Data table",
  schema: "Schema",
  fieldName: "Field name",
  fieldLabel: "Field label",
  description: "Description",
  dataType: "Data type",
  index: "Index",
  allowNull: "Allow NULL",
  unique: "Unique",
  sqlSyntaxError:
    "You have an SQL syntax error. Please check your SQL query and try again",
  sqlSyntaxEmpty: "The sql query is empty",
  newQuery: "New query",
  openQuery: "Open",
  saveQuery: "Save",
  saveQueryAs: "Save as",
  enterQueryName: "Please enter the query name",
  enterQueryDescription: "Please enter the query description",
  enterQueryNameAndDescription:
    "Please enter the query name and the description",
  updateQuery: "Update query",
  querySyntaxOk: "Query syntax is valid",
  queryUpdated: "Query updated",
  queryCreated: "Query created",
  deleteTitle: "Delete query",
  deleteMessage:
    'Do you really want to delete the query "{query}"? This process is irreversible.',
  deleteSuccessText: "The query {query} has been deleted",
  savedQueries: "Data Sets",
  resource: "Resource",
  preview: "Preview",
  parameter: "Parameter",
  parameterName: "Name",
  parameterValue: "Test Value",
  runQuery: "Run",
  standardQueries: "Standard Queries",
  standardQuery: "Standard Query",
  applyQueryToolTip: "Apply query",
  notSaved: "Not saved",
  modePlanning: "Planning",
  modeCode: "Code",
  queryName: "Query name",
  queryDescription: "Beschreibung",
  previewResourceResults: "Resource data preview",
  previewQueryResults: "Query data preview",
  selectedFields: "Selected fields",
  addField: "Add field",
  conditions: "Conditions"
};
