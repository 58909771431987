export const demoData = {
  configuration: {
    system: "System / Dienst",
    systemOptions: {
      Shopware5: "Shopware 5",
      Test: "Test"
    },
    dataTypeShopware5: "Shopware 5 Ressource",
    dataTypeShopware5Options: {
      Address: "Adresse",
      Article: "Artikel",
      Category: "Kategorie",
      Customer: "Kunde",
      Order: "Bestellung"
    },
    quantity: "Gesamtanzahl zu generierender Datensätze"
  },
  input: {
    articles: "Artikelbasis",
    customers: "Kundenbasis"
  }
};
