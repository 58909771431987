<template>
  <v-app>
    <router-view data-app></router-view>
    <!-- <Socket /> -->
    <Pusher />
    <LoadedGlobal />
  </v-app>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~animate.css";
@import "assets/plugins/fontawesome-pro-6.1.1-web/css/all.min.css";

// Main demo style scss
@import "assets/sass/style.vue";
@import "assets/sass/yedi.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
html {
  overflow-y: auto !important;
}
</style>

<script>
//import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
//import Socket from "./components/SYS/Socket";
import Pusher from "./components/SYS/Pusher";
import LoadedGlobal from "./components/SYS/LoadedGlobal";
import { mapGetters } from "vuex";

export default {
  components: {
    //Socket,
    Pusher,
    LoadedGlobal
  },
  metaInfo() {
    return {
      title: this.metaTitle,
      titleTemplate: `%s YEDI`
    };
  },
  computed: {
    ...mapGetters(["breadcrumbs", "pageTitle"]),
    metaTitle() {
      let title = "";
      this.breadcrumbs
        .slice()
        .reverse()
        .forEach((entry, index) => {
          if (index > 1) {
            return;
          }
          title += entry.title;
          title += " - ";
        });
      return title;
    }
  },
  name: "YEDI",
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    //this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  }
};
</script>
