export const xml = {
  configuration: {
    help: {
      type: "XML Datei Auswertung anhand einer Datenstruktur oder einer manuellen Konfiguration."
    },
    selector: "Ziel XML-Tag",
    forceArrayOn: "Array erzwingen bei",
    forceArrayOnValues: {
      name: "XML-Tag"
    },
    dataStructure: "Datenstruktur",
    type: "Auswertung",
    typeOptions: {
      manually: "Manuell",
      dataStructure: "Datenstruktur"
    },
    archiveXmlFile: "XML Datei archivieren"
  },
  input: {
    help: {
      xmlPath: 'Bitte einen relativen Pfad angeben (z.B. "in/test.xml").'
    },
    xmlPath: "XML Datei"
  },
  error: {
    log_method: "Logging-Stufe",
    error_handling: "Fehlerbehandlung",
    error_handlingOptions: {
      process_continues: "Workflow läuft weiter",
      process_aborts: "Workflow bricht automatisch ab"
    }
  }
};
