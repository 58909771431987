export const integrations = {
  addResource: "Add",
  analyzeData: "Analyze data",
  authSuccess: "Authentication successful",
  basicConfig: "Basic configuration",
  create: "New Integration",
  createTitle: "New {connector}-Integration",
  deleteResource: "Remove",
  groupAll: "All (A-Z)",
  groupFilesApis: "Files & Services APIs",
  groupErps: "Databases ERPs",
  groupCrm: "CRM",
  groupPim: "PIM",
  groupShops: "Shops",
  loginInformation: "Login information",
  saveIntegration: "Save integration",
  selectConnector: "Select connector",
  selectData: "Select data",
  selectMethod: "Select method",
  selectResource: "Select resource",
  tableConfig: " Table configuration",
  testAuthentication: "Test authentication",
  testAuthenticationErrorTitle: "Authentication error",
  testAuthenticationErrorMessage: "<code>{msg}</code>",
  title: "Integrations"
};
