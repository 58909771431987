export const user = {
  cancelCreate: "Benutzererstellung abbrechen",
  actions: "Aktionen",
  admin: "Admin",
  adminAdministration: "Admin Verwaltung",
  adminDeleteMessage:
    'Möchten Sie den Benutzer "{user}" wirklich löschen? Dieser Vorgang ist unwiderruflich.',
  adminDeleteTitle: "Benutzer löschen",
  adminsManagement: "Adminsverwaltung",
  avatar: "Profilbild",
  avatarChange: "Profilbild ändern",
  avatarRemove: "Profilbild entfernen",
  baseData: "Stammdaten",
  company: "Firma",
  cropProfilePicture: "Profilbild zuschneiden",
  dataProvideCorrectly: "Die Daten bitte korrekt eingeben!",
  dropFilesAnywhereToUpload:
    "Legen Sie Dateien zum Hochladen an einer beliebigen Stelle ab",
  dropFilesToUpload: "Legen Sie Dateien zum Hochladen ab",
  email: "E-Mail",
  enterEmail: "Bitte geben Sie die E-Mail-Adresse des Benutzers ein",
  enterFirstName: "Bitte geben Sie den Vornamen des Benutzers ein",
  enterLastName: "Bitte geben Sie den Nachnamen des Benutzers ein",
  enterPassword: "Bitte geben Sie das Benutzerpasswort ein",
  enterPasswordConfirmation: "Bitte geben Sie das gleiche Passwort erneut ein",
  enterSalutation: "Bitte geben Sie die Form des Grußes ein",
  enterTitle: "Bitte geben Sie den Titel ein",
  firstName: "Vorname",
  imageUpload: "Bild hochladen",
  informationContact: "Kontakt Informationen",
  informationPersonal: "Persönliche Informationen",
  informationPersonalUpdate: "Persönliche Informationen aktualisieren",
  informationUser: "User Informationen",
  language: "Sprache",
  lastName: "Nachname",
  mail: "E-Mail Adresse",
  mailEnter: "E-Mail Adresse eingeben",
  mailShort: "E-Mail",
  myProfile: "Mein Profil",
  myProfileDesc: "Kontoeinstellungen und mehr",
  name: "Name",
  nameEnter: "Namen eingeben",
  notAPicture: "Ihre Wahl ist kein Bild",
  password: "Passwort",
  passwordChangeDescription: "Passwort für Account ändern",
  passwordChangeTitle: "Passwort ändern",
  passwordConfirmation: "Passwort Bestätigung",
  passwordCurrentRequired: "Aktuelles Passwort erforderlich",
  passwordEnter: "Passwort eingeben",
  passwordNew: "Neues Passwort",
  passwordNewConfirm: "Neues Passwort bestätigen",
  passwordNewConfirmRequired: "Bestätigung des Passworts erforderlich",
  passwordNewRequired: "Neues Passwort erforderlich",
  passwordNotMatching: "Die Passwörter stimmen nicht überein",
  passwordWrong: "Falsches Passwort",
  profile: "Profil",
  profilePicture: "Profilbild",
  removeProfilePicture: "Profilbild entfernen",
  required: "Erforderlich",
  reviewAndSubmit: "Überprüfen und einreichen",
  role: "Rolle",
  salutation: "Anrede",
  saveImageChanges: "Bildänderungen speichern",
  selectLanguage:
    "Bitte wählen Sie aus, welche Sprache dem Benutzer zugewiesen werden soll",
  selectRole:
    "Bitte wählen Sie aus, welche Rolle dem Benutzer zugewiesen werden soll",
  sendWelcomeEmail: "Begrüßungs-E-Mail senden",
  title: "Titel",
  updateBaseDataAndProfilePicture: "Stammdaten und Profilbild aktualisieren",
  updateProfile: "Profil aktualisieren",
  uploadAndCropImage: "Bild hochladen und zuschneiden",
  uploadProfilePicture: "Profilbild hochladen",
  user: "User",
  adminCreate: "User erstellen",
  adminCreated: "User erstellt",
  adminDeleted: "User erfolgreich gelöscht",
  adminUpdate: "User bearbeiten",
  adminUpdated: "User aktualisiert",
  validFileTypes: "Erlaubte Dateitypen",
  deleteConfirm: "Möchten sie ",
  allUsers: "Alle Benutzer"
};
