export const dataSets = {
  addAggregation: "Add transformer",
  addAndCondition: "+ and condition",
  addConditionGroup: "+ and",
  addField: "+ add field",
  addFilter: "Add filter",
  addJoin: "Add join",
  addOrCondition: "+ or condition",
  aggregations: "Transformer",
  alias: "Alias",
  clearAggregationHelp: "Empty transformer",
  clearConditionHelp: "Empty condition",
  configuration: "Configuration",
  create: "Create",
  createDataSet: "Create DataSet",
  customLimit: "Limit",
  dataSets: "DataSets",
  dataStructureAlias: "DataStructure-Alias",
  deleteText:
    'Are you sure you want to delete the dataset "{name}"? This process is irreversible.',
  deleteStageText: 'Are you sure you want to delete the stage "{name}"?',
  deleteTitle: "Delete DataSet",
  deletedSuccess: "DataSet was successfully deleted",
  description: "Description",
  editor: "Editor",
  fieldData: "Data",
  fieldPlaceholder: "Search field",
  fields: "Fields",
  filter: "Filter",
  foreignField: "Foreign field",
  invalidDataStructure: "Please select a data structure",
  invalidField: "Please add at least one field.",
  limit: "Limit",
  localField: "Local Field",
  name: "Name",
  noDataAvailable: "No data available",
  noDataStructureSelected: "No data structure selected",
  noFieldsSelected: "At least one field must be added",
  nullText: "[NULL]",
  on: "On",
  openEditor: "Open editor",
  preview: "Preview",
  removeAggregationHelp: "Remove transformer",
  removeConditionHelp: "Remove condition",
  removeFieldHelp: "Remove field",
  savedSuccess: "DataSet was saved successfully",
  schema: "Schema",
  selectAggregation: "Transformer",
  selectDataStructure: "Select data structure",
  selectField: "Select field",
  selectOperator: "Select operator",
  selectValue: "Enter value",
  testStage: "Test stage",
  title: "Titel",
  parameter: "Parameter",
  config: "Config",
  parameterCreate: "Create parameter",
  parameterCreated: "Parameter created",
  parameterUpdated: "Parameter updated",
  addParameter: "Add parameter",
  deleteParameter: "Are you sure to delete parameter: {name}?"
};
