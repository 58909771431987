export const csv = {
  configuration: {
    help: {
      type: "CSV Datei Auswertung anhand einer Datenstruktur oder einer manuellen Konfiguration."
    },
    type: "Auswertung",
    typeOptions: {
      manually: "Manuell",
      dataStructure: "Datenstruktur"
    },
    dataStructure: "Datenstruktur",
    delimiter: "Trennzeichen",
    encoding: "Codierung",
    archiveCsvFile: "CSV Datei archivieren"
  },
  input: {
    help: {
      csvPath: 'Bitte einen relativen Pfad angeben (z.B. "in/test.csv").'
    },
    csvPath: "CSV Datei"
  },
  error: {
    error_handling: "Fehlerbehandlung",
    error_handlingOptions: {
      process_continues: "Workflow läuft weiter",
      process_aborts: "Workflow bricht automatisch ab"
    }
  }
};
