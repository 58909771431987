export const client = {
  accounting: "Buchhaltung",
  accountingHasVat: "Ich habe eine Umsatzsteuer-ID",
  accountingVatNumber: "Umsatzsteuer-ID",
  accountingNoVat: "Ich bin Kleinunternehmer",
  accountingInvoicesSendEmail: "Ich möchte Rechnungen per E-Mail erhalten",
  accountingInvoicesEmail: "E-Mail für Rechnungen",
  accountingInvoicing: "Rechnungsstellung",
  accountingTax: "Steuer",
  active: "Aktiv",
  assignedPartner: "Zugewiesener Partner",
  apiToken: "API Token",
  baseData: "Stammdaten",
  billing: "Rechnungsadresse",
  billingAddress1: "Adresszeile 1",
  billingAddress2: "Adresszeile 2",
  billingAddress3: "Adresszeile 3",
  billingStreet: "Straße, Hausnummer",
  billingZipcode: "PLZ",
  billingCity: "Ort",
  billingCountry: "Land",
  cannotCreate: "Mandant konnte nicht angelegt werden",
  clientCreate: "Mandanten anlegen",
  clientCreated: "Mandant wurde angelegt",
  clientDeleteMessage:
    'Möchten Sie den Mandanten "{client}" wirklich löschen? Dieser Prozess ist irreversibel.',
  clientDeleteTitle: "Mandant löschen",
  clientDeleted: "Mandant erfolgreich gelöscht",
  clientNotCreated: "Mandant nicht erstellt",
  clientUpdate: "Mandanten bearbeiten",
  clientUpdated: "Mandant wurde erfolgreich aktualisiert",
  clients: "Mandanten",
  companyData: "Unternehmensdaten",
  contactData: "Kontaktdaten",
  contactEmail: "E-Mail Adresse",
  contactFax: "Fax",
  contactPersonYedi:
    "Mitarbeiter YEDI, der diesen Mandanten verantwortlich betreut",
  contactPhone: "Telefon",
  contactWebsite: "Website",
  connection: "Verbindung",
  contactPerson: "Ansprechpartner",
  couldNotOpenDatabase: "Datenbank konnte nicht geöffnet werden.",
  error: "Fehler",
  id: "#ID",
  informationClient: "Mandantinformationen",
  informationClientDescription: "Mandantinformationen bearbeiten",
  initialized: "Initialisiert",
  language: "Sprache",
  logo: "Logo",
  name: "Firmenname",
  nameEnter: "Namen eingeben",
  nameShort: "Kürzel",
  noContactPersons: "Keine Ansprechpartner vorhanden",
  number: "Mandantennummer",
  settings: "Einstellungen",
  storage: "Storage",
  configurations: "Konfigurationen",
  storageChange: "Storage bearbeiten",
  storageData: "Storage Daten",
  upload: "Hochladen",
  uuid: "UUID",
  variables: "Variablen",
  cancelCreate: "Mandantenanlage abbrechen",
  stepCreateDatabase: "Datenbank anlegen",
  stepAddClientEntry: "Mandanten anlegen",
  stepInitializeMongo: "MongoDB initialisieren",
  stepTenantStorage: "Mandanten Storage erstellen",
  stepTenantConnections: "Mandanten Connections zuweisen ",
  stepFtpUser: "FTP Benutzer anlegen",
  stepInitializeTenant: "Mandanten initialisieren",
  stepActivateQueues: "Queues aktivieren",
  stepServiceDaemons: "Service Daemon neu starten",
  stepCreateDatabaseIntro:
    "Als erster Schritt bei der Anlage eines Mandanten muss in der Profihost ServerCon eine neue MySQL Datenbank für den Mandanten angelegt werden. Die generierten Zugangsdaten bitte in die unten stehenden Formularfelder eintragen und als Bemerkung:<br><br><span class='bg-light'>Tenant DB<br>KUNDENNAME</span>",
  stepCreateDatabaseLink: "Link zum ServerCon:",
  stepCreateDatabaseLoginData: "Datenbank Zugangsdaten",
  databaseName: "Datenbankname",
  databaseUsername: "Benutzername",
  databasePassword: "Passwort",
  clientName: "Mandantenname",
  stepAddClientEntryIntro:
    'Im zweiten Schritt muss ein Eintrag für den neuen Mandanten in der Master DB angelegt werden. Dazu bitte überprüfen, ob der Mandantenname korrekt ist und die Anlage über den Button "Mandanten anlegen" bestätigen. Optional kann ein Partner ausgewählt werden, dem der Mandant zugeordnet wird.',
  addClientFirst: "Bitte zuerst einen Mandanten anlegen",
  addClient: "Mandanten anlegen",
  addClientSuccessTitle: "Mandant wurde erfolgreich angelegt!",
  addClientSuccessMessage:
    'Der Mandant "{name}" wurde erfolgreich angelegt. Nun geht es weiter zum nächsten Schritt.',
  addClientErrorTitle: "Ups! Etwas ist schief gelaufen...",
  addClientErrorMessage:
    'Der Mandant "{name}" konnte nicht angelegt werden. Bitte versuchen Sie es später erneut.',
  stepTenantStorageIntro:
    "In diesem Schritt muss ein Storage für den Mandanten angelegt werden. Dazu bitte per SSH mit dem Server verbinden und folgenden Befehl ausführen:",
  stepTenantStorageCommand: "Befehl",
  stepTenantStorageStorageCreated: "Storage wurde erstellt",
  stepTenantStorageStorageAssigned: "Storage wurde zugewiesen",
  stepTenantConnectionsIntro:
    "Nun müssen dem Mandanten die Datenbankverbindungen zugewiesen werden. Zuerst wird die MySQL Datenbank zugewiesen, danach die MongoDB. Dazu bitte die Zugangsadaten auf ihre Richtigkeit prüfen und die Zuweisung bestätigen.",
  stepTenantConnectionsMySql: "MySQL Verbindung",
  stepTenantConnectionsMongo: "MongoDB Verbindung",
  stepTenantConnectionsAssign: "Verbindung zuweisen",
  stepTenantConnectionsSuccess:
    "Verbindung konnte erfolgreich zugewiesen werden!",
  stepTenantConnectionsError:
    "Die Verbindung konnte nicht zugewiesen werden. Bitte versuchen Sie es später erneut.",
  stepTenantConnectionsWarning: "Bitte zuerst die Verbindungen zuweisen",
  stepInitializeMongoDone: "MongoDB initialisiert",
  stepInitializeMongoIntro:
    "Als Drittes muss ein neuer User für MongoDB angelegt werden. Dazu bitte per SSH mit dem Server verbinden und folgende Befehle nacheinander im Terminal ausführen:",
  stepFtpUserIntro:
    "Als nächstes muss ein neuer FTP Benutzer in der ServerCon angelgt werden. Der User sollte folgenden Pfad als Root-Verzeichnis haben:",
  stepFtpUserRoot: "Root-Verzeichnis",
  stepFtpUserLink: "Link zum ServerCon:",
  stepFtpUserDone: "FTP-User angelegt",
  stepInitializeTenantSuccess: "Der Mandant wurde erfolgreich initalisiert!",
  stepInitializeTenantError:
    "Der Mandant konnte nicht initialisiert werden. Bitte versuchen Sie es später erneut.",
  stepInitializeTenantIntro:
    "Der Mandant muss nun initialisert werden. Durch Bestätung des folgenden Buttons wird die Intialisierung gestartet.",
  stepInitializeTenantConfirm: "Mandanten initialisieren",
  stepInitializeTenantWarning: "Bitte zuerst den Mandanten initialisieren",
  stepActivateQueuesIntro:
    "Hier können nun die Queues des Mandanten nach Belieben aktiviert werden. Dann geht es weiter zum nächsten Schritt.",
  queueInstance: "Instanz",
  queueInstances: "Instanzen",
  activate: "Aktivieren",
  deactivate: "Deaktivieren",
  stepServiceDaemonsIntro:
    "Zuletzt muss noch der Service Daemon für den Queue Worker neu gestartet werden. Dazu bitte im ServerCon den entsprechenden Daemon (Name: YEDI Queue) neu starten. Danach kann die Mandantenanlage beendet werden.",
  stepServiceDaemonsDone: "Service Daemon neu gestartet",
  stepServiceDaemonsLink: "Link zum ServerCon:",
  wizardFinish: "Mandantenanlage abschließen",
  stepServiceDaemonsWarning: "Bitte zuerst den Service Daemon neu starten",
  databases: "Datenbanken",
  databaseHost: "Host",
  databasePort: "Port",
  storageType: "Speichertyp",
  clientWorkspaceUpdateError:
    "Der Mandant-Arbeitsbereich konnte nicht aktualisiert werden",
  workspaceDomainExists: "Domain existiert bereits",
  workspaceAdd: "Domain hinzufügen",
  projects: "Projekte",
  openMysql: "MySQL öffnen",
  openMongo: "MongoDB öffnen",
  selectPartner: "Partner auswählen",
  noPartner: "Kein Partner"
};
