export const login = {
  hintMail: "Bitte geben Sie Ihre E-Mail Adresse ein",
  hintMailFalse:
    "Die eingegebene E-Mail Adresse hat nicht das vorgegebene Format.",
  hintPassword: "Bitte geben Sie Ihr Passwort ein",
  imprint: "Impressum",
  label: "We Connect<br/>Applications Together", // HTML allowed here
  login: "Anmelden",
  loginFailed: "Die Anmeldedaten stimmen nicht überein.",
  mail: "E-Mail Adresse",
  numberCustomer: "Kundennummer",
  password: "Passwort",
  passwordChange: "Passwort ändern",
  passwordRepeat: "Passwort wiederholen",
  passwordForgotten: "Passwort vergessen?",
  passwordResetTitle: "Passwort zurücksetzen",
  passwordResetFor: "Passwort zurücksetzen für {email}",
  passwordResetDescription:
    "E-Mail Adresse eingeben um Passwort zurückzusetzen.",
  passwordResetSubmit: "Absenden",
  privacyPolicy: "Datenschutz",
  welcomeAt: "Willkommen bei",
  tooManyLoginAttempts:
    "Zu viele Anmeldeversuche. Bitte warten Sie, bevor Sie es erneut versuchen!",
  loginDetailsWrong: "Sie konnten sich mit diesen Angaben nicht anmelden.",
  accountLocked:
    "Ihr Konto ist gesperrt. Bitte kontaktieren Sie uns für weitere Informationen",
  twoFaRequired:
    "Anscheinend verbinden Sie sich von einem neuen Gerät aus. Bitte geben Sie den 2FA-Code an, den Sie erhalten haben",
  twoFaRequiredTitle: "Bitte geben Sie den 2FA-Code ein",
  twoFaCodeWrong: "Der angegebene 2FA-Code war falsch",
  enterCode: "Geben Sie den Code ein",
  saveDeviceAsTrusted:
    "Speichern Sie dieses Gerät für 30 Tage als vertrauenswürdig.",
  unstrustedIP:
    "Sie dürfen sich nicht von Ihrer aktuellen IP-Adresse ({ip}) aus anmelden",
  sendMeAnotherCode: "Bitte senden Sie mir einen anderen Code",
  submit: "Abschicken",
  passwordResetMailSent:
    "Wenn die angegebene E-Mail Adresse in unserem System registriert ist, wurde eine Mail mit dem Link zum Zurücksetzen verschickt.",
  passwordResetSuccess: "Password wurde erfolgreich zurückgesetzt!",
  backToLogin: "Zurück zum Login",
  accountDeactivated:
    "Dein Account ist deaktiviert. Bitte kontaktiere deinen Partner für weitere Informationen."
};
