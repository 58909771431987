export const shopware5Connector = {
  authentication: {
    connectorConfig_url: "Host",
    connectorConfig_username: "Benutzername",
    connectorConfig_password: "API-Key",
    connectorActionTestConnection: "Verbindung zur Shopware-Instanz testen",
    connectorActionTestConnectionSuccess:
      "Verbindungsaufbau zur Shopware-Instanz erfolgreich",
    connectorActionTestConnectionError:
      "Fehler beim Verbindungsaufbau zur Shopware-Instanz",
    version: "Konnektor-Version",
    connectorConfig_apiVersion: "Version der Shopware-Instanz"
  },
  configuration: {
    request: "Request",
    requestConfig_queryParameters: "Query-Parameter",
    requestConfig_queryParametersValues: {
      key: "Schlüssel",
      value: "Wert"
    },
    requestConfig_headers: "Header-Parameter",
    requestConfig_headersValues: {
      key: "Schlüssel",
      value: "Wert"
    },
    requestConfig_additionalConfig: "Weitere Parameter",
    requestConfig_additionalConfigValues: {
      key: "Schlüssel",
      value: "Wert"
    },
    dataInput: "Dateneingabe via",
    createRequestDataStructure: "Datenstruktur aus Request erstellen",
    createRequestDataStructureSuccess: "Datenstruktur erfolgreich angelegt",
    createRequestDataStructureError: "Fehler beim Erstellen der Datenstruktur",
    requestConfig_id: "ID",
    requestConfig_useNumberAsId: "Nummer als ID verwenden"
  },
  output: {
    createResponseDataStructure: "Datenstruktur aus Response erstellen",
    createResponseDataStructureError: "Fehler beim Erstellen der Datenstruktur",
    createResponseDataStructureSuccess: "Datenstruktur erfolgreich angelegt"
  }
};
