import DesignerElements from "@/components/Projects/Workflows/Designer/designerElements";

// getter types
export const GET_CLIPBOARD_ELEMENTS = "getClipboardElements";
export const GET_ALL_ELEMENTS = "getAllElements";

// action types
export const ADD_CLIPBOARD_ELEMENT = "addClipboardElement";
export const REMOVE_CLIPBOARD_ELEMENT = "removeClipboardElement";
export const CLEAR_CLIPBOARD_ELEMENTS = "clearClipboardElements";
export const SET_ALL_ELEMENTS = "setAllElements";
export const LOAD_ALL_ELEMENTS = "loadAllElements";

// mutation types
export const ADD_TO_CLIPBOARD = "addToClipboard";
export const REMOVE_FROM_CLIPBOARD = "removeFromClipboard";
export const CLEAR_CLIPBOARD = "clearClipboard";
export const SET_ELEMENTS = "setElements";

const state = {
  clipboard: [],
  elements: []
};

const getters = {
  [GET_CLIPBOARD_ELEMENTS](state) {
    return state.clipboard;
  },
  [GET_ALL_ELEMENTS](state) {
    return state.elements;
  }
};

const actions = {
  [ADD_CLIPBOARD_ELEMENT](context, payload) {
    context.commit(ADD_TO_CLIPBOARD, payload);
  },
  [REMOVE_CLIPBOARD_ELEMENT](context, payload) {
    context.commit(REMOVE_FROM_CLIPBOARD, payload);
  },
  [CLEAR_CLIPBOARD_ELEMENTS](context, payload) {
    context.commit(CLEAR_CLIPBOARD, payload);
  },
  [SET_ALL_ELEMENTS](context, payload) {
    context.commit(SET_ELEMENTS, payload);
  },
  [LOAD_ALL_ELEMENTS](context) {
    DesignerElements.getAll().then(response => {
      context.commit(SET_ELEMENTS, response?.data?.data);
    });
  }
};

const mutations = {
  [ADD_TO_CLIPBOARD](state, payload) {
    state.clipboard.unshift(payload);
  },
  [REMOVE_FROM_CLIPBOARD](state, payload) {
    let index = state.clipboard.indexOf(payload);
    if (index < 0) {
      return;
    }
    state.clipboard.splice(index, 1);
  },
  [CLEAR_CLIPBOARD](state) {
    state.clipboard = [];
  },
  [SET_ELEMENTS](state, payload) {
    state.elements = payload;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
