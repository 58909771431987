export const notes = {
  notes: "Notizen",
  title: "Titel",
  note: "Notiz",
  noteCreate: "Notiz erstellen",
  noteEdit: "Notiz aktualisieren",
  type: "Type",
  typeId: "Type ID",
  createdText: "Die Notiz '{title}' wurde erstellt",
  updatedText: "Die Notiz '{title}' wurde aktualisiert",
  deleteNoteQuestion:
    "Sind Sie sicher, dass Sie die Notiz '{title}' löschen möchten?",
  deletedText: "Die Notiz '{title}' wurde gelöscht"
};
