export const dataStore = {
  configuration: {
    type: "Typ",
    typeOptions: {
      write: "Schreiben",
      read: "Lesen"
    },
    createDataStructure: "Input Daten auswählen",
    dataStructure: "Datenstruktur",
    dataStructureRead: "Datenstruktur",
    dataStructureSource: "Quellstruktur",
    dataStructureSourceOptions: {
      array: "Array",
      csv: "CSV"
    },
    dataStructureSelect: "Datenstruktur auswählen/anlegen",
    dataStructureSelectOptions: {
      existing: "Datenstruktur vorhanden",
      new: "Neue Datenstruktur anlegen"
    },
    csvDataStructure: "Datenstruktur auswählen",
    readMethod: "Datenmenge filtern",
    readMethodOptions: {
      all: "alle Datensätze",
      getDirty: "Unverarbeitete Datensätze",
      getClean: "Verarbeitete Datensätze",
      getByPrimaryId: "Nach Primärschlüssel",
      getByFieldValue: "Nach Feldwert"
    },
    returnMethod: "Rückgabe",
    returnMethodOptions: {
      fullRecord: "Vollständiger Datensatz",
      primaryKey: "Nur Primärschlüssel"
    },
    testConfig: "Konfiguration testen",
    testConfigSuccess: "Testkonfiguration erfolgreich",
    limit: "Limit",
    offset: "Offset"
  },
  input: {
    help: {
      csvPath: 'Bitte einen relativen Pfad angeben (z.B. "in/test.csv")'
    },
    dataToSave: "Daten, die gespeichert werden sollen",
    selectCsvPath: "Dateipfad",
    warning: "Warning",
    archiveCsvFile: "Datei archivieren",
    primaryKeyInput: "Primärschlüssel",
    fieldValueField: "Feld",
    fieldValueOperator: "Vergleichs-Operator",
    fieldValueOperatorOptions: {
      "=": "==",
      "!=": "!=",
      ">": ">",
      ">=": ">=",
      "<": "<",
      "<=": "<=",
      "IS NULL": "IS NULL",
      "IS NOT NULL": "IS NOT NULL",
      "IS EMPTY": "IS EMPTY",
      "IS NOT EMPTY": "IS NOT EMPTY"
    },
    fieldValueInput: "Feldwert",
    testConfig: "Konfiguration testen",
    testConfigSuccess: "Testkonfiguration erfolgreich"
  }
};
