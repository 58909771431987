export const reporting = {
  process: "Process",
  reporting: "Reporting",
  lastIteration: "Last iteration",
  lastIterationStatus: "Status",
  iterationsCount: "Jobs last cycle",
  iterationsCountError: "Therefrom incorrect",
  generalInformation: "General Informations",
  seconds: "{value} seconds",
  lastCycleDuration: "Last cycle duration",
  lastCycleDurationWithChilds: "Last cycle duration with childs",
  averageCycleDuration: "Average duration",
  averageCycleDurationWithChilds: "Average duration with childs",
  averageCycleDurationWithChildsLast7Days:
    "Average duration with childs (last 7 Days)",
  lastJobError: "Last job error",
  lastIterations: "Jobs",
  selectAll: "Select all",
  status: "Status",
  duration: "Duration (min:sec)",
  durationApproximately: "≈ 00:01",
  childJobs: "Childjobs",
  lastIterationCount: "Iterations count last cycle",
  lastIterationAverageDuration: "Average duration last iteration",
  statusCount: "Status count last cycle",
  jobs: "Jobs {process}",
  cycle: "Cycle",
  noChildJobs: "No childjobs",
  selectionOther: "(+{count} others)",
  averageDays: "Days for calculation",
  noIterations: "No jobs available.",
  jobDetails: "Jobdetails",
  contentIdentifier: "Content identifier",
  startedBy: "Started by",
  startedAt: "Started at",
  finishedAt: "Finished at",
  queue: "Queue",
  parameter: "Parameter",
  details: "Details",
  logs: "Logs",
  showDetails: "Show details",
  systemEmails: "Emails sent by the system",
  recipient: "Recipient",
  returnValues: "Return value",
  elementInfo: "Element information",
  toDesigner: "To designer",
  overview: "Overview",
  jobEvaluation: "Complete status evaluation",
  count: "Count",
  totalCycleDuration: "Total cycle duration",
  averageCycleDurationLast7Days: "Average duration over the last 7 days",
  estimatedEnd: "Estimated end",
  nextCycle: "Next cycle",
  total: "Total",
  start: "Start",
  stop: "Stop",
  completeTimeEvaluation: "Complete time evaluation",
  noChildJobsWereTriggered: "No child jobs were triggered",
  noSchedulerPlanned: "No scheduler planner",
  payloadTitle: "Payload",
  time: "Period",
  chooseTimeFrom: "Period from",
  chooseTimeTo: "Period to"
};
