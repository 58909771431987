export const fileAccess = {
  configuration: {
    type: "Type",
    typeOptions: {
      read: "Read",
      write: "Write"
    }
  },
  input: {
    content: "Content",
    filePath: "Directory",
    fileName: "File name",
    searchPattern: "Search pattern",
    testData: "Search",
    testDataSuccess: "Data successfully tested",
    rootPath: "Root path"
  },
  fileManager: "File Manager",
  fileManagerOpen: "Open file manager",
  applySelectedFileTree: "Apply"
};
