export const textTemplates = {
  textTemplates: "TextTemplates",
  type: "Type",
  textTemplateCreate: "Create TextTemplate",
  textTemplateDeleted: "TextTemplate was deleted",
  deleteTextTemplateTitle: 'Delete TextTemplate "{title}"?',
  deleteTextTemplateText:
    "Do you want to delete the TextTemplate? This action cannot be undone.",
  textTemplateSaved: "TextTemplate saved",
  textTemplateUpdated: "TextTemplate updated",
  textTemplateLabel: "Label",
  textTemplateSave: "Save",
  variables: "Variables",
  string: "Text",
  dataStructure: "DataStructure",
  dataStructureList: "DataStructure List",
  variablesHelp:
    "Parameters can be used in TextTemplates to show dynamic values.",
  addVariable: "Add parameter",
  detailsBasicConfiguration: "Basic configuration",
  name: "Name",
  datastructure: "DataStructure",
};
