export const pimcore = {
  authentication: {
    url: "URL of pimcore instance",
    apiKey: "Api key",
    testConnection: "Test connection to pimcore instance",
    testConnectionSuccess:
      "Successfully established connection to pimcore instance",
    testConnectionError: "Couldn't establish connection to pimcore instance"
  },
  configuration: {
    resource: "Resource",
    resourceOptions: {
      dataObject: "Data Object",
      asset: "Asset",
      custom: "Custom"
    },
    customFunction: "Custom Function",
    customFunctionOptions: {
      getById: "Get by id",
      getByPath: "Get by path",
      getChildrenById: "Get children by id",
      getChildrenByPath: "Get children by path",
      getDirty: "Get dirty objects",
      setClean: "Set clean"
    },
    createDatastructure: "Create data structure (Schema: Pimcore%Klassenname%",
    createDatastructureSuccess: "Data structure was created successfully",
    createDatastructureError: "Data structure could not be created",
    action: "Action",
    actionOptions: {
      create: "CREATE",
      read: "READ",
      update: "UPDATE",
      delete: "DELETE",
      write: "WRITE",
      dataStructure: "Create data structure"
    },
    detailed: "Return complete dataset",
    datastructureDemoId: "Demo ID",
    help: {
      detailed: "Full objects are returned",
      raw: "If this option is marked, the request won´t be sent to pimcore, but could be used as a parameter in an other pimcore element."
    },
    raw: "Return raw data",
    assetSource: "Asset source",
    assetSourceOptions: {
      url: "URL",
      external: "Tenant Storage"
    }
  },
  input: {
    id: "Object id from pimcore",
    parents: "List of parent objects",
    parentsValues: {
      id: "Query-Action"
    },
    customId: "ID",
    customPath: "Path",
    customFilter: "Filter",
    customClass: "Class",
    data: "Data",
    writeMode: "Write mode",
    fileName: "File name",
    customSettings: "Custom Settings",
    customSettingsValues: {
      name: "Name",
      value: "Value"
    },
    metaData: "Meta data",
    metaDataValues: {
      name: "Name",
      value: "Value"
    },
    isFolder: "Is folder",
    limit: "Limit",
    offset: "Offset",
    orderKey: "Sort by field",
    order: "Sorting direction",
    orderOptions: {
      desc: "DESC",
      asc: "ASC"
    },
    filter: "Filter Query",
    filterValues: {
      query: "WHERE-conditions"
    },
    class: "Class of Data Object",
    key: "Unique key",
    published: "Publish dataset",
    notPublished: "Unpublished dataobject",
    isVariant: "Dataset is variant",
    type: "Filter by object, variant, folder (multiple values possible)",
    unpublished: "Unpublished",
    parentId: "Parent ID",
    imageUrl: "Image URL",
    imagePath: "Image Path"
  },
  error: {
    logMethod: "Log method",
    logMethodOptions: {
      none: "None",
      log: "Log",
      warn: "Warning",
      error: "Error"
    },
    logMessage: "Log message"
  }
};
