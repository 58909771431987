export const users = {
  accessData: "Zugangsdaten",
  actions: "Actions",
  baseData: "Benutzerinformationen",
  cropProfilePicture: "Profilbild zuschneiden",
  dropFilesAnywhereToUpload:
    "Legen Sie Dateien zum Hochladen an einer beliebigen Stelle ab",
  dropFilesToUpload: "Legen Sie Dateien zum Hochladen ab",
  email: "E-Mail",
  enterEmail: "Bitte geben Sie die E-Mail-Adresse des Benutzers ein",
  enterFirstName: "Bitte geben Sie den Vornamen des Benutzers ein",
  enterLastName: "Bitte geben Sie den Nachnamen des Benutzers ein",
  enterPassword: "Bitte geben Sie das Benutzerpasswort ein",
  enterPasswordConfirmation: "Bitte geben Sie das gleiche Passwort erneut ein",
  enterSalutation: "Bitte geben Sie die Form des Grußes ein",
  enterTitle: "Bitte geben Sie den Titel ein",
  firstName: "Vorname",
  imageUpload: "Bild hochladen",
  language: "Sprache",
  lastName: "Nachname",
  myProfile: "Mein Profil",
  myProfileDesc: "Kontoeinstellungen und mehr",
  notAPicture: "Ihre Wahl ist kein Bild",
  password: "Passwort",
  passwordConfirmation: "Passwort Bestätigung",
  profile: "Profil",
  profilePicture: "Profilbild",
  removeProfilePicture: "Profilbild entfernen",
  required: "Erforderlich",
  reviewAndSubmit: "Überprüfen und einreichen",
  role: "Rolle",
  salutation: "Anrede",
  saveImageChanges: "Bildänderungen speichern",
  selectLanguage:
    "Bitte wählen Sie aus, welche Sprache dem Benutzer zugewiesen werden soll",
  selectRole:
    "Bitte wählen Sie aus, welche Rolle dem Benutzer zugewiesen werden soll",
  sendWelcomeEmail: "Begrüßungs-E-Mail senden",
  title: "Titel",
  updateBaseDataAndProfilePicture: "Stammdaten und Profilbild aktualisieren",
  updateProfile: "Profil aktualisieren",
  uploadAndCropImage: "Bild hochladen und zuschneiden",
  uploadProfilePicture: "Profilbild hochladen",
  user: "Benutzer",
  userCreate: "Benutzer erstellen",
  userCreated: "Benutzer erstellt",
  userDeleted: "Benutzer erfolgreich gelöscht",
  userUpdated: "Benutzer aktualisiert",
  usersManagement: "Benutzerverwaltung",
  allUsers: "Alle Benutzer"
};
