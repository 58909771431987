export const grantedIps = {
  active: "Active",
  addNewEntry: "Add a new entry to ip-whitelist",
  confirmDeleteTitle: "Delete ip",
  confirmDeleteText: "Do you really want to delete the ips?",
  deleteSuccessText: "IP record deleted successfully",
  description: "Description",
  grantedIpCreated: "Route granted ip entry created",
  grantedIpsNeededToSave:
    "In order to activate the blocking of all IPs except those in the user whitelist, at least one IP address must be entered",
  grantedIpUpdated: "Route granted ip entry updated",
  grantedIps: "Route granted IP-Addresses",
  ipAddresses: "IP-Addresses",
  ipAddressesToolTip:
    "Enter the IP addresses for which access is to be granted here. Enter a * symbol to grant access to all IP addresses.",
  public: "Deactivate user restriction",
  publicTooltip:
    "Do you want to share it with specific users only or publicly?",
  route: "Route",
  routeTooltip:
    "Enter the path to be released without the host part (e.g. / route / myRoute)",
  saveTitle: "Activate user whitelist",
  saveWarning:
    "Your currently used IP address is not in the whitelist. Please note that you can lock yourself out of the system by activating the whitelist option.",
  updateEntry: "Update entry",
  user: "Restrict to user",
  userTooltip: "Select the users to be unrestricted here"
};
