export const image = {
  configuration: {
    compressionOptions: {
      none: "Keine",
      resmush: "Resmush"
    },
    compression: "Bildkompression",
    quality: "Qualität (0-100)",
    info: "Hinweis"
  },
  input: {
    imagePath: "Pfad/Url des Bildes"
  }
};
