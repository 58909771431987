export const mailRecipient = {
  mailRecipient: "E-Mail Empfänger",
  recipient: "Empfänger",
  emailClass: "E-Mail Klasse",
  process: "Prozess",
  mailRecipientUpdate: "E-Mail Empfänger bearbeiten",
  deleteConfirm: 'Empfänger "{recipient}" löschen ?',
  deleteConfirmText:
    'Möchten Sie den Empfänger "{recipient}" wirklich löschen? Sie können dies nach dem Löschen nicht wiederherstellen.',
  deleteSucessText: 'Empfänger "{recipient}" wurde gelöscht',
  recipientCreate: "Empfänger erstellen",
  recipientCreated: "Der Empfänger wurde erstellt",
  recipientUpdate: "Empfänger bearbeiten",
  recipientUpdated: "Empfänger aktualisiert",
  users: "Benutzer",
  admins: "Admins"
};
