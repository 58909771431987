export const restConnector = {
  authentication: {
    connectorConfig_url: "Host",
    connectorConfig_type: "Authentifizierungs-Typ",
    connectorConfig_key: "API-Schlüssel-Feld",
    connectorConfig_value: "API-Schlüssel",
    connectorConfig_addTo: "API-Schlüssel übermitteln via",
    headers: "Headers",
    queryParameters: "Query-Parameter",
    connectorConfig_username: "Benutzername",
    connectorConfig_password: "Passwort",
    connectorConfig_token: "Bearer Token",
    connectorActionTestConnection: "Verbindung testen",
    connectorActionTestConnectionSuccess: "Verbindungsaufbau erfolgreich",
    connectorActionTestConnectionError: "Verbindungsaufbau fehlgeschlagen",
    version: "Konnektor-Version"
  },
  configuration: {
    requestConfig_method: "HTTP-Methode",
    get: "GET",
    post: "POST",
    put: "PUT",
    delete: "DELETE",
    requestConfig_queryParameters: "GET-Parameter",
    requestConfig_queryParametersValues: {
      name: "Schlüssel",
      value: "Wert"
    },
    requestConfig_headers: "Headers",
    requestConfig_headersValues: {
      name: "Schlüssel",
      value: "Wert"
    },
    requestConfig_additionalConfig: "Weitere Optionen",
    requestConfig_additionalConfigValues: {
      name: "Schlüssel",
      value: "Wert"
    }
  },
  input: {
    data: "Daten",
    createRequestDataStructure: "Datenstruktur aus Request erstellen",
    createRequestDataStructureSuccess: "Datenstruktur erfolgreich erstellt",
    createRequestDataStructureError: "Fehler bei Erstellung der Datenstruktur"
  },
  output: {
    createResponseDataStructure: "Datenstruktur aus Response erstellen",
    createResponseDataStructureSuccess: "Datenstruktur erolgreich erstellt",
    createResponseDataStructureError: "Fehler bei Erstellung der Datenstruktur"
  }
};
