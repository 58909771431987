export const usersGrantIps = {
  usersGrantedIps: "Benutzer Gewährte IP-Adressen",
  addNewEntry: "Neuen Eintrag hinzufügen",
  updateEntry: "Eintrag aktualisieren",
  user: "Benutzer",
  ipAddress: "IP-Adresse",
  description: "Beschreibung",
  userGrantedIpCreated: "Benutzer gewährter IP-Eintrag erstellt",
  userGrantedIpUpdated: "Benutzer gewährter IP-Eintrag aktualisiert",
  confirmDeleteTitle: "Benutzer erteilten IP-Eintrag löschen",
  confirmDeleteTextUser:
    'Möchten Sie die IP "{ip}" für Benutzer "{user}" wirklich löschen?',
  confirmDeleteText: 'Möchten Sie die IP "{ip}" wirklich löschen?',
  deleteSuccessText: "IP-Eintrag erfolgreich gelöscht"
};
