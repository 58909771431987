export const processStatus = {
  aborted: "Aborted",
  error: "Error",
  info: "Information",
  pending: "Pending",
  started: "Started",
  stopped: "Stopped successfully",
  waiting: "Waiting",
  warning: "Warning",
  working: "Working"
};
