export const scheduleMethods = {
  cron: "Cronjob",
  between: "In bestimmtem Zeitraum",
  unlessBetween: "Außerhalb bestimmten Zeitraums",
  inTimeInterval: "In Zeitintervall",
  everyMinute: "Jede Minute",
  everyFiveMinutes: "Alle fünf Minuten",
  everyTenMinutes: "Alle zehn Minuten",
  everyFifteenMinutes: "Alle 15 Minuten",
  everyThirtyMinutes: "Alle 30 Minuten",
  hourly: "Stündlich",
  hourlyAt: "Stündlich zu bestimmter Uhrzeit",
  daily: "Täglich",
  dailyAt: "Täglich zu bestimmter Uhrzeit",
  at: "Zu bestimmter Zeit",
  twiceDaily: "Zwei mal täglich",
  weekdays: "Unter der Woche",
  weekends: "Am Wochenende",
  mondays: "Montags",
  tuesdays: "Dienstags",
  wednesdays: "Mittwochs",
  thursdays: "Donnerstags",
  fridays: "Freitags",
  saturdays: "Samstags",
  sundays: "Sonntags",
  weekly: "Wöchentlich",
  weeklyOn: "Wöchentlich zu bestimmtem Zeitpunkt",
  monthly: "Monatlich",
  monthlyOn: "Monatlich zu bestimmtem Zeitpunkt",
  twiceMonthly: "Zwei mal monatlich",
  quarterly: "Vierteljährlich",
  yearly: "Jährlich",
  days: "An bestimmten Tagen",
  timezone: "Zeitzone festlegen",
  spliceIntoPosition: "In Positionen unterteilen",
  never: "Nie",
  cronWithParams: "Cronjob: {expression}",
  betweenWithParams: "Zwischen {startTime} und {endTime} Uhr",
  unlessBetweenWithParams: "Außer zwischen {startTime} und {endTime} Uhr",
  inTimeIntervalWithParams: "Im Zeitintervall {startTime} und {endTime} Uhr",
  hourlyAtWithParams: "{offset} Min. nach jeder vollen Stunde",
  atWithParams: "Um {time} Uhr",
  dailyAtWithParams: "Täglich um {time} Uhr",
  twiceDailyWithParams: "Zweimal täglich um {first} und {second} Uhr",
  weeklyOnWithParams: "Wöchentlich: {day}, {time} Uhr",
  monthlyOnWithParams: "Monatlich: {day}, {time} Uhr",
  twiceMonthlyWithParams: "Zweimal monatlich: {first}, {second}",
  daysWithParams: "An Tagen: {days}",
  timezoneWithParams: "In Zeitzone {timezone}",
  spliceIntoPositionWithParams: "In Position {position} mit Wert {value}"
};
