import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/components/SYS/layout/LayoutFullscreen"),
      children: [
        {
          path: "/project/workflows/:id/mode/planning/:workflow_element_hash?",
          name: "projectWorkflowsEditorModePlanning",
          component: () =>
            import("@/components/Projects/Workflows/Designer/Index")
        },
        {
          path: "/project/workflows/:id/mode/code",
          name: "projectWorkflowsEditorModeCode",
          component: () =>
            import("@/components/Projects/Workflows/Designer/Index")
        },
        {
          path: "/project/workflows/:id/mode/history",
          name: "projectWorkflowsEditorModeHistory",
          component: () =>
            import("@/components/Projects/Workflows/Designer/Index")
        },
        {
          path: "project/mappings/:id/mapper",
          name: "projectMappingsMappingMapper",
          component: () => import("@/components/Projects/Mappings/Index")
        },
        {
          path: "project/mappings/:id/debug",
          name: "projectMappingsMappingDebug",
          component: () => import("@/components/Projects/Mappings/Index")
        },
        {
          path: "/admin/settings/data-structures/editor/:id?",
          name: "adminSettingsDataStructuresEditor",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },
        {
          path: "partner/data-structures/editor/:id?",
          name: "partnerDataStructuresEditor",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },
        {
          path: "project/data-structures/editor/:id?",
          name: "projectDataStructuresEditor",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },
        {
          path: "apexcharts-demo",
          name: "apexcharts",
          component: () => import("@/components/General/ApexChartsDemo")
        },
        {
          path: "project/text-templates/:id/editor",
          name: "projectTextTemplatesEditor",
          component: () => import("@/components/Projects/TextTemplates/Index")
        }
      ]
    },
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/components/SYS/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/components/General/Dashboard/Index")
        },
        {
          path: "/user/profile",
          name: "user.profile",
          component: () => import("@/components/General/UserProfile/Index")
        },
        {
          path: "/user/profile/information",
          name: "user.profile.information",
          component: () => import("@/components/General/UserProfile/Index")
        },
        {
          path: "/user/profile/access-data",
          name: "user.profile.access-data",
          component: () => import("@/components/General/UserProfile/Index")
        },
        {
          path: "/admin/account/company",
          name: "adminAccountData",
          component: () => import("@/components/Admins/Account/Company/Index")
        },
        {
          path: "/admin/account/company/contact",
          name: "adminAccountDataContact",
          component: () => import("@/components/Admins/Account/Company/Index")
        },
        {
          path: "/admin/account/company/billing",
          name: "adminAccountDataBilling",
          component: () => import("@/components/Admins/Account/Company/Index")
        },
        {
          path: "/admin/account/company/accounting",
          name: "adminAccountDataAccounting",
          component: () => import("@/components/Admins/Account/Company/Index")
        },
        {
          path: "/admin/users",
          name: "adminAccountUsers",
          component: () => import("@/components/Admins/Account/Admin/Index")
        },
        {
          path: "/admin/users/add",
          name: "adminAccountUsersCreate",
          component: () => import("@/components/Admins/Account/Admin/Create")
        },
        {
          path: "/admin/users/:id",
          name: "adminAccountUsersDetails",
          component: () => import("@/components/Admins/Account/Admin/Details")
        },
        {
          path: "/admin/users/:id/information",
          name: "adminAccountUsersDetailsInformation",
          component: () => import("@/components/Admins/Account/Admin/Details")
        },
        {
          path: "/admin/users/:id/access-data",
          name: "adminAccountUsersDetailsAccessData",
          component: () => import("@/components/Admins/Account/Admin/Details")
        },
        {
          path: "/admin/users/:id/profile",
          name: "adminAccountUsersDetailsProfile",
          component: () => import("@/components/Admins/Account/Admin/Details")
        },
        {
          path: "/admin/notifications",
          name: "adminNotifications",
          component: () => import("@/components/SYS/WIP")
        },
        {
          path: "/admin/settings/system",
          name: "adminSettingsSystem",
          component: () => import("@/components/Admins/Settings/System/Index")
        },
        {
          path: "/admin/settings/system/languages",
          name: "adminSettingsSystemLanguages",
          component: () => import("@/components/Admins/Settings/System/Index")
        },
        {
          path: "/admin/settings/system/countries",
          name: "adminSettingsSystemCountries",
          component: () => import("@/components/Admins/Settings/System/Index")
        },
        {
          path: "/admin/settings/system/security",
          name: "adminSettingsSystemSecurity",
          component: () => import("@/components/Admins/Settings/System/Index")
        },
        {
          path: "/admin/settings/system/queues",
          name: "adminsSettingsSystemQueues",
          component: () => import("@/components/Admins/Settings/System/Index")
        },
        {
          path: "/admin/settings/system/security/whitelist",
          name: "adminSettingsSystemSecurityWhitelist",
          component: () => import("@/components/Admins/Settings/System/Index")
        },
        {
          path: "/admin/settings/system/security/whitelist/create",
          name: "adminSettingsSystemSecurityWhitelistCreate",
          component: () => import("@/components/Admins/Settings/System/Index")
        },
        {
          path: "/admin/settings/system/security/whitelist/:id",
          name: "adminSettingsSystemSecurityWhitelistEdit",
          component: () => import("@/components/Admins/Settings/System/Index")
        },
        {
          path: "/admin/settings/server",
          name: "adminSettingsServer",
          component: () =>
            import("@/components/Admins/Settings/ServerInfo/Index")
        },
        {
          path: "/admin/settings/billing",
          name: "adminSettingsBilling",
          component: () => import("@/components/Admins/Settings/Billing/Index")
        },
        {
          path: "/admin/settings/email",
          name: "adminSettingsEmail",
          component: () => import("@/components/Admins/Settings/Email/Index")
        },
        {
          path: "/admin/settings/email",
          name: "adminSettingsEmailTemplates",
          component: () => import("@/components/Admins/Settings/Email/Index")
        },
        {
          path: "/admin/settings/newsletter",
          name: "adminSettingsNewsletter",
          component: () =>
            import("@/components/Admins/Settings/Newsletter/Index")
        },
        {
          path: "/admin/settings/extensions",
          name: "adminSettingsExtensions",
          component: () =>
            import("@/components/Admins/Settings/Extensions/Index")
        },
        {
          path: "/admin/settings/system/countries/:id",
          name: "adminSettingsCountriesEdit",
          component: () =>
            import("@/components/Admins/Settings/System/Countries/Edit")
        },
        {
          path: "/admin/settings/system/countries",
          name: "adminSettingsCountries",
          component: () =>
            import("@/components/Admins/Settings/System/Countries/Index")
        },
        {
          path: "/admin/settings/system/index",
          name: "adminSettingsSystemIndex",
          component: () => import("@/components/Admins/Settings/System/Index")
        },
        {
          path: "/admin/settings/mail-templates",
          name: "adminSettingsMailTemplates",
          component: () =>
            import("@/components/Admins/Settings/Email/MailTemplates/Index")
        },
        {
          path: "/admin/settings/mail-templates/:id",
          name: "adminSettingsMailTemplatesDetails",
          component: () =>
            import("@/components/Admins/Settings/Email/MailTemplates/Details")
        },
        {
          path: "/admin/settings/workflow-elements",
          name: "adminSettingsWorkflowElements",
          component: () =>
            import("@/components/Admins/Settings/WorkflowElements/Index")
        },
        {
          path: "/admin/settings/workflow-elements/:type",
          name: "adminSettingsWorkflowElementsType",
          component: () =>
            import("@/components/Admins/Settings/WorkflowElements/Index")
        },
        {
          path: "/admin/settings/workflow-elements/:type/:action",
          name: "adminSettingsWorkflowElementsTypeAction",
          component: () =>
            import("@/components/Admins/Settings/WorkflowElements/Index")
        },
        {
          path: "/admin/settings/data-structures",
          name: "adminSettingsDataStructures",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },
        {
          path: "/admin/settings/data-structures/create",
          name: "adminSettingsDataStructuresCreate",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },
        {
          path: "/admin/settings/data-structures/:id",
          name: "adminSettingsDataStructuresDetails",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },
        {
          path: "/admin/settings/data-structures/:id/base-data",
          name: "adminSettingsDataStructuresDetailsBaseData",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },
        {
          path: "/admin/settings/data-structures/:id/config",
          name: "adminSettingsDataStructuresDetailsConfiguration",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },
        {
          path: "/admin/settings/data-structures/:id/queries",
          name: "adminSettingsDataStructuresDetailsStandardSQLQueries",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },
        {
          path: "/admin/settings/plugins",
          name: "adminSettingsPlugins",
          component: () => import("@/components/Admins/Settings/Plugins/Index")
        },
        {
          path: "/admin/settings/plugins/details",
          name: "adminSettingsPluginsDetails",
          component: () => import("@/components/Admins/Settings/Plugins/Index")
        },
        {
          path: "/admin/clients",
          name: "adminTenants",
          component: () => import("@/components/Admins/Clients/Index")
        },
        {
          path: "/admin/clients/create",
          name: "adminTenantsCreate",
          component: () => import("@/components/Admins/Clients/Wizard")
        },
        {
          path: "/admin/clients/:id",
          name: "adminTenantsDetails",
          component: () => import("@/components/Admins/Clients/Details")
        },
        {
          path: "/admin/clients/:id/contact",
          name: "adminTenantsDetailsContact",
          component: () => import("@/components/Admins/Clients/Details")
        },
        {
          path: "/admin/clients/:id/billing",
          name: "adminTenantsDetailsBilling",
          component: () => import("@/components/Admins/Clients/Details")
        },
        {
          path: "/admin/clients/:id/accounting",
          name: "adminTenantsDetailsAccounting",
          component: () => import("@/components/Admins/Clients/Details")
        },
        {
          path: "/admin/clients/:id/storage",
          name: "adminTenantsDetailsStorage",
          component: () => import("@/components/Admins/Clients/Details")
        },
        {
          path: "/admin/clients/:id/databases",
          name: "adminTenantsDetailsDatabases",
          component: () => import("@/components/Admins/Clients/Details")
        },
        {
          path: "/admin/clients/:id/security",
          name: "adminTenantsDetailsLoginSecurity",
          component: () => import("@/components/Admins/Clients/Details")
        },
        {
          path: "/admin/clients/:id/security/whitelist",
          name: "adminTenantsDetailsLoginSecurityWhitelist",
          component: () => import("@/components/Admins/Clients/Details")
        },
        {
          path: "/admin/clients/:id/security/whitelist/create",
          name: "adminTenantsDetailsLoginSecurityWhitelistCreate",
          component: () => import("@/components/Admins/Clients/Details")
        },
        {
          path: "/admin/clients/:id/security/whitelist/:whitelistId",
          name: "adminTenantsDetailsLoginSecurityWhitelistEdit",
          component: () => import("@/components/Admins/Clients/Details")
        },
        {
          path: "/admin/clients/:id/settings",
          name: "adminTenantsDetailsSettings",
          component: () => import("@/components/Admins/Clients/Details")
        },
        {
          path: "/admin/partners",
          name: "adminPartners",
          component: () => import("@/components/Admins/Partners/Index")
        },
        {
          path: "/admin/partners/create",
          name: "adminPartnersCreate",
          component: () => import("@/components/Admins/Partners/Wizard")
        },
        {
          path: "/admin/partners/:id",
          name: "adminPartnersDetails",
          component: () => import("@/components/Admins/Partners/Details")
        },
        {
          path: "/admin/partners/:id/contact",
          name: "adminPartnersDetailsContact",
          component: () => import("@/components/Admins/Partners/Details")
        },
        {
          path: "/admin/partners/:id/billing",
          name: "adminPartnersDetailsBilling",
          component: () => import("@/components/Admins/Partners/Details")
        },
        {
          path: "/admin/partners/:id/accounting",
          name: "adminPartnersDetailsAccounting",
          component: () => import("@/components/Admins/Partners/Details")
        },
        {
          path: "/admin/partners/:id/storage",
          name: "adminPartnersDetailsStorage",
          component: () => import("@/components/Admins/Partners/Details")
        },
        {
          path: "/admin/partners/:id/databases",
          name: "adminPartnersDetailsDatabases",
          component: () => import("@/components/Admins/Partners/Details")
        },
        {
          path: "/admin/partners/:id/security",
          name: "adminPartnersDetailsSecurity",
          component: () => import("@/components/Admins/Partners/Details")
        },
        {
          path: "/admin/news",
          name: "adminNews",
          component: () => import("@/components/Admins/News/Index")
        },
        {
          path: "/admin/news/create",
          name: "adminNewsCreate",
          component: () => import("@/components/Admins/News/Index")
        },
        {
          path: "/admin/news/:id",
          name: "adminNewsDetails",
          component: () => import("@/components/Admins/News/Index")
        },
        {
          path: "/admin/media",
          name: "adminMediaManagement",
          component: () => import("@/components/Admins/Media/Index")
        },
        {
          path: "/admin/welcome",
          name: "adminWelcome",
          component: () => import("@/components/Admins/Welcome/Index")
        },
        {
          path: "/help/contact-person",
          name: "helpContactPerson",
          component: () =>
            import("@/components/General/HelpArea/ContactPersons")
        },
        {
          path: "/help/support",
          name: "helpSupport",
          component: () => import("@/components/General/HelpArea/Support")
        },

        {
          path: "/partner/accounting",
          name: "partnerAccountAccounting",
          component: () => import("@/components/Partners/Account/Accounting")
        },
        {
          path: "/partner/account",
          name: "partnerAccountData",
          component: () =>
            import("@/components/Partners/Account/AccountData/Index")
        },
        {
          path: "/partner/account/contact",
          name: "partnerAccountDataContact",
          component: () =>
            import("@/components/Partners/Account/AccountData/Index")
        },
        {
          path: "/partner/account/billing",
          name: "partnerAccountDataBilling",
          component: () =>
            import("@/components/Partners/Account/AccountData/Index")
        },
        {
          path: "/partner/users",
          name: "partnerAccountUsers",
          component: () => import("@/components/Partners/Account/Partner/Index")
        },
        {
          path: "/partner/users/add",
          name: "partnerAccountUsersCreate",
          component: () =>
            import("@/components/Partners/Account/Partner/Create")
        },
        {
          path: "/partner/users/:id",
          name: "partnerAccountUsersDetails",
          component: () =>
            import("@/components/Partners/Account/Partner/Details")
        },
        {
          path: "/partner/users/:id/information",
          name: "partnerAccountUsersDetailsInformation",
          component: () =>
            import("@/components/Partners/Account/Partner/Details")
        },
        {
          path: "/partner/users/:id/access-data",
          name: "partnerAccountUsersDetailsAccessData",
          component: () =>
            import("@/components/Partners/Account/Partner/Details")
        },
        {
          path: "/partner/users/:id/profile",
          name: "partnerAccountUsersDetailsProfile",
          component: () =>
            import("@/components/Partners/Account/Partner/Details")
        },
        {
          path: "/partner/tenants",
          name: "partnerTenants",
          component: () => import("@/components/Partners/Tenants/Index")
        },
        {
          path: "/partner/tenants/create",
          name: "partnerTenantsCreate",
          component: () => import("@/components/Admins/Clients/Wizard")
        },
        {
          path: "/partner/tenants/:id",
          name: "partnerTenantsDetails",
          component: () => import("@/components/Admins/Clients/Details")
        },
        {
          path: "/partner/tenants/:id/contact",
          name: "partnerTenantsDetailsContact",
          component: () => import("@/components/Admins/Clients/Details")
        },
        {
          path: "/partner/tenants/:id/billing",
          name: "partnerTenantsDetailsBilling",
          component: () => import("@/components/Admins/Clients/Details")
        },
        {
          path: "/partner/tenants/:id/accounting",
          name: "partnerTenantsDetailsAccounting",
          component: () => import("@/components/Admins/Clients/Details")
        },
        {
          path: "/partner/tenants/:id/storage",
          name: "partnerTenantsDetailsStorage",
          component: () => import("@/components/Admins/Clients/Details")
        },
        {
          path: "/partner/tenants/:id/databases",
          name: "partnerTenantsDetailsDatabases",
          component: () => import("@/components/Admins/Clients/Details")
        },
        {
          path: "/partner/tenants/:id/security",
          name: "partnerTenantsDetailsLoginSecurity",
          component: () => import("@/components/Admins/Clients/Details")
        },

        {
          path: "/partner/tenants/:id/security/whitelist",
          name: "partnerTenantsDetailsLoginSecurityWhitelist",
          component: () => import("@/components/Admins/Clients/Details")
        },
        {
          path: "/partner/tenants/:id/security/whitelist/create",
          name: "partnerTenantsDetailsLoginSecurityWhitelistCreate",
          component: () => import("@/components/Admins/Clients/Details")
        },
        {
          path: "/partner/tenants/:id/security/whitelist/:whitelistId",
          name: "partnerTenantsDetailsLoginSecurityWhitelistEdit",
          component: () => import("@/components/Admins/Clients/Details")
        },

        {
          path: "/partner/tenants/:id/settings",
          name: "partnerTenantsDetailsSettings",
          component: () => import("@/components/Admins/Clients/Details")
        },

        {
          path: "partner/data-structures",
          name: "partnerDataStructures",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },
        {
          path: "partner/data-structures/create",
          name: "partnerDataStructuresCreate",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },
        {
          path: "partner/data-structures/:id",
          name: "partnerDataStructuresDetails",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },
        {
          path: "partner/data-structures/:id/base-data",
          name: "partnerDataStructuresDetailsBaseData",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },
        {
          path: "partner/data-structures/:id/config",
          name: "partnerDataStructuresDetailsConfiguration",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },
        {
          path: "partner/data-structures/:id/queries",
          name: "partnerDataStructuresDetailsStandardSQLQueries",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },

        {
          path: "/partner/welcome",
          name: "partnerWelcome",
          component: () => import("@/components/Partners/Welcome/Index")
        },
        {
          path: "/project/welcome",
          name: "projectWelcome",
          component: () => import("@/components/Projects/Welcome/Index")
        },
        //  ----------------- START - Hide while not working properly (YEDI-960) -----------------
        {
          path: "project/insights",
          name: "projectInsights",
          component: () => import("@/components/Projects/Insights/Index")
        },
        {
          path: "project/insights/create",
          name: "projectInsightsCreate",
          component: () => import("@/components/Projects/Insights/Index")
        },
        {
          path: "project/insights/:id",
          name: "projectInsightsShow",
          component: () => import("@/components/Projects/Insights/Index")
        },
        {
          path: "project/insights/edit/:id",
          name: "projectInsightsEdit",
          component: () => import("@/components/Projects/Insights/Index")
        },
        //  ----------------- END - Hide while not working properly -----------------
        {
          path: "project/notes",
          name: "projectNotes",
          component: () => import("@/components/Projects/Notes/Index")
        },
        {
          path: "project/integrations",
          name: "projectIntegrationsOverview",
          component: () => import("@/components/Projects/Integrations/Index")
        },
        {
          path: "project/integrations/select-connector",
          name: "projectIntegrationsOverviewSelectConnector",
          component: () => import("@/components/Projects/Integrations/Index")
        },
        {
          path: "project/integrations/create",
          name: "projectIntegrationsOverviewCreate",
          component: () => import("@/components/Projects/Integrations/Index")
        },
        {
          path: "project/integrations/create/:id/loginDetails",
          name: "projectIntegrationsOverviewCreateLoginDetails",
          component: () => import("@/components/Projects/Integrations/Index")
        },
        {
          path: "/project/integrations/create/:id/selectData",
          name: "projectIntegrationsOverviewCreateSelectData",
          component: () => import("@/components/Projects/Integrations/Index")
        },
        {
          path: "/project/integrations/create/:id/analyzeData",
          name: "projectIntegrationsOverviewCreateAnalyzeData",
          component: () => import("@/components/Projects/Integrations/Index")
        },
        {
          path: "/project/integrations/create/:id/basicConfig",
          name: "projectIntegrationsOverviewCreateBasicConfig",
          component: () => import("@/components/Projects/Integrations/Index")
        },
        {
          path: "/project/integrations/create/:id/tableConfig",
          name: "projectIntegrationsOverviewCreateTableConfig",
          component: () => import("@/components/Projects/Integrations/Index")
        },
        {
          path: "/project/integrations/create/:id/save",
          name: "projectIntegrationsOverviewCreateSave",
          component: () => import("@/components/Projects/Integrations/Index")
        },

        {
          path: "project/dynamicModels/dynamicModel",
          name: "projectDynamicModelsDynamicModel",
          component: () => import("@/components/Projects/DynamicModels/Index")
        },
        {
          path: "project/dynamicModels/dynamicModel/create",
          name: "projectDynamicModelsDynamicModelCreate",
          component: () => import("@/components/Projects/DynamicModels/Index")
        },
        {
          path: "/project/dynamicModels/dynamicModel/:id",
          name: "projectDynamicModelsDynamicModelDetails",
          component: () => import("@/components/Projects/DynamicModels/Index")
        },
        {
          path: "/project/dynamicModels/dynamicModel/:id/basic-configuration",
          name: "projectDynamicModelsDynamicModelDetailsBasic",
          component: () => import("@/components/Projects/DynamicModels/Index")
        },
        {
          path: "/project/dynamicModels/dynamicModel/:id/table-configuration",
          name: "projectDynamicModelsDynamicModelDetailsTable",
          component: () => import("@/components/Projects/DynamicModels/Index")
        },
        {
          path: "/project/workflows",
          name: "projectWorkflows",
          component: () =>
            import("@/components/Projects/Workflows/Designer/Index")
        },
        {
          path: "/project/workflows/create",
          name: "projectWorkflowsCreate",
          component: () =>
            import("@/components/Projects/Workflows/Designer/Index")
        },
        {
          path: "/project/workflows/:id",
          name: "projectWorkflowsEditor",
          component: () =>
            import("@/components/Projects/Workflows/Designer/Index")
        },
        {
          path: "/project/workflows/:id/basedata",
          name: "projectWorkflowsDetailBaseData",
          component: () =>
            import("@/components/Projects/Workflows/Designer/Index")
        },
        {
          path: "/project/workflows/:id/properties",
          name: "projectWorkflowsDetailProperties",
          component: () =>
            import("@/components/Projects/Workflows/Designer/Index")
        },
        {
          path: "/project/workflows/:id/dependency",
          name: "projectWorkflowsDetailDependency",
          component: () =>
            import("@/components/Projects/Workflows/Designer/Index")
        },
        {
          path: "/project/workflows/:id/trigger",
          name: "projectWorkflowsDetailTrigger",
          component: () =>
            import("@/components/Projects/Workflows/Designer/Index")
        },
        {
          path: "/project/workflows/:id/Queue",
          name: "projectWorkflowsDetailQueue",
          component: () =>
            import("@/components/Projects/Workflows/Designer/Index")
        },
        {
          path: "/project/workflows/:id/dangerzone",
          name: "projectWorkflowsDetailDangerZone",
          component: () =>
            import("@/components/Projects/Workflows/Designer/Index")
        },
        {
          path: "/project/workflows/:id/mode/:mode",
          name: "projectWorkflowsEditorMode",
          component: () =>
            import("@/components/Projects/Workflows/Designer/Index")
        },
        {
          path: "/project/workflows/reporting/:id",
          name: "projectWorkflowsReporting",
          component: () =>
            import("@/components/Projects/Workflows/Designer/Index")
        },
        {
          path: "/project/workflows/reporting/:id/details",
          name: "projectWorkflowsReportingDetails",
          component: () =>
            import("@/components/Projects/Workflows/Designer/Index")
        },
        {
          path: "/project/workflows/reporting/:id/jobs",
          name: "projectWorkflowsReportingDetailsJobs",
          component: () =>
            import("@/components/Projects/Workflows/Designer/Index")
        },
        {
          path: "/project/workflows/reporting/:id/childJobs",
          name: "projectWorkflowsReportingDetailsChildJobs",
          component: () =>
            import("@/components/Projects/Workflows/Designer/Index")
        },
        {
          path: "/project/workflows/reporting/:id/:jobId/details",
          name: "projectWorkflowsJobDetails",
          component: () =>
            import("@/components/Projects/Workflows/Designer/Index")
        },
        //  ----------------- START - Hide while not working properly (YEDI-960) -----------------
        // {
        //   path: "project/journal",
        //   name: "projectReportingJournal",
        //   component: () => import("@/components/Projects/Journal/Index")
        // },
        //  ----------------- END - Hide while not working properly (YEDI-960) -----------------
        {
          path: "project/mappings",
          name: "projectMappings",
          component: () => import("@/components/Projects/Mappings/Index")
        },
        {
          path: "project/mappings/create",
          name: "projectMappingsCreate",
          component: () => import("@/components/Projects/Mappings/Index")
        },
        {
          path: "project/mappings/:id/details",
          name: "projectMappingsDetails",
          component: () => import("@/components/Projects/Mappings/Index")
        },
        {
          path: "project/data-structures",
          name: "projectDataStructures",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },
        {
          path: "project/data-structures/create",
          name: "projectDataStructuresCreate",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },
        {
          path: "project/data-structures/:id",
          name: "projectDataStructuresDetails",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },
        {
          path: "project/data-structures/:id/base-data",
          name: "projectDataStructuresDetailsBaseData",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },
        {
          path: "project/data-structures/:id/config",
          name: "projectDataStructuresDetailsConfiguration",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },
        {
          path: "project/data-structures/:id/queries",
          name: "projectDataStructuresDetailsStandardSQLQueries",
          component: () =>
            import("@/components/Admins/Settings/DataStructures/Index")
        },
        //  ----------------- START - Hide while not working properly (YEDI-960) -----------------
        // {
        //   path: "project/communication",
        //   name: "projectReportingCommunication",
        //   component: () => import("@/components/Projects/Communication/Index")
        // },
        // {
        //   path: "project/communication/:id",
        //   name: "projectReportingCommunicationDetails",
        //   component: () => import("@/components/Projects/Communication/Index")
        // },
        // {
        //   path: "project/communication/:id/information",
        //   name: "projectReportingCommunicationDetailsInformation",
        //   component: () => import("@/components/Projects/Communication/Index")
        // },
        // {
        //   path: "project/communication/:id/content",
        //   name: "projectReportingCommunicationDetailsContent",
        //   component: () => import("@/components/Projects/Communication/Index")
        // },
        // {
        //   path: "project/communication/:id/parameter",
        //   name: "projectReportingCommunicationDetailsParameter",
        //   component: () => import("@/components/Projects/Communication/Index")
        // },
        //  ----------------- END - Hide while not working properly (YEDI-960) -----------------
        {
          path: "project/data-store",
          name: "projectDataExplorer",
          component: () => import("@/components/Projects/DataStore/Index")
        },
        {
          path: "project/data-store/:id",
          name: "projectDataExplorerDetails",
          component: () => import("@/components/Projects/DataStore/Index")
        },
        {
          path: "project/text-templates",
          name: "projectTextTemplates",
          component: () => import("@/components/Projects/TextTemplates/Index")
        },
        {
          path: "project/text-templates/create",
          name: "projectTextTemplatesCreate",
          component: () => import("@/components/Projects/TextTemplates/Index")
        },
        {
          path: "project/text-templates/:id/details",
          name: "projectTextTemplatesDetails",
          component: () => import("@/components/Projects/TextTemplates/Index")
        },
        {
          path: "project/data-sets",
          name: "projectDataSets",
          component: () => import("@/components/Projects/DataSets/Index")
        },
        {
          path: "project/data-sets/editor/:id?",
          name: "projectDataSetsEditor",
          component: () => import("@/components/Projects/DataSets/Index")
        },
        {
          path: "/project/reporting",
          name: "projectReporting",
          component: () => import("@/components/Projects/Reporting/Index")
        },
        {
          path: "/project/reporting/jobs",
          name: "projectReportingJobs",
          component: () => import("@/components/Projects/Reporting/Jobs/Table")
        },
        {
          path: "/project/settings",
          name: "projectSettings",
          component: () => import("@/components/Projects/Settings/Index")
        },
        {
          path: "/project/settings/projects",
          name: "projectSettingsProjects",
          component: () => import("@/components/Projects/Settings/Index")
        },
        {
          path: "/project/settings/projects/create",
          name: "projectSettingsProjectsCreate",
          component: () => import("@/components/Projects/Settings/Index")
        },
        {
          path: "/project/settings/projects/:projectId",
          name: "projectSettingsProjectsDetails",
          component: () => import("@/components/Projects/Settings/Index")
        },
        {
          path: "/project/settings/queues",
          name: "projectSettingsQueues",
          component: () => import("@/components/Projects/Settings/Index")
        },
        {
          path: "/project/settings/queues/create",
          name: "projectSettingsQueuesCreate",
          component: () => import("@/components/Projects/Settings/Index")
        },
        {
          path: "/project/settings/queues/:queueId",
          name: "projectSettingsQueuesEdit",
          component: () => import("@/components/Projects/Settings/Index")
        },
        {
          path: "/project/settings/mails",
          name: "projectSettingsMails",
          component: () => import("@/components/Projects/Settings/Index")
        },
        {
          path: "/project/settings/basic",
          name: "projectSettingsBasic",
          component: () => import("@/components/Projects/Settings/Index")
        },
        /* Users Start */
        {
          path: "/project/settings/users",
          name: "projectSettingsUsers",
          component: () => import("@/components/Projects/Settings/Index")
        },
        {
          path: "/project/settings/users/:id/information",
          name: "projectSettingsUsersAccessInformation",
          component: () =>
            import("@/components/Projects/Settings/Users/Details")
        },
        {
          path: "/project/settings/users/:id/access-data",
          name: "projectSettingsUsersAccessData",
          component: () =>
            import("@/components/Projects/Settings/Users/Details")
        },
        {
          path: "/project/settings/users/add",
          name: "projectSettingsUsersCreate",
          component: () => import("@/components/Projects/Settings/Users/Create")
        },
        /* Users End */
        {
          path: "/project/settings/plugins",
          name: "projectSettingsPlugins",
          component: () => import("@/components/Projects/Settings/Index")
        },
        {
          path: "/project/settings/email",
          name: "projectSettingsEmail",
          component: () => import("@/components/Projects/Settings/Index")
        },
        {
          path: "/project/settings/email/:id",
          name: "projectSettingsEmailDetails",
          component: () => import("@/components/Projects/Settings/Index")
        },
        {
          path: "/project/settings/loginSecurity",
          name: "projectSettingsLoginSecurity",
          component: () => import("@/components/Projects/Settings/Index")
        },
        {
          path: "/project/settings/config",
          name: "projectSettingsConfig",
          component: () => import("@/components/Projects/Settings/Index")
        },
        {
          path: "/project/settings/config/create",
          name: "projectSettingsConfigCreate",
          component: () => import("@/components/Projects/Settings/Index"),
          props: true
        },
        {
          path: "/project/settings/config/:configId",
          name: "projectSettingsConfigDetails",
          component: () => import("@/components/Projects/Settings/Index")
        },
        {
          path: "/project/settings/workflowToken",
          name: "projectSettingsWorkflowToken",
          component: () => import("@/components/Projects/Settings/Index")
        },
        {
          path: "/project/settings/workflowToken/create",
          name: "projectSettingsWorkflowTokenCreate",
          component: () => import("@/components/Projects/Settings/Index")
        },
        {
          path: "/project/settings/workflowToken/:tokenId",
          name: "projectSettingsWorkflowTokenDetail",
          component: () => import("@/components/Projects/Settings/Index")
        },
        {
          path: "/project/settings/changeBatches",
          name: "projectSettingsChangeBatches",
          component: () => import("@/components/Projects/Settings/Index")
        },
        {
          path: "/project/settings/data-structure",
          name: "projectSettingsDataStructure",
          component: () => import("@/components/SYS/WIP")
        },
        {
          path: "/project/settings/extensions",
          name: "projectSettingsExtensions",
          component: () => import("@/components/SYS/WIP")
        },
        {
          path: "/project/settings/roles",
          name: "projectSettingsRoles",
          component: () => import("@/components/Admins/Roles/Index")
        },
        {
          path: "/tenant/accounting/information",
          name: "tenantAccountingInformation",
          component: () => import("@/components/Tenants/Accounting/Information")
        },
        {
          path: "/tenant/accounting/address",
          name: "tenantAccountingAddress",
          component: () => import("@/components/Tenants/Accounting/Billing")
        },
        {
          path: "/tenant/data",
          name: "tenantData",
          component: () => import("@/components/Tenants/Account/Index")
        },
        {
          path: "/tenant/data/contact",
          name: "tenantDataContact",
          component: () => import("@/components/Tenants/Account/Index")
        },
        {
          path: "/tenant/users",
          name: "tenantUserAdministration",
          component: () => import("@/components/Tenants/Users/Index")
        },
        {
          path: "/tenant/users/:id/information",
          name: "tenantUserAdministrationDetails",
          component: () => import("@/components/Tenants/Users/Details")
        },
        {
          path: "/tenant/users/:id/profile",
          name: "tenantUserAdministrationDetailsProfile",
          component: () => import("@/components/Tenants/Users/Details")
        },
        {
          path: "/tenant/users/:id/access-data",
          name: "tenantUserAdministrationDetailsAccessData",
          component: () => import("@/components/Tenants/Users/Details")
        },
        {
          path: "/tenant/users/add",
          name: "tenantUserAdministrationCreate",
          component: () => import("@/components/Tenants/Users/Create")
        },
        {
          path: "/tenant/settings",
          name: "tenantSettings",
          component: () => import("@/components/Tenants/Settings/Index")
        },
        {
          path: "/tenant/welcome",
          name: "tenantWelcome",
          component: () => import("@/components/Tenants/Welcome/Index")
        },
        {
          path: "/form-helper",
          name: "formHelper",
          component: () => import("@/components/Tools/FormHelper/Index")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/components/General/Auth/Index"),
      children: [
        {
          name: "passwordReset",
          path: "/auth/password/reset",
          component: () => import("@/components/General/Auth/Index")
        },
        {
          name: "login",
          path: "/login",
          component: () => import("@/components/General/Auth/Index")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/components/SYS/errors/Error-1")
    },
    {
      path: "/builder",
      name: "builder",
      component: () => import("@/components/SYS/errors/Error-1")
    }
  ]
});
