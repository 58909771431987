export const SET_LANGUAGES = "setLanguages";

export default {
  state: {
    languages: []
  },
  getters: {
    /**
     * Get list of breadcrumbs for current page
     * @param state
     * @returns {*}
     */
    languages(state) {
      return state.languages;
    }
  },
  actions: {
    /**
     * Set the languages list
     * @param state
     * @param payload
     */
    [SET_LANGUAGES](state, payload) {
      state.commit(SET_LANGUAGES, payload);
    }
  },
  mutations: {
    [SET_LANGUAGES](state, payload) {
      state.languages = payload;
    }
  }
};
