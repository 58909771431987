import ApiService from "@/core/services/api.service";
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}
import store from "@/core/services/store";

class DesignerElements {
  get(id) {
    return ApiService.get(url, "workflow_elements/" + id);
  }
  getSingle(id) {
    return ApiService.get(url, "workflow_elements/element/" + id);
  }
  getAll() {
    if (store.getters.selectedProject) {
      ApiService.setHeader("x-project-id", store.getters.selectedProject.id);
    }
    if (process.env.VUE_APP_VERSION >= 2) {
      return this.getAssigned();
    }
    return ApiService.get(url, "workflow_elements/elements/all");
  }
  getAssigned() {
    return ApiService.get(url, "workflow_elements/all");
  }
  getTypes() {
    return ApiService.get(url, "workflow_elements/types");
  }
  action(id, data, header = {}) {
    return ApiService.post(
      url + "/workflow_elements/action/" + id,
      data,
      header
    );
  }
}
export default new DesignerElements();
