export const restConnector = {
  authentication: {
    connectorConfig_url: "Host",
    connectorConfig_type: "Authentication type",
    connectorConfig_key: "API key field",
    connectorConfig_value: "API key",
    connectorConfig_addTo: "Provide API key via",
    headers: "Headers",
    queryParameters: "Query parameters",
    connectorConfig_username: "Username",
    connectorConfig_password: "Password",
    connectorConfig_token: "Bearer Token",
    connectorActionTestConnection: "Test connection",
    connectorActionTestConnectionSuccess: "Connection tested successfully",
    connectorActionTestConnectionError: "Failed to create connection",
    version: "Connector version"
  },
  configuration: {
    requestConfig_method: "HTTP method",
    get: "GET",
    post: "POST",
    put: "PUT",
    delete: "DELETE",
    requestConfig_queryParameters: "GET parameters",
    requestConfig_queryParametersValues: {
      name: "Key",
      value: "Value"
    },
    requestConfig_headers: "Headers",
    requestConfig_headersValues: {
      name: "Key",
      value: "Value"
    },
    requestConfig_additionalConfig: "Additional options",
    requestConfig_additionalConfigValues: {
      name: "Key",
      value: "Value"
    }
  },
  input: {
    data: "Data",
    createRequestDataStructure: "Create datastructure from request",
    createRequestDataStructureSuccess: "Datastructure created successfully",
    createRequestDataStructureError: "Failed to create datastructure"
  },
  output: {
    createResponseDataStructure: "Create datastructure from response",
    createResponseDataStructureError: "Failed to create datastructure",
    createResponseDataStructureSuccess: "Datastructure created successfully"
  }
};
