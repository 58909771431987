export const cache = {
  configuration: {
    validTime: "Gültigkeitsdauer",
    fieldToValidate: "Zu validierendes Feld"
  },
  input: {
    dataToValidate: "Datensatz, der überprüft werden soll"
  },
  error: {
    log_level: "Log Level",
    log_levelOptions: {
      process: {
        error: "Error",
        info: "Info",
        warning: "Warning"
      }
    },
    log_method: "Logging-Stufe",
    error_handling: "Fehlerbehandlung",
    error_handlingOptions: {
      process_continues: "Workflow läuft weiter",
      process_aborts: "Workflow bricht automatisch ab"
    }
  }
};
