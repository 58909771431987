import store from "@/core/services/store/index";
import router from "@/router";

const MenuService = {
  setDefaultItems() {
    return store.dispatch("setDefaultItems", null).then(() => {
      return store.getters.menuItems;
    });
  },

  getItems() {
    return store.getters.menuItems;
  },

  getActiveArea() {
    let activeArea = {};
    let areas = this.getItems();

    Object.keys(areas).forEach(key => {
      let area = areas[key];
      let areaChildren = area.children;
      Object.keys(areaChildren).forEach(childKey => {
        let item = areaChildren[childKey];

        if (router.currentRoute.name.includes(item.name)) {
          activeArea = area;
          return;
        }
      });
    });

    return activeArea;
  }
};

export default MenuService;
