export const serverInfo = {
  buffered: "Buffered",
  clientDb: "Tenant database",
  free: "Free",
  idle: "Idle",
  nice: "Nice",
  sys: "Sys",
  total: "Total",
  usageCpu: "CPU usage",
  usageDisk: "Disk usage",
  usageRam: "RAM usage",
  used: "Used",
  user: "User"
};
