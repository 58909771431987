export const emailLog = {
  mailableClassName: "Mailable Class name",
  title: "Title",
  process: "Process",
  status: "Status",
  preview: "Preview",
  parameters: "Parameters",
  recipientEmail: "Recipient Email",
  senderEmail: "Sender Email",
  emailLogDetails: "Email Log Details",
  emailLogs: "Email Logs",
  deleteMessage:
    'Do you really want to delete the log "{log}"? This process is irreversible.',
  deleteTitle: "Delete log",
  deleteSucessText: 'Log "{log}" has been deleted'
};
