export const countries = {
  countries: "Countries",
  countryCreate: "Add country",
  countryCreated: "Country added successfully",
  countryDelete: "Remove country",
  countryDeleted: "Country removed successfully",
  countryDeleteText:
    'Do you really want to remove the country "{name}" irreversible?',
  countryUpdate: "Update country",
  countryUpdated: "Country updated successfully",
  disableTax: "Disable tax",
  euMember: "EU member",
  iso: "ISO-Code",
  name: "Name",
  nameInternational: "Name international",
  tax: "Tax (in %)"
};
