// action types
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const UPDATE_USER_DATA = "updateUserData";
export const UPDATE_LANGUAGE = "updateLanguage";

// mutation types
export const SET_PERSONAL_INFO = "setPersonalInfo";
export const SET_USER_DATA = "setUserData";
export const SET_LANGUAGE = "setLanguage";

const state = {
  user_personal_info: {
    id: "",
    uuid: "",
    profile_picture: "",
    name: "",
    surname: "",
    job: "",
    email: "",
    phone: "",
    username: "",
    language: "",
    language_id: ""
  },
  userData: {},
  language: localStorage.getItem("language")
};

const getters = {
  currentUserPersonalInfo(state) {
    return state.user_personal_info;
  },
  userData(state) {
    return state.userData;
  },
  language(state) {
    return state.language;
  }
};

const actions = {
  [UPDATE_PERSONAL_INFO](context, payload) {
    context.commit(SET_PERSONAL_INFO, payload);
  },
  [UPDATE_USER_DATA](context, payload) {
    context.commit(SET_USER_DATA, payload);
  },
  [UPDATE_LANGUAGE](context, payload) {
    context.commit(SET_LANGUAGE, payload);
  }
};

const mutations = {
  [SET_PERSONAL_INFO](state, user_personal_info) {
    state.user_personal_info = user_personal_info;
  },
  [SET_USER_DATA](state, data) {
    state.userData = data;
  },
  [SET_LANGUAGE](state, data) {
    state.language = data;
    localStorage.setItem("language", data);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
