import { processStatus } from "../processStatus";

export const workflowReporting = {
  workflowReporting: "Workflow Reporting",
  clients: "Mandanten Auswahl",
  clientsShowAll: "Alle Mandanten anzeigen",
  active: "Nur aktive Workflows anzeigen",
  hours: "Jobs aus diesem Zeitraum anzeigen",
  24: "Letzten 24 Stunden",
  48: "Letzten 48 Stunden",
  72: "Letzten 72 Stunden",
  last24: "aus den letzten 24 Stunden",
  last48: "aus den letzten 48 Stunden",
  last72: "aus den letzten 72 Stunden",
  status: "Job Status",
  process: processStatus,
  openReporting: "Zum Reporting",
  noProject: "Kein Projekt",
  showWorkflows: "Workflows anzeigen",
  hideWorkflows: "Workflows verbergen",
  showProjects: "Projekte anzeigen",
  hideProjects: "Projekte verbergen",
  openClients: "Kunden",
  openProjects: "Projekte",
  openWorkflows: "Workflows",
  initially_open: "Standardmäßig geöffnete Ebene",
  noStatusSelected: "Keinen Status anzeigen"
};
