export const languages = {
  languages: "Languages",
  languageCreate: "Add language",
  languageCreated: "language added successfully",
  languageDelete: "Remove language",
  languageDeleted: "language removed successfully",
  languageDeleteText:
    'Do you really want to remove the language "{name}" irreversible?',
  languageUpdate: "Update language",
  languageUpdated: "language updated successfully",
  disableTax: "Disable tax",
  euMember: "EU member",
  iso: "ISO-Code",
  name: "Name",
  nameInternational: "Name international",
  tax: "Tax (in %)"
};
