export const mappingTransformers = {
  StringReplace: {
    search: "Suchen nach",
    replace: "Ersetzen mit"
  },
  CaseConvert: {
    type: "Typ",
    upper: "Umwandlung in Großbuchstaben",
    lower: "Umwandlung in Kleinbuchstaben",
    method_upper: "Upper Method",
    method_lower: "Lower Method",
    strtoupper: "strtoupper",
    ucfirst: "ucfirst",
    ucwords: "ucwords",
    strtolower: "strtolower",
    lcfirst: "lcfirst"
  },
  CharTrimmer: {
    type: "Typ",
    trim: "Trim",
    rtrim: "Right Trim",
    ltrim: "Left Trim",
    character: "Zeichen"
  },
  HtmlEntities: {
    type: "Typ",
    htmlentities: "htmlentities",
    htmlspecialchars: "htmlspecialchars",
    html_entity_decode: "html_entity_decode",
    htmlspecialchars_decode: "htmlspecialchars_decode"
  },
  DateFormatter: {
    timestamp: "Timestamp",
    date: "Datum",
    type: "Typ",
    date_format: "Datumsformat"
  },
  NumberFormatter: {
    decimals: "Anzahl der Dezimalstellen",
    decimal_separator: "Dezimaltrennzeichen",
    thousands_separator: "Tausendertrennzeichen"
  },
  Pad: {
    length: "Länge",
    pad_string: "Auffüllen mit",
    pad_type: "Typ",
    1: "rechts auffüllen",
    0: "links auffüllen",
    2: "beidseitig auffüllen"
  },
  StringRepeater: {
    type: "Typ",
    input: "Input Variable",
    string: "Zeichenfolge",
    string_text: "Zu wiederholende Zeichenfolge",
    repeat_times: "Anzahl Wiederholungen"
  },
  CharPosition: {
    type: "Typ",
    search: "Zu suchende Zeichenfolge",
    offset: "Suche beginnen nach X Zeichen",
    strpos: "Erstes Vorkommen (case sensitive)",
    stripos: "Erstes Vorkommen (case insensitive)",
    strripos: "Letztes Vorkommen (case insensitive)",
    strrpos: "Letztes Vorkommen (case sensitive)"
  },
  StringPart: {
    offset: "Teil-Zeichenfolge beginnen nach X Zeichen",
    length: "Länge der Teil-Zeichenfolge"
  },
  Round: {
    round_type: "Round Type",
    ceil: "Aufrunden",
    floor: "Abrunden"
  },
  ExtremeValue: {
    extreme_type: "Typ",
    min: "Minimalwert",
    max: "Maximalwert"
  },
  TagStripper: {
    allowed_tags: "Allowed tags"
  },
  Concat: {
    char: "Verknüpfende Zeichenfolge"
  },
  CollectionFilter: {
    filter: "Collection Filter"
  },
  Cast: {
    type: "Typ",
    int: "int",
    float: "float",
    bool: "bool",
    string: "string"
  },
  Sort: {
    direction: "Richtung",
    asc: "Aufsteigend",
    desc: "Absteigend",
    sortBy: "Sortieren nach",
    collection: "Collection"
  },
  BasicArithmetic: {
    type: "Typ",
    add: "Addieren",
    subtract: "Subtrahieren",
    divide: "Dividieren",
    multiply: "Multiplizieren",
    modulo: "Modulo",
    moduloFirstField: "Input 1",
    moduloSecondField: "Input 2"
  },
  Collection: {
    type: "Art der Zusammenführung",
    multiply: "Multiplikation",
    sum: "Summe",
    assign: "Zuordnung",
    fixed: "Feste Anzahl",
    collection: "Collection",
    valueCount: "Anzahl",
    uniqueValues: "Einzigartige Werte"
  },
  ValueAssignment: {
    dataType: "Datentyp",
    string: "string",
    float: "float",
    int: "int",
    bool: "bool",
    text: "text",
    date: "date",
    null: "null",
    json: "json",
    stringInput: "String",
    intInput: "Number",
    floatInput: "Float",
    boolInput: "Bool",
    true: "true",
    false: "false",
    jsonInput: "JSON",
    textInput: "Text Input",
    dateInput: "Datum",
    dateInputNow: "Verwenden Sie die Funktion NOW() für das Datum",
    empty: "null"
  }
};
