export const soapConnector = {
  authentication: {
    connectorActionGetRequests: "Get requests",
    connectorActionGetRequestsSuccess: "Get requests successfully",
    connectorActionGetRequestsError: "Get requests failed",
    connectorClass: "Connectorclass",
    connectorConfig_wsdl: "WSDL",
    testConnection: "Test connection",
    testConnectionSuccess: "Test connection successfully",
    testConnectionError: "Test connection failed"
  },
  output: {
    createResponseDataStructure: "Create datastructure",
    createResponseDataStructureSuccess: "Create datastructure successfully",
    createResponseDataStructureError: "Create datastructure failed"
  },
  configuration: {
    connectorConfig_options: "Options",
    connectorConfig_optionsValues: {
      key: "Key",
      value: "Value"
    },
    createRequestDataStructure: "Create datastructure",
    createRequestDataStructureSuccess: "Create datastructure successfully",
    createRequestDataStructureError: "Create datastructure failed",
    request: "Request"
  }
};
