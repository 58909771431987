export const csvBatch = {
  configuration: {
    archiveCsvFile: "CSV-Datei archivieren",
    batchSize: "Stapelgröße",
    dataStructure: "Datenstruktur",
    delimiter: "Trennzeichen",
    encoding: "Codierung",
    help: {
      type: "CSV Datei Auswertung anhand einer Datenstruktur oder einer manuellen Konfiguration."
    },
    limit: "Limit",
    type: "Typ",
    typeOptions: {
      dataStructure: "Datenstruktur",
      manually: "Manuell"
    }
  },
  input: {
    csvPath: "CSV-Pfad",
    help: {
      csvPath: 'Bitte einen relativen Pfad angeben (z.B. "in/test.csv").'
    }
  },
  error: {
    error_handling: "Fehlerbehandlung",
    error_handlingOptions: {
      process_continues: "Workflow läuft weiter",
      process_aborts: "Workflow bricht automatisch ab"
    }
  }
};
