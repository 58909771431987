export const mapping = {
  configuration: {
    mapping: "Mapping",
    testMapping: "Mapping testen"
  },
  input: {
    source: "Quelle",
    testMapping: "Mapping testen"
  }
};
