export const xlsxBatch = {
  configuration: {
    archiveXlsxFile: "Archive XLSX-file",
    batchSize: "Batch size",
    fileContainsHeaderRow: "File contains header row",
    fileContainsHeaderRowOptions: {
      true: "Yes",
      false: "No"
    },
    limit: "Limit",
    offset: "Offset"
  },
  input: {
    headerFields: "Header fields",
    headerFieldsValues: {
      name: "Name"
    },
    help: {
      sheet:
        "If no table is specified, the first one is taken from the XLSX file.",
      xlsxPath: 'Please enter a relative path (e.g. "in/test.xlsx").'
    },
    sheet: "Table",
    xlsxPath: "XLSX-Path"
  },
  error: {
    error_handling: "Error handling",
    error_handlingOptions: {
      process_continues: "Workflow continues",
      process_aborts: "Workflow aborts"
    }
  }
};
