import { workflowReporting } from "./widgets/workflowReporting";

export const widgets = {
  delete: "Widget löschen",
  configuration: "Konfiguration",
  widgets: "Widgets",
  add: "Widget hinzufügen",
  enableEdit: "Bearbeitung aktivieren",
  disableEdit: "Bearbeitung deaktivieren",
  reloadWidgets: "Widgets neu laden",
  reloaded: "Widgets wurden neu geladen",
  workflowReporting: workflowReporting
};
