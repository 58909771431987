export const client = {
  accounting: "Accounting",
  accountingHasVat: "I got a VAT",
  accountingVatNumber: "VAT",
  accountingNoVat: "I'm a small business",
  accountingInvoicesSendEmail: "I want to receive invoices by mail",
  accountingInvoicesEmail: "Email for invoices",
  accountingInvoicing: "Invoicing",
  accountingTax: "Tax",
  active: "Active",
  apiToken: "API token",
  assignedPartner: "Assigned partner",
  baseData: "Base Data",
  billing: "Billing adress",
  billingAddress1: "Address line 1",
  billingAddress2: "Address line 2",
  billingAddress3: "Address line 3",
  billingStreet: "Street, Number",
  billingZipcode: "Zip code",
  billingCity: "City",
  billingCountry: "Country",
  cannotCreate: "Client couldn't be created",
  clientCreate: "Create client",
  clientCreated: "Client created successfully",
  clientDeleteMessage: 'Do you really want to delete "{client}" irreversible?',
  clientDeleteTitle: "Delete client",
  clientDeleted: "Client deleted successfully",
  clientNotCreated: "Client couldn't be created ",
  clientUpdate: "Update client",
  clientUpdated: "Client updated successfully",
  clients: "Clients",
  companyData: "Company data",
  contactData: "Contact data",
  contactEmail: "Email address",
  contactFax: "Fax",
  contactPersonYedi: "Responsible employee from YEDI",
  contactPhone: "Phone",
  contactWebsite: "Website",
  connection: "Connection",
  contactPerson: "Contact person",
  couldNotOpenDatabase: "Could not open database.",
  error: "Error",
  id: "#ID",
  informationClient: "Client information",
  informationClientDescription: "Edit client information",
  initialized: "Initialized",
  language: "Language",
  logo: "Logo",
  name: "Firm",
  nameEnter: "Enter name",
  nameShort: "Short name",
  noContactPersons: "No contact persons available",
  number: "Tenant number",
  settings: "Settings",
  storage: "Storage",
  configurations: "Configurations",
  storageChange: "Edit storage",
  storageData: "Storage data",
  upload: "Upload",
  uuid: "UUID",
  variables: "Variables",
  cancelCreate: "Cancel client creation",
  stepCreateDatabase: "Create database",
  stepAddClientEntry: "Add client",
  stepInitializeMongo: "Initialize MongoDB",
  stepTenantStorage: "Create client storage",
  stepTenantConnections: "Assign client connections",
  stepFtpUser: "Add FTP user",
  stepInitializeTenant: "Initialize client",
  stepActivateQueues: "Activate queues",
  stepServiceDaemons: "Restart service daemon",
  stepCreateDatabaseIntro:
    "At first a new MySQL database has to be created in Profihost ServerCon. Fill the input field with the generated login data and leave a comment like:<br><br><span class='bg-light'>Tenant DB<br>CLIENT NAME</span>",
  stepCreateDatabaseLink: "Link to ServerCon:",
  stepCreateDatabaseLoginData: "Login data",
  databaseName: "Database name",
  databaseUsername: "Username",
  databasePassword: "Password",
  clientName: "Client name",
  stepAddClientEntryIntro:
    'At the second step a new client entry has to be added to the master database. Please check the provided client name and confirm the creation by clicking  "Create client". Optionally a partner can be selected, who the client will be assigned to.',
  addClientFirst: "Please create a client first",
  addClient: "Create client",
  addClientSuccessTitle: "Client created successfully!",
  addClientSuccessMessage:
    'Client "{name}" was created successfully. Proceed to the next step.',
  addClientErrorTitle: "Oops! Something went wrong...",
  addClientErrorMessage:
    'Client "{name}" could not be created. Please try again later.',
  stepTenantStorageIntro:
    "At this step a new storage needs to be created. Connect to the server via SSH and execute following command:",
  stepTenantStorageCommand: "Command",
  stepTenantStorageStorageCreated: "Storage created",
  stepTenantStorageStorageAssigned: "Storage assigned",
  stepTenantConnectionsIntro:
    "Now the database connections get assigned to the client. First, add the MySQL connection, then the MongoDB connection. Please check the login data and confirm the assignments.",
  stepTenantConnectionsMySql: "MySQL connection",
  stepTenantConnectionsMongo: "MongoDB connection",
  stepTenantConnectionsAssign: "Assign connection",
  stepTenantConnectionsSuccess: "Connection assigned successfully!",
  stepTenantConnectionsError:
    "Connection could not be assigned. Please try again later.",
  stepTenantConnectionsWarning: "Please assign the connections first",
  stepInitializeMongoDone: "MongoDB initialized",
  stepInitializeMongoIntro:
    "As third step a new Mongo user needs to be created. Connect to the server via SSH and execute following commands in order:",
  stepFtpUserIntro:
    "Next a new FTP user has to be added in Profihost ServerCon. Following path should be provided as root path:",
  stepFtpUserRoot: "Root path",
  stepFtpUserLink: "Link to ServerCon:",
  stepFtpUserDone: "FTP user added",
  stepInitializeTenantSuccess: "Client initialized successfully!",
  stepInitializeTenantError:
    "Client could not be initialized. Please try again later.",
  stepInitializeTenantIntro:
    "The client now needs to be initialized. Initialization starts by clicking following button.",
  stepInitializeTenantConfirm: "Initialize client",
  stepInitializeTenantWarning: "Please initialize client first",
  stepActivateQueuesIntro:
    "Now the queues can be activated at will. After that, proceed to the next step.",
  queueInstance: "Instance",
  queueInstances: "Instances",
  activate: "Activate",
  deactivate: "Deactivate",
  stepServiceDaemonsIntro:
    "In the end the service daemon for the queue worker needs to be restarted. Please restart the daemon (name: YEDI Queue) in Profihost ServerCon. After that, the client creation process can be finished.",
  stepServiceDaemonsDone: "Restarted daemon",
  stepServiceDaemonsLink: "Link to ServerCon:",
  wizardFinish: "Finish",
  stepServiceDaemonsWarning: "Please restart daemon first",
  databases: "Databases",
  databaseHost: "Host",
  databasePort: "Port",
  storageType: "Storage type",
  clientWorkspaceUpdateError: "Client workspace could not be updated",
  workspaceDomainExists: "Domain already exists",
  workspaceAdd: "Add domain",
  projects: "Projects",
  openMysql: "Open MySQL",
  openMongo: "Open MongoDB",
  selectPartner: "Select Partner",
  noPartner: "No partner"
};
