export const logging = {
  input: {
    log_message: "Log Nachricht",
    data: "Daten",
    dataValues: {
      name: "Name",
      value: "Wert"
    }
  },
  configuration: {
    log_method: "Log Priorität",
    log_methodOptions: {
      log: "Info",
      error: "Fehler",
      warn: "Warnung"
    }
  }
};
