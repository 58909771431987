export const dataExplorer = {
  confirm: "Confirm",
  cancel: "Cancel",
  dataExplorer: "DataExplorer",
  dataExplorerResources: "DataExplorer - Resources",
  dataStructures: "Datastructures",
  dataSets: "DataSets",
  dataStructure: "Datastructure",
  dataSet: "DataSet",
  noDescription: "No description available",
  details: "Details",
  preview: "Preview",
  schema: "Schema",
  entriesCount: "Records",
  dirtyEntriesCount: "Dirty records",
  lastUpdated: "Last change",
  editDataSet: "Edit DataSet",
  editDataStructure: "Edit data structure",
  truncateDataStructure: "Truncate data structure",
  setCleanDataStructure: "Set all records clean",
  setDirtyDataStructure: "Set all records dirty",
  setCleanDataStructureEntry: "Set this record clean",
  setDirtyDataStructureEntry: "Set this record dirty",
  truncateText:
    'Are you sure you want to empty the "{name}" data structure? This process is irreversible.',
  truncateTitle: "Clear data structure",
  setCleanText:
    'Are you sure you want to set all records of data structure "{name}" to processed? This process is irreversible.',
  setCleanTitle: "Edit data structure",
  setCleanSuccess: "Entries set to clean",
  setDirtyText:
    'Are you sure you want to set all records of data structure "{name}" to unprocessed? This process is irreversible.',
  setDirtyTitle: "Edit data structure",
  setDirtySuccess: "Entries set to dirty",
  filter: "Filter",
  dirty: "Dirty",
  clean: "Clean",
  noFilter: "Remove filter",
  openDetails: "View data",
  deletedSuccess: "All entries have been successfully deleted",
  dataStructureFromRequest: "DataStructure (Request)"
};
