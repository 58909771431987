export const table = {
  actions: "Aktionen",
  active: "Aktiv",
  author: "Autor",
  availableAt: "Verfügbar am",
  className: "Klassenname",
  contentIdentifier: "Content Identifier",
  createdAt: "Erstellt am",
  datetime: "Datum/Uhrzeit",
  description: "Beschreibung",
  detailsHide: "Weniger Details",
  end: "Ende",
  finishedAt: "Enddatum",
  id: "#ID",
  initialized: "Initialisiert",
  isLocked: "Gesperrt",
  label: "Bezeichnung",
  language: "Sprache",
  lastRun: "Letzter Durchlauf",
  level: "Ebene",
  logo: "Logo",
  mail: "E-Mail",
  message: "Nachricht",
  name: "Name",
  nameDisplay: "Anzeigename",
  noRecords: "Keine Datensätze gefunden",
  numberClient: "Kundennummer",
  numberPartner: "Partnernummer",
  overview: "Übersicht",
  partner: "Partner",
  parameter: "Parameter",
  preview: "Vorschau",
  primary: "Primärschlüssel",
  project: "Projekt",
  queue: "Queue",
  receiver: "Empfänger",
  release: "Veröffentlichung",
  reservedAt: "Reserviert am",
  role: "Rolle",
  schedule: "Zeitplan",
  sender: "Sender",
  short: "Kürzel",
  source: "Quelle",
  showLess: "Weniger zeigen",
  showMore: "Mehr zeigen",
  start: "Start",
  startedAt: "Startdatum",
  status: "Status",
  subject: "Betreff",
  tableName: "Tabellenname",
  tenant: "Mandant",
  timestamp: "Zeitpunkt",
  title: "Titel",
  token: "Token",
  total: "Gesamt",
  type: "Typ",
  updatedAt: "Aktualisiert am",
  url: "URL",
  user: "Benutzer",
  uuid: "#UUID",
  version: "Version",
  workflows: "Workflows",
  workflowComponent: "Workflow-Komponente",
  is_locked: "Gesperrt",
  comment: "Kommentar",
  baseDataStructureType: "Datenstrukturtyp",
  progress: "Fortschritt"
};
