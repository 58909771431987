export const table = {
  actions: "Actions",
  active: "Active",
  author: "Author",
  availableAt: "Available at",
  className: "Class name",
  contentIdentifier: "Content identifier",
  createdAt: "Created at",
  datetime: "Date/Time",
  description: "Description",
  detailsHide: "Hide details",
  end: "End",
  finishedAt: "Finished at",
  id: "#ID",
  initialized: "Initialized",
  isLocked: "Is locked",
  label: "Label",
  language: "Language",
  lastRun: "Last run",
  logo: "Logo",
  mail: "Email",
  message: "Message",
  name: "Name",
  nameDisplay: "Display name",
  noRecords: "No records found",
  numberClient: "Client number",
  numberPartner: "Partner number",
  overview: "Overview",
  partner: "Partner",
  parameter: "Parameter",
  preview: "Preview",
  primary: "Primary key",
  project: "Project",
  queue: "Queue",
  receiver: "Receiver",
  release: "Release",
  reservedAt: "Reserved at",
  role: "Role",
  schedule: "Schedule",
  sender: "Sender",
  short: "Short",
  showLess: "Show less",
  showMore: "Show more",
  start: "Start",
  startedAt: "Started at",
  status: "Status",
  subject: "Subject",
  tableName: "Table name",
  tenant: "Tenant",
  timestamp: "Timestamp",
  title: "Title",
  token: "Token",
  total: "Total",
  type: "Type",
  updatedAt: "Updated at",
  url: "URL",
  user: "User",
  uuid: "#UUID",
  version: "Version",
  workflows: "Workflows",
  workflowComponent: "Workflow component",
  is_locked: "Locked",
  comment: "Comment",
  baseDataStructureType: "Base data structure type",
  progress: "Progress"
};
