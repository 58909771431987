export const partner = {
  accounting: "Buchhaltung",
  accountingHasVat: "Ich habe eine Umsatzsteuer-ID",
  accountingInvoicesEmail: "E-Mail für Rechnungen",
  accountingInvoicesSendEmail: "Ich möchte Rechnungen per E-Mail erhalten",
  accountingInvoicing: "Rechnungsstellung",
  accountingNoVat: "Ich bin Kleinunternehmer",
  accountingTax: "Steuer",
  accountingVatNumber: "Umsatzsteuer-ID",
  active: "Aktiv",
  assignedPartnerLabel: "Partner, der für diesen Mandanten verantwortlich ist.",
  apiToken: "API Token",
  baseData: "Stammdaten",
  billing: "Rechnungsadresse",
  billingAddress1: "Adresszeile 1",
  billingAddress2: "Adresszeile 2",
  billingAddress3: "Adresszeile 3",
  billingCity: "Ort",
  billingCountry: "Land",
  billingStreet: "Straße, Hausnummer",
  billingZipcode: "PLZ",
  cannotCreate: "Partner kann nicht erstellt werden",
  companyData: "Unternehmendaten",
  cropProfilePicture: "Profilbild zuschneiden",
  connection: "Verbindung",
  contactData: "Kontaktdaten",
  contactEmail: "E-Mail Adresse",
  contactFax: "Fax",
  contactPerson: "Ansprechpartner",
  contactPersonYedi:
    "Mitarbeiter YEDI, der diesen Mandanten verantwortlich betreut",
  contactPersonPartner:
    "Mitarbeiter des Partners, der diesen Mandanten verantwortlich betreut",
  contactPhone: "Telefon",
  contactWebsite: "Website",
  id: "#ID",
  informationPartner: "Partnerninformationen",
  informationPartnerDescription: "Partnerninformationen bearbeiten",
  initialized: "Initialisiert",
  language: "Sprache",
  logo: "Logo",
  name: "Firmenname",
  nameEnter: "Namen eingeben",
  nameShort: "Kürzel",
  number: "Partnernnummer",
  partnerCreate: "Partner erstellen",
  partnerCreated: "Partner wurde erstellt",
  partnerDeleteMessage:
    'Möchten Sie den Partner "{partner}" wirklich löschen? Dieser Prozess ist irreversibel.',
  partnerDeleteTitle: "Partner löschen",
  partnerDeleted: "Partner erfolgreich gelöscht",
  partnerNotCreated: "Partner nicht erstellt",
  partnerUpdate: "Partner bearbeiten",
  partnerUpdated: "Partner wurde erfolgreich aktualisiert",
  partner: "Partner",
  partners: "Partner",
  password: "Passwort",
  passwordConfirmation: "Passwort Bestätigung",
  settings: "Einstellungen",
  storage: "Storage",
  storageChange: "Storage bearbeiten",
  storageData: "Storage Daten",
  upload: "Hochladen",
  uuid: "UUID",
  variables: "Variablen",
  cancelCreate: "Partneranlage abbrechen",
  stepCreateDatabase: "Datenbank anlegen",
  stepAddPartnerEntry: "Partner anlegen",
  stepInitializeMongo: "MongoDB initialisieren",
  stepTenantStorage: "Partner Storage erstellen",
  stepTenantConnections: "Partner Connections zuweisen ",
  stepFtpUser: "FTP Benutzer anlegen",
  stepInitializeTenant: "Partner initialisieren",
  stepActivateQueues: "Queues aktivieren",
  stepServiceDaemons: "Service Daemon neu starten",
  stepCreateDatabaseIntro:
    "Als erster Schritt bei der Anlage eines Partner muss in der Profihost ServerCon eine neue MySQL Datenbank für den Partner angelegt werden. Die generierten Zugangsdaten bitte in die unten stehenden Formularfelder eintragen und als Bemerkung:<br><br><span class='bg-light'>Partner DB<br>PARTNERNAME</span>",
  stepCreateDatabaseLink: "Link zum ServerCon:",
  stepCreateDatabaseLoginData: "Datenbank Zugangsdaten",
  databaseName: "Datenbankname",
  databaseUsername: "Benutzername",
  databasePassword: "Passwort",
  partnerName: "Partnername",
  stepAddPartnerEntryIntro:
    'Im zweiten Schritt muss ein Eintrag für den neuen Partner in der Master DB angelegt werden. Dazu bitte überprüfen, ob der Partnername korrekt ist und die Anlage über den Button "Partner anlegen" bestätigen.',
  addPartnerFirst: "Bitte zuerst einen Partner anlegen",
  addPartner: "Partner anlegen",
  addPartnerSuccessTitle: "Partner wurde erfolgreich angelegt!",
  addPartnerSuccessMessage:
    'Der Partner "{name}" wurde erfolgreich angelegt. Nun geht es weiter zum nächsten Schritt.',
  addPartnerErrorTitle: "Ups! Etwas ist schief gelaufen...",
  addPartnerErrorMessage:
    'Der Partner "{name}" konnte nicht angelegt werden. Bitte versuchen Sie es später erneut.',
  stepTenantStorageIntro:
    "In diesem Schritt muss ein Storage für den Mandanten angelegt werden. Dazu bitte per SSH mit dem Server verbinden und folgenden Befehl ausführen:",
  stepTenantStorageCommand: "Befehl",
  stepTenantStorageStorageCreated: "Storage wurde erstellt",
  stepTenantConnectionsIntro:
    "Nun müssen dem Mandanten die Datenbankverbindungen zugewiesen werden. Zuerst wird die MySQL Datenbank zugewiesen, danach die MongoDB. Dazu bitte die Zugangsadaten auf ihre Richtigkeit prüfen und die Zuweisung bestätigen.",
  stepTenantConnectionsMySql: "MySQL Verbindung",
  stepTenantConnectionsMongo: "MongoDB Verbindung",
  stepTenantConnectionsAssign: "Verbindung zuweisen",
  stepTenantConnectionsSuccess:
    "Verbindung konnte erfolgreich zugewiesen werden!",
  stepTenantConnectionsError:
    "Die Verbindung konnte nicht zugewiesen werden. Bitte versuchen Sie es später erneut.",
  stepTenantConnectionsWarning: "Bitte zuerst die Verbindungen zuweisen",
  stepInitializeMongoDone: "MongoDB initialisiert",
  stepInitializeMongoIntro:
    "Als Drittes muss ein neuer User für MongoDB angelegt werden. Dazu bitte per SSH mit dem Server verbinden und folgende Befehle nacheinander im Terminal ausführen:",
  stepFtpUserIntro:
    "Als nächstes muss ein neuer FTP Benutzer in der ServerCon angelgt werden. Der User sollte folgenden Pfad als Root-Verzeichnis haben:",
  stepFtpUserRoot: "Root-Verzeichnis",
  stepFtpUserLink: "Link zum ServerCon:",
  stepFtpUserDone: "FTP-User angelegt",
  stepInitializeTenantSuccess: "Der Partner wurde erfolgreich initalisiert!",
  stepInitializeTenantError:
    "Der Partner konnte nicht initialisiert werden. Bitte versuchen Sie es später erneut.",
  stepInitializeTenantIntro:
    "Der Partner muss nun initialisert werden. Durch Bestätung des folgenden Buttons wird die Intialisierung gestartet.",
  stepInitializeTenantConfirm: "Partner initialisieren",
  stepInitializeTenantWarning: "Bitte zuerst den Partner initialisieren",
  stepActivateQueuesIntro:
    "Hier können nun die Queues des Partners nach Belieben aktiviert werden. Dann geht es weiter zum nächsten Schritt.",
  queueInstance: "Instanz",
  queueInstances: "Instanzen",
  activate: "Aktivieren",
  deactivate: "Deaktivieren",
  stepServiceDaemonsIntro:
    "Zuletzt muss noch der Service Daemon für den Queue Worker neu gestartet werden. Dazu bitte im ServerCon den entsprechenden Daemon (Name: YEDI Queue) neu starten. Danach kann die Mandantenanlage beendet werden.",
  stepServiceDaemonsDone: "Service Daemon neu gestartet",
  stepServiceDaemonsLink: "Link zum ServerCon:",
  wizardFinish: "Partneranlage abschließen",
  stepServiceDaemonsWarning: "Bitte zuerst den Service Daemon neu starten",
  databases: "Datenbanken",
  databaseHost: "Host",
  databasePort: "Port",
  storageType: "Speichertyp",
  salutation: "Anrede",
  clientWorkspaceUpdateError:
    "Der Mandant-Arbeitsbereich konnte nicht aktualisiert werden",
  workspaceDomainExists: "Domain existiert bereits",
  workspaceAdd: "Domain hinzufügen",
  mail: "E-Mail Adresse",
  mailEnter: "E-Mail Adresse eingeben",
  user: "User",
  updateBaseDataAndProfilePicture: "Stammdaten und Profilbild aktualisieren",
  profilePicture: "Profilbild",
  removeProfilePicture: "Profilbild entfernen",
  uploadProfilePicture: "Profilbild hochladen",
  enterSalutation: "Bitte geben Sie die Form des Grußes ein",
  enterTitle: "Bitte geben Sie den Titel ein",
  title: "Titel",
  firstName: "Vorname",
  enterFirstName: "Bitte geben Sie den Vornamen des Benutzers ein",
  lastName: "Nachname",
  enterLastName: "Bitte geben Sie den Nachnamen des Benutzers ein",
  email: "E-Mail",
  enterEmail: "Bitte geben Sie die E-Mail-Adresse des Benutzers ein",
  passwordChangeDescription: "Passwort für Account ändern",
  passwordChangeTitle: "Passwort ändern",
  passwordCurrentRequired: "Aktuelles Passwort erforderlich",
  passwordNew: "Neues Passwort",
  passwordNewConfirm: "Neues Passwort bestätigen",
  passwordNewConfirmRequired: "Bestätigung des Passworts erforderlich",
  passwordNewRequired: "Neues Passwort erforderlich",
  passwordNotMatching: "Die Passwörter stimmen nicht überein",
  dataProvideCorrectly: "Die Daten bitte korrekt eingeben!",
  profile: "Profil",
  reviewAndSubmit: "Überprüfen und einreichen",
  loginSecurity: "Anmeldesicherheit"
};
