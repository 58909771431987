export const branch = {
  configuration: {
    condition: "Bedingung",
    conditions: "Bedingungen",
    conditionsValues: {
      left: "Linker Wert",
      operator: "Operator",
      right: "Rechter Wert"
    },
    type: "Welche Bedingungen sollen ausgeführt werden?",
    typeOptions: {
      first: "Nur erste zutreffende",
      all: "Alle zutreffenden"
    },
    lastCondition:
      "Letzter Zweig: Wird ausgeführt wenn keine der Bedingungen zutrifft.",
    addCondition: "Bedingung hinzufügen",
    conditionLabel: "Bezeichnung"
  }
};
