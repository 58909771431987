export const rest = {
  authentication: {
    password: "Password",
    testConnection: "Test connection",
    testConnectionSuccess: "Connection tested successfully",
    testConnectionError: "Connection test failed",
    type: "Type of authentication",
    typeOptions: {
      apikey: "Apikey",
      none: "No authentication",
      password: "Password",
      bearer: "Bearer Token"
    },
    url: "URL",
    username: "Username",
    key: "Key",
    value: "Value",
    addTo: "Send via",
    addToOptions: {
      headers: "Headers",
      queryParameters: "Query parameters"
    },
    token: "Token"
  },
  configuration: {},
  input: {
    additional_config: "Additional config",
    additional_configValues: {
      name: "Name",
      value: "Value"
    },
    data: "Data",
    dataValues: {
      name: "Name",
      value: "Value"
    },
    endpoint: "Endpoint",
    headers: "Header",
    headersValues: {
      name: "Name",
      value: "Value"
    },
    method: "Methode",
    methodOptions: {
      delete: "delete",
      get: "get",
      post: "post",
      put: "put"
    },
    query_parameters: "Query parameters",
    query_parametersValues: {
      name: "Name",
      value: "Value"
    },
    testConnection: "Test connection",
    testConnectionSuccess: "Connection tested successfully",
    testConnectionError: "Connection test failed"
  },
  output: {
    response: "Response"
  },
  error: {
    log_level: "Log Level",
    log_levelOptions: {
      process: {
        info: "Info",
        warning: "Warning",
        error: "Error"
      }
    },
    error_handling: "Error handling",
    error_handlingOptions: {
      process_continues: "Workflow continues",
      process_aborts: "Workflow aborts"
    }
  }
};
