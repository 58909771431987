export const mapping = {
  configuration: {
    mapping: "Mapping",
    testMapping: "Test mapping"
  },
  input: {
    source: "Source",
    testMapping: "Test mapping"
  }
};
