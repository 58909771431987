export const emailLog = {
  mailableClassName: "verschickbare Klassenname",
  title: "Titel",
  process: "Prozess",
  status: "Status",
  preview: "Vorschau",
  parameters: "Parameters",
  recipientEmail: "Empfänger E-Mail",
  senderEmail: "Absender E-Mail",
  emailLogDetails: "E-Mail Log Details",
  emailLogs: "E-Mail Logs",
  deleteMessage:
    'Möchten Sie den Log "{log}" wirklich löschen? Dieser Vorgang ist unwiderruflich.',
  deleteTitle: "Log löschen",
  deleteSucessText: 'Log "{log}" wurde gelöscht'
};
