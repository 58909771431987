export const plugins = {
  plugins: "Plugins",
  label: "Label",
  description: "Description",
  status: "Status",
  statusInstalledBy: "Installed by {user} on {date}",
  statusNotInstalled: "Not installed",
  pluginDetails: "Plugin details",
  workflowElements: "Workflow elements",
  eventSubscribers: "Event subscribers",
  installedAt: "Installed at",
  updatedAt: "Updated at",
  confirmInstallTitle: "Install plugin?",
  confirmInstallText: 'Are you sure you want to install "{plugin}" plugin?',
  confirmUninstallTitle: "Uninstall plugin?",
  confirmUninstallText: 'Are you sure you want to uninstall "{plugin}" plugin?',
  confirmInstallButton: "Install plugin",
  confirmUninstallButton: "Uninstall plugin",
  installTooltip: "Install plugin",
  uninstallTooltip: "Uninstall plugin",
  infoTooltip: "Plugin details",
  connectors: "Connectors"
};
