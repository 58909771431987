export const processLog = {
  iterationFinishedAt: "Beendet am",
  iterationHideDetails: "Jobdetails verbergen",
  iterationId: "Job ID",
  iterationLoading: "Jobdetails werden geladen...",
  iterationParameters: "Parameter",
  iterationQueue: "Queue",
  iterationStartedAt: "Gestartet am",
  iterationStartedBy: "Gestartet von",
  iterationStatus: "Status",
  logCreatedAt: "Erstellt",
  logId: "Log ID",
  logMessage: "Nachricht",
  logParameters: "Parameter",
  logType: "Typ",
  logUpdatedAt: "Zuletzt geändert",
  noIterations: "Keine Jobs vorhanden.",
  noProcesses: "Keine Prozesse angelegt.",
  processLogs: "Prozesslogs",
  selectIteration: "Job auswählen",
  selectProcess: "Prozess auswählen",
  tabDetailsTitle: "Jobdetails",
  tabLogLoading: "Log-Details werden geladen..."
};
