export const mapping = {
  backToOverview: "Back to overview",
  connectionsToggle: "Show connection lines",
  connectionDeleteTitle: "Delete connection?",
  connectionDeleteText:
    "If the connection gets deleted, already configured transformers may not work properly anymore. Do you really want to delete the connection?",
  dataStructureSearch: "Search {name}",
  undo: "Undo",
  redo: "Redo",
  modeMapping: "Data Mapper",
  modeCode: "Code",
  modeDebug: "Debug",
  mappingSaved: "Mapping saved",
  initialVariables: "Input variables",
  transformersOutput: "Transformers output",
  transformersOutputEmpty: "No output defined yet.",
  selectDataSet: "Select data set",
  selectDataSetFirst: "A data set has to be selected first in debug mode.",
  noDataSets: "No data sets found for this structure.",
  mappingUpdated: "Mapping saved",
  mappings: "Mappings",
  mappingCreate: "Create mapping",
  mappingLabel: "Label",
  mappingDescription: "Description",
  mappingActive: "Active",
  mappingDataStructureSource: "Source data structure",
  mappingDataStructureTarget: "Target data structure",
  mappingSave: "Save mapping",
  dataStructureSource: "Source data structure",
  dataStructureTarget: "Target data structure",
  detailsBasicConfiguration: "Basis configuration",
  maxInputValues:
    'Maximum amount of input values for transformer "{label}": {maxValues}',
  configurationTitle: "Configuration of {label}",
  configurationNoType: "Select transformer type",
  configurationNoTransformer: "Select transformer for configuration",
  availableVariables: "Available variables",
  noAvailableVariables: "No input variables available",
  mappingNotSavedTitle: "Discard changes?",
  mappingNotSavedText:
    "Mapping configuration changes were not saved yet. Do you want to continue despite? These changes will be discarded irreversibly.",
  continueDespite: "Continue despite",
  startMapping: "Start mapping",
  runningMapping: "Running mapping",
  notMapped: "Field not mapped",
  mappingFinished: "Mapping finished successfully",
  transformers: "Transformers",
  transformer: "Transformer",
  transformerLabel: "Label",
  transformerTransformer: "Selected transformer",
  transformerInputVars: "Input variables",
  transformerOutput: "Output",
  runMappingFirst:
    "To show debug information you have to select a data set and start a mapping first.",
  dataStructureChangeTitle: "Edit data structure?",
  dataStructureChangeText:
    "Editing the data structure can lead to error in current mapping. Also unsaved changes to current mapping will be discarded. Do you want to continue despite?",
  delete: "Delete mapping",
  edit: "Open mapping",
  configuration: "Edit mapping",
  outputValueDefault: "By default, the output of the last transformer run.",
  addTransformer: "Add transformer",
  noInputVars: "No input variables defined.",
  conditions: "Conditions",
  conditionsEmpty: "No conditions set.",
  noLabel: "Label...",
  transformerConfigurationBasic: "Basic configuration",
  transformerConfigurationConditions: "Conditions",
  transformerConfigurationTransformer: "Transformer configuration",
  transformerConfigurationUpdated: "Configuration accepted",
  transformerEdit: "Configure Transformers",
  selectField: "Select field",
  addAndCondition: "+ and condition",
  addOrCondition: "+ or condition",
  removeConditionHelp: "Delete condition",
  clearConditionHelp: "Empty condition",
  typeString: "Text",
  typeNumber: "Number",
  typeBoolean: "Bool",
  typeNull: "null",
  typeVariable: "Variable",
  addConditionGroup: "+ and",
  resultElements: "Result elements",
  elementIndex: "Index",
  onlyRequiredFields: "Only required fields"
};
