export const projects = {
  deleted: "Project '{name}' deleted.",
  description: "Description",
  finishedAt: "Finished at",
  generalInformation: "Information",
  image: "Image",
  informationProject: "Projectinformation",
  loadImage: "Load image",
  name: "Name",
  noDateSelected: "No date selected",
  noImageFound: "No image found",
  removeProject: "Remove {name}?",
  project: "Project",
  projectCreate: "Create project",
  saved: "Project '{name}' saved.",
  short: "Short",
  startedAt: "Started at",
  title: "Projects",
  url: "URL",
  workflows: "Workflows"
};
