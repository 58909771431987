export const dynamicModels = {
  acceptedFileTypes: "Unterstützte Dateitypen",
  acceptedFormats: "Unterstützte Formate",
  addDynamicField: "Dynamisches Feld hinzufügen",
  analyze: "Analysieren",
  analyzeSettings: "Analyse-Einstellungen",
  basicSettings: "Grundeinstellungen",
  cancelCreate: "Model-Anlage abbrechen",
  className: "Klassenname",
  commaSeparated: "Mehrere Tags durch Komma trennen.",
  confirmType: "Typ bestätigen",
  createModel: "Model anlegen",
  defaultTen: "Standardwert 10",
  deleteModelText:
    'Möchten Sie das Model "{name}" wirklich löschen? Dieser Vorgang ist unwiderruflich.',
  deleteModelTitle: "Model löschen",
  delimiter: "Trennzeichen",
  details: "Detailansicht",
  detailsBasic: "Basisinformationen",
  detailsBasicConfiguration: "Basiskonfiguration",
  detailsTable: "Tabellenkonfiguration",
  detectAutomatically: "Automatisch erkennen",
  dotNotation: "Auswahl durch Dot-Notation.",
  dropPlaceholder: "Datei hier reinziehen",
  dynamicFieldGlue: "Trennzeichen",
  dynamicFieldName: "Feld Name",
  dynamicFieldPreview: "Vorschau",
  dynamicFieldPlaceholder: "dynamic field",
  dynamicFieldValues: "Felder Auswahl",
  dynamicFields: "Dynamische Felder",
  dynamicModels: "Dynamic Models",
  emptyForAutoDetection: "Leer lassen für automatische Erkennung.",
  enclosure: "Sonderzeichen Erkennung",
  encoding: "Codierung",
  entryQuantity: "Anzahl zu analysierender Einträge",
  entryQuantityHelp: "Es werden maximal diese Anzahl von Einträgen analysiert.",
  file: "Datei",
  filePlaceholder: "Datei auswählen oder hineinziehen...",
  fileSelection: "Dateiauswahl",
  fileType: "Dateityp",
  forceArrayOn: "Array erzwingen bei",
  forceEncoding: "Codierung erzwingen",
  showForceEncodingHelp:
    "Codierung überprüfen, wenn eine Datei in den DataStore geladen werden soll.",
  forceArrayOnAdd: "Feld hinzufügen",
  forceArrayOnHelp:
    "Kind-Elemente des Tags immer als Array behandeln. Direkter Tag Name, keine Dot-Notation.",
  goToEditor: "Weiter zum Editor",
  mandatoryField: "Pflichtfeld.",
  modelDeletedSuccess: "Model wurde erfolgreich gelöscht",
  modelName: "Modelname",
  modelStoreErrorText:
    "Bei der Anlage des Models ist folgender Fehler aufgetreten:<br><code>{msg}</code>",
  modelStoreErrorTitle: "Model-Anlage abgebrochen",
  modelStoredText:
    'Das Model "{name}" wurde erfolgreich angelegt und kann ab sofort verwendet werden.',
  modelStoredTitle: "Model angelegt",
  modelType: "Modeltyp",
  modelUpdated: "Model wurde erfolgreich aktualisiert",
  next: "Nächster Schritt",
  noType:
    "Bei der Anlage des Models scheint kein Typ gespeichert worden zu sein. Bitte im nachfolgenden Feld den Typ des Models auswählen und über den Button bestätigen, damit die richtigen Konfigurationsfelder angezeigt werden können. Die Auswahl des Typ ist nach dem Speichern nicht veränderbar.",
  placeholderName: "Name des Feldes",
  placeholderNoType: "Model Typ auswählen...",
  placeholderSelect: "Auswählen...",
  previewAndConfiguration: "Vorschau & Konfiguration",
  preview: "Vorschau (Zeile 1-{rows})",
  previewLines: "Zeilenanzahl für Vorschau",
  previous: "Vorheriger Schritt",
  primaryKey: "Primärschlüssel",
  primaryXmlHelp:
    "Auswahl des Primärschlüssels durch Dot-Notation oder Klick auf Baum-Eintrag der Vorschau.",
  reanalyzeFile: "Datei neu analysieren",
  resetsTableConfig: "Setzt Tabellenkonfiguration zurück",
  saveModel: "Model speichern",
  selectByNodeClick: "Auswahl durch Klick auf Baum-Eintrag.",
  selectedConfiguration: "Ausgewählte Konfiguration",
  selector: "Ziel XML-Tag",
  selectorHelp: "Auswahl des Ziel Tags durch Dot-Notation.",
  selectPrimaryKey: "Bitte wählen Sie einen Primärschlüssel aus.",
  showPreview: "Vorschau anzeigen",
  showPreviewHelp:
    "Bei großen Dateien wird aus Performancegründen empfohlen die Vorschau auszuschalten.",
  stepAnalyze: "Datei analysieren",
  stepBasicConfig: "Basiskonfiguration",
  stepSave: "Model speichern",
  stepTableConfig: "Tabellenkonfiguration",
  tableName: "Tabellenname",
  tablePreview: "Tabellenvorschau (Zeile 1-{rows})",
  textInput: "Texteingabe",
  typeInvalid: "Ungültiges {type}",
  maxDepth: "Maximale Tiefe",
  maxDepthHelp: "Es werden maximal diese Anzahl von Ebenen analysiert.",
  iterationMaxDepth: "Maximale Tiefe der Iteration",
  type: "Typ",
  csv: "CSV",
  xml: "XML",
  json: "JSON"
};
