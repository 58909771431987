export const ftp = {
  authentication: {
    password: "Password",
    username: "Username"
  },
  configuration: {
    host: "Host",
    root: "Root",
    port: "Port",
    protocol: "protocol",
    protocolOptions: {
      ftp: "FTP",
      sftp: "SFTP"
    },
    ssl: "SSL",
    testConnection: "Test connection",
    testConnectionSuccess: "Connection tested successfully",
    testConnectionError: "Connection test failed",
    type: "Typ",
    typeOptions: {
      downloadMove: "Download and delete",
      downloadCopy: "Download",
      exists: "Exists",
      listFiles: "List files",
      uploadMove: "Upload and delete",
      uploadCopy: "Upload",
      delete: "Delete"
    }
  },
  input: {
    filePath: "Filepath",
    files: "Files",
    pattern: "Pattern"
  },
  output: {
    files: "Files"
  },
  error: {
    log_level: "Log Level",
    log_levelOptions: {
      process: {
        info: "Info",
        warning: "Warning",
        error: "Error"
      }
    },
    error_handling: "Error handling",
    error_handlingOptions: {
      process_continues: "Workflow continues",
      process_aborts: "Workflow aborts"
    },
    coreError: 'Something went wrong. We cannot read the files from the path'
  },
};
