export const queryEditor = {
  dataTable: "Data table",
  schema: "Schema",
  fieldName: "Feldname",
  fieldLabel: "Feldbezeichnung",
  description: "Beschreibung",
  dataType: "Datentyp",
  index: "Index",
  allowNull: "Erlaube NULL",
  unique: "Einzigartig",
  sqlSyntaxError:
    "Sie haben einen SQL-Syntaxfehler. Bitte überprüfen Sie Ihre SQL-Abfrage und versuchen Sie es erneut",
  sqlSyntaxEmpty: "Die SQL-Abfrage ist leer",
  newQuery: "Neue Abfrage",
  openQuery: "Öffnen",
  saveQuery: "Speichern",
  saveQueryAs: "Speichern unter",
  enterQueryName: "Bitte geben Sie den Namen der Abfrage ein",
  enterQueryDescription: "Bitte geben Sie die Abfragebeschreibung ein",
  enterQueryNameAndDescription:
    "Bitte geben Sie den Namen der Abfrage und die Beschreibung ein",
  updateQuery: "Abfrage aktualisieren",
  querySyntaxOk: "Die Abfragesyntax ist gültig",
  queryUpdated: "Abfrage aktualisiert",
  queryCreated: "Abfrage erstellt",
  deleteTitle: "Abfrage löschen",
  deleteMessage:
    'Möchten Sie die Abfrage "{query}" wirklich löschen? Dieser Vorgang ist unwiderruflich.',
  deleteSuccessText: 'Die Abfrage "{query}" wurde gelöscht',
  savedQueries: "Data Sets",
  resource: "Ressource",
  preview: "Vorschau",
  parameter: "Parameter",
  parameterName: "Name",
  parameterValue: "Test Value",
  runQuery: "Ausführen",
  standardQueries: "Standard Queries",
  standardQuery: "Standard Query",
  applyQueryToolTip: "Query Übernehmen",
  notSaved: "Nicht gespeichert",
  modePlanning: "Planung",
  modeCode: "Code",
  queryName: "Query name",
  queryDescription: "Beschreibung",
  previewResourceResults: "Ressource data Vorschau",
  previewQueryResults: "Query data Vorschau",
  selectedFields: "Ausgewählte Felder",
  addField: "Feld hinzufügen",
  conditions: "Bedinungen"
};
