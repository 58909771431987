import { admin } from "./components/admin";
import { user } from "./components/user";
import { billing } from "./components/billing";
import { client } from "./components/client";
import { companyData } from "./components/companyData";
import { config } from "./components/config";
import { countries } from "./components/countries";
import { dashboard } from "./components/dashboard";
import { dataStructures } from "./components/dataStructures";
import { dynamicModels } from "./components/dynamicModels";
import { emailLog } from "./components/emailLog";
import { emailSettings } from "./components/emailSettings";
import { error } from "./components/error";
import { general } from "./components/general";
import { integrations } from "./components/integrations";
import { journal } from "./components/journal";
import { languages } from "./components/languages";
import { login } from "./components/login";
import { mailRecipient } from "./components/mailRecipient";
import { mailTemplates } from "./components/mailTemplates";
import { media } from "./components/media";
import { menu } from "./components/menu";
import { news } from "@/i18n/de-de/components/news";
import { notifications } from "./components/notifications.js";
import { partner } from "./components/partner";
import { processCodeEditor } from "./components/processCodeEditor";
import { processManager } from "./components/processManager";
import { processStatus } from "./components/processStatus";
import { processes } from "./components/processes";
import { projects } from "./components/projects.js";
import { queryEditor } from "./components/queryEditor";
import { queuedJobs } from "./components/queuedJobs";
import { queues } from "./components/queues";
import { reporting } from "./components/reporting";
import { role } from "./components/role";
import { scheduleMethods } from "./components/scheduleMethods";
import { scheduleParams } from "./components/scheduleParams";
import { search } from "./components/search";
import { serverInfo } from "./components/server_info";
import { settings } from "./components/settings";
import { storage } from "./components/storage";
import { table } from "./components/table";
import { tenantConnections } from "./components/tenantConnections";
import { tool } from "./components/tool";
import { topbar } from "./components/topbar";
import { users } from "./components/users";
import { usersGrantIps } from "./components/userGrantIps";
import { validation } from "./components/validation";
import { weekdays } from "./components/weekdays";
import { widgets } from "./components/widgets";
import { workflowDesigner } from "./components/workflowDesigner";
import { workflowElements } from "./components/workflowElements";
import { communication } from "./components/communication";
import { workflowToken } from "./components/workflowToken";
import { support } from "./components/support";
import { mapping } from "./components/mapping";
import { processLog } from "./components/process_log";
import { mappingTransformers } from "./components/mappingTransformers";
import { changeBatches } from "./components/changeBatches";
import { insights } from "./components/insights";
import { grantedIps } from "@/i18n/de-de/components/grantedIps";
import { notes } from "@/i18n/de-de/components/notes";
import { dataExplorer } from "./components/dataExplorer";
import { dataSets } from "@/i18n/de-de/components/dataSets";
import { plugins } from "@/i18n/de-de/components/plugins";
import { textTemplates } from "./components/textTemplates";
import { formHelper } from "@/i18n/de-de/components/formHelper";
import { variables } from "@/i18n/de-de/components/variables";

export const locale = {
  admin: admin,
  user: user,
  billing: billing,
  client: client,
  companyData: companyData,
  config: config,
  countries: countries,
  dashboard: dashboard,
  dataStructures: dataStructures,
  dynamicModels: dynamicModels,
  emailLog: emailLog,
  emailSettings: emailSettings,
  error: error,
  general: general,
  integrations: integrations,
  journal: journal,
  languages: languages,
  login: login,
  mailRecipient: mailRecipient,
  mailTemplates: mailTemplates,
  media: media,
  menu: menu,
  news: news,
  notifications: notifications,
  partner: partner,
  processCodeEditor: processCodeEditor,
  processManager: processManager,
  processStatus: processStatus,
  processes: processes,
  projects: projects,
  queryEditor: queryEditor,
  queuedJobs: queuedJobs,
  queues: queues,
  reporting: reporting,
  role: role,
  scheduleMethods: scheduleMethods,
  scheduleParams: scheduleParams,
  search: search,
  serverInfo: serverInfo,
  settings: settings,
  storage: storage,
  table: table,
  tenantConnections: tenantConnections,
  tool: tool,
  topbar: topbar,
  users: users,
  usersGrantIps: usersGrantIps,
  validation: validation,
  weekdays: weekdays,
  widgets: widgets,
  workflowDesigner: workflowDesigner,
  workflowElements: workflowElements,
  communication: communication,
  workflowToken: workflowToken,
  support: support,
  mapping: mapping,
  processLog: processLog,
  mappingTransformers: mappingTransformers,
  changeBatches: changeBatches,
  insights: insights,
  grantedIps: grantedIps,
  notes: notes,
  dataExplorer: dataExplorer,
  dataSets: dataSets,
  plugins: plugins,
  textTemplates: textTemplates,
  formHelper: formHelper,
  variables: variables
};
