export const mssql = {
  authentication: {
    database: "Datenbank",
    help: {
      testConnection:
        "Versucht eine Verbindung zur MS SQL-Datenbank herzustellen und gibt bei Erfolg die Tabellen und Views zurück"
    },
    host: "Host",
    password: "Passwort",
    port: "Port",
    testConnection: "Verbindung testen",
    testConnectionSuccess: "Verbindung testen erfolgreich",
    testConnectionError: "Verbindung testen fehlgeschlagen",
    username: "Benutzername"
  },
  configuration: {
    columns: "Spalten",
    help: {
      testQuery:
        "Testet die konfigurierte Abfrage und gibt bei Erfolg die ersten 5 Datensätze zurück"
    },
    table: "Tabelle/View",
    testQuery: "Abfrage testen",
    testQueryError: "Abfrage testen fehlgeschlagen",
    testQuerySuccess: "Abfrage testen erfolgreich",
    type: "Typ",
    where: "Bedingungen"
  },
  input: {
    help: {
      filter: "SQL WHERE Bedingung (Beispiel: name = '{{ output.name }}')"
    },
    filter: "Filter"
  },
  error: {
    log_level: "Log Level",
    log_levelOptions: {
      process: {
        error: "Error",
        info: "Info",
        warning: "Warning"
      }
    },
    log_method: "Logging-Stufe",
    error_handling: "Fehlerbehandlung",
    error_handlingOptions: {
      process_continues: "Workflow läuft weiter",
      process_aborts: "Workflow bricht automatisch ab"
    }
  }
};
