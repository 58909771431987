export const news = {
  news: "News",
  newsCreate: "Newsbeitrag anlegen",
  deleteTitle: "Newsbeitrag löschen",
  deleteContent:
    'Soll der Newsbeitrag "{title}" endgültig gelöscht werden? Der Vorgang ist irreversibel.',
  deleteSuccess: "Newsbeitrag erfolgreich gelöscht",
  createSuccess: "Newsbeitrag erfolgreich angelegt",
  editSuccess: "Newsbeitrag erfolgreich aktualisiert",
  title: "Titel",
  content: "Inhalt",
  release: "Veröffentlichungsdatum",
  releaseHelp:
    "Das Datum, welches für den Kunden neben dem Newsbeitrag als Veröffentlichungsdatum angezeigt werden soll.",
  author: "Autor",
  selectPlaceholder: "Auswählen...",
  newsDetails: "Newsbeitrag bearbeiten"
};
