export const general = {
  active: "Aktiv",
  back: "Zurück",
  backToOverview: "Zurück zur Übersicht",
  cancel: "Abbrechen",
  caution: "Achtung",
  changeImage: "Bild ändern",
  confirm: "Bestätigen",
  close: "Schließen",
  copied: "Erfolgreich in Zwischenablage kopiert",
  copy: "Kopieren",
  copyToClipboard: "In Zwischenablage kopieren",
  delete: "Löschen",
  deleted: "Gelöscht",
  details: "Einzelheiten",
  done: "Fertig",
  edit: "Bearbeiten",
  email: "E-Mail",
  fileReaderNotSupported:
    "Entschuldigung, diese Dateiauswahl API wird nicht unterstüzt!",
  inactive: "Inaktiv",
  language: "Sprache",
  loading: "Wird geladen",
  minutes: "minuten",
  next: "Weiter",
  nextStep: "Nächster Schritt",
  no: "Nein",
  noConfigAvailable: "Keine Konfiguration verfügbar",
  noRecords: "Ergebnisse 0 von 0",
  notAvailable: "Nicht verfügbar",
  overview: "Übersicht",
  phone: "Telefon",
  preview: "Vorschau",
  previous: "Zurück",
  previousStep: "Vorheriger Schritt",
  records: "Ergebnisse {from}-{to} von {total}",
  recordsPerPage: "Ergebnisse pro Seite",
  reload: "Neu laden",
  remove: "Entfernen",
  removeImage: "Bild entfernen",
  reset: "Zurücksetzen",
  save: "Speichern",
  saveChanges: "Änderungen speichern",
  search: "Suchen",
  seconds: "sekunden",
  selectClientFirst: "Bitte wählen Sie zuerst einen Kunden aus",
  send: "Senden",
  sendMessage: "Nachricht senden",
  activate: "Aktivieren",
  deactivate: "Deaktivieren",
  status: "Status",
  uploadImage: "Bild hochladen",
  uploadedImage: "Hochgeladenes Bild",
  yedi: "YEDI",
  yes: "Ja",
  yourContactPerson: "Ihr Ansprechpartner",
  noContactPerson: "Kein Ansprechpartner zugewiesen",
  yourPartner: "Ihr Partner",
  noPartner: "Kein Partner zugewiesen",
  sessionExpiredTitle: "Sitzung abgelaufen",
  sessionExpiredMessage: "Sie wurden automatisch abgemeldet",
  timeoutLimitExceeded:
    "Anscheinend dauert es zu lange, bis der Server antwortet. Bitte versuchen Sie es später erneut",
  selectionOther: "(+{count} andere)",
  takeOn: "Übernehmen",
  empty: "Leer",
  autoUpdate: "Automatische Aktualisierung",
  expandedMenu: "Menü expandiert",
  directInput: "Direkteingabe",
  addField: "+ Feld hinzufügen",
  help: "Hilfe",
  settings: "Einstellungen",
  logout: "Abmelden",
  profile: "Profil"
};
