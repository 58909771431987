export const branch = {
  configuration: {
    condition: "Condition",
    conditions: "Conditions",
    conditionsValues: {
      left: "Left value",
      operator: "Operator",
      right: "Right value"
    },
    type: "Which conditions should be executed?",
    typeOptions: {
      first: "Only first matching",
      all: "All matching"
    },
    lastCondition: "Last arm: Executes if no other condition matches.",
    addCondition: "Add condition",
    conditionLabel: "Label"
  }
};
