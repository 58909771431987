import Vue from "vue";
import VueI18n from "vue-i18n";

// Localisation language list
import { locale as en } from "@/i18n/en-us/index";
import { locale as de } from "@/i18n/de-de/index";

Vue.use(VueI18n);

let messages = { en, de };

// get current selected language
const lang = localStorage.getItem("language") || "de";

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages // set locale messages
});

export default i18n;
