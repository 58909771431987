export const companyData = {
  accounting: "Accounting",
  accountingInvoicesEmail: "Email for invoices",
  accountingVatNumber: "VAT",
  baseData: "Base Data",
  billing: "Billing adress",
  billingAddress1: "Address line 1",
  billingAddress2: "Address line 2",
  billingAddress3: "Address line 3",
  billingCity: "City",
  billingCountry: "Country",
  billingStreet: "Street, Number",
  billingZipcode: "Zip code",
  companyData: "Company data",
  companyUpdate: "Update company data",
  companyUpdated: "Company data updated successfully",
  contactData: "Contact data",
  contactEmail: "Email address",
  contactFax: "Fax",
  contactPhone: "Phone",
  contactWebsite: "Website",
  id: "#ID",
  language: "Language",
  logo: "Logo",
  name: "Firm",
  nameEnter: "Enter name",
  nameShort: "Short name",
  bankDetails: "Bank details",
  accountHolder: "Account holder",
  bankName: "Bank name",
  iban: "IBAN",
  swiftBic: "SWIFT/BIC",
  uploadLogo: "Upload Logo",
  removeLogo: "Remove Logo",
  cropLogo: "Crop Logo"
};
