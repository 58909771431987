export const insights = {
  addGraph: "Graph hinzufügen",
  chartTitle: "Titel",
  commonSettings: "Allgemeine Einstellungen",
  configuration: "Konfiguration",
  create: "Insight erstellen",
  customization: "Anpassungen",
  customizeAxisX: "x-Achse anpassen",
  customizeAxisY: "y-Achse anpassen",
  customizeGeneral: "Allgemeine Anpassungen",
  customizeGraphs: "Graphen anpassen",
  customizeOptions: "Anpassungs-Einstellungen",
  dataSets: "Data Sets",
  delete: "Insight löschen",
  deleteSuccess: "Insight gelöscht",
  deleteText:
    'Möchten Sie das Insight "{label}" wirklich löschen? Dieser Vorgang ist unwiderruflich.',
  deleteTitle: "Insight löschen",
  edit: "Insight bearbeiten",
  finish: "Speichern und beenden",
  graph: "Graph",
  graphAxisHint: "Es muss zuerst ein Data Set ausgewählt werden",
  graphAxisNoFields: "Es sind keine Felder für dieses Data Set verfügbar",
  graphAxisX: "Datenfeld x-Achse",
  graphAxisY: "Datenfeld y-Achse",
  graphDataSet: "Data Set",
  graphLabel: "Bezeichnung",
  label: "Bezeichnung",
  loadPreview: "Vorschau laden",
  reloadPreview: "Daten aktualisieren",
  saveAndContinue: "Speichern und fortfahren",
  selectType: "Diagramm auswählen",
  selectTypeWarning: "Bitte wählen Sie zuerst ein Diagramm aus",
  show: "Insight ansehen",
  showDataLabels: "Datenwerte auf Graph anzeigen",
  showMarkers: "Datenpunkte anzeigen",
  theme: "Theme",
  title: "Insights",
  type: "Diagramm Art",
  typeArea: "Flächen",
  typeBar: "Balken",
  typeColumn: "Säulen",
  typeCustom: "Benutzerdefiniert",
  typeDonut: "Donut",
  typeLine: "Linien",
  typePie: "Kuchen",
  xAxisSettings: "Einstellungen x-Achse",
  xAxisTitle: "Titel x-Achse",
  xAxisUnit: "Feste Einheit wählen",
  xAxisUnitAuto: "Einheiten automatisch generieren",
  xUnitDays: "Tage (01., 02., ...)",
  xUnitHours12: "Stunden (12h)",
  xUnitHours24: "Stunden (24h)",
  xUnitMonthsNumbers: "Monate (01, 02, ...)",
  xUnitMonthsText: "Monate (Jan., Feb., ...)",
  xUnitWeekdays: "Wochentage (Mo., Di., ...)",
  xUnitYears: "Jahre",
  yAxisTitle: "Titel y-Achse",
  curveSmooth: "Abgerundet",
  curveStraight: "Gerade",
  curveStepLine: "Stufen",
  curve: "Linien-Darstellung",
  customizeBars: "Balken-Darstellung",
  stacked: "Werte stapeln",
  stackType: "Stapel-Aufteilung",
  stackTypeAbsolute: "Absolut",
  stackTypeRelative: "Relativ",
  pieCategory: "Kategorie",
  pieValue: "Wert",
  isDonut: "Donut Darstellung",
  customizePie: "Tortendiagramm anpassen",
  customizeDonut: "Donutdiagramm anpassen",
  startAngle: "Startwinkel",
  endAngle: "Endwinkel",
  donutSize: "Donut Größe",
  dataPipelines: "DataPipelines",
  graphDataPipeline: "Quell-DataPipeline"
};
