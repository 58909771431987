export const scheduleParams = {
  day: "Tag",
  days: "Tage",
  endTime: "Endzeit",
  expression: "Ausdruck",
  first: "Erster Zeitpunkt",
  offset: "Minuten nach voller Stunde",
  position: "Position",
  second: "Zweiter Zeitpunkt",
  startTime: "Startzeit",
  time: "Zeit",
  timezone: "Zeitzone",
  value: "Wert"
};
