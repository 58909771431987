export const image = {
  configuration: {
    compressionOptions: {
      none: "None",
      resmush: "Resmush"
    },
    compression: "Image compression",
    quality: "Quality (0-100)",
    info: "Info"
  },
  input: {
    imagePath: "Path/Url of image"
  }
};
