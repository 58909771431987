export const news = {
  news: "News",
  newsCreate: "Create article",
  deleteTitle: "Delete article",
  deleteContent:
    'Do you really want to delete article "{title}"? This process is irreversible.',
  deleteSuccess: "Article deleted",
  createSuccess: "Article created",
  editSuccess: "Article updated",
  title: "Title",
  content: "Content",
  release: "Release data",
  releaseHelp: "This date will be shown to the client as release date.",
  author: "Author",
  selectPlaceholder: "Select...",
  newsDetails: "Edit article"
};
