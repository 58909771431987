export const logging = {
  input: {
    log_message: "Log message",
    data: "Data",
    dataValues: {
      name: "Name",
      value: "Value"
    }
  },
  configuration: {
    log_method: "Log priority",
    log_methodOptions: {
      log: "Info",
      error: "Error",
      warn: "Warning"
    }
  }
};
