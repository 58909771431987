export const mailTemplates = {
  mailTemplates: "Email templates",
  name: "Name",
  className: "Class name",
  deleteConfirm: 'Delete template "{template}" ?',
  deleteConfirmText:
    'Do you really want to delete the template "{template}"? You cannot restore this after delete.',
  deleteSucessText: "Template {template} has been deleted",
  variables: "Variables",
  editTemplate: "Edit template",
  preview: "Preview",
  updated: "Template updated",
  backToOverview: "Back to overview"
};
