export const email = {
  authentication: {
    host: "Host",
    password: "Password",
    port: "Port",
    smtpAuth: "SMTP",
    ssl: "SSL",
    type: "Type of authentication",
    typeOptions: {
      smtp: "SMTP",
      system: "System"
    },
    username: "Username"
  },
  configuration: {
    from_name: "From name",
    from_mail: "From email",
    reply_name: "Reply to name",
    reply_mail: "Reply to email",
    template: "Mailtemplate"
  },
  input: {
    subject: "Subject",
    to: "Recipient",
    toValues: {
      address: "Address",
      name: "Name"
    },
    cc: "CC",
    ccValues: {
      address: "Address",
      name: "Name"
    },
    bcc: "BCC",
    bccValues: {
      address: "Address",
      name: "Name"
    },
    content: "Content",
    attachments: "Attachments"
  },
  output: {},
  error: {
    log_method: "Log priority",
    log_methodOptions: {
      error: "error",
      log: "log",
      none: "none",
      warn: "warn"
    },
    log_levelOptions: {
      0: "0 - Disable debugging",
      1: "1 - Output messages sent by the client.",
      2: "2 - as 1, plus responses received from the server (this is the most useful setting).",
      3: "3 - as 2, plus more information about the initial connection - this level can help diagnose STARTTLS failures.",
      4: "4 - as 3, plus even lower-level information, very verbose, don't use for debugging SMTP, only low-level problems."
    },
    log_message: "Log message",
    trigger_process: "Workflow",
    log_level: "Log Level"
  }
};
