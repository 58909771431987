import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import Auth from "@/components/General/Auth/auth";
import router from "@/router";
import Swal from "sweetalert2";
import i18n from "@/core/plugins/vue-i18n";

let cache = {};

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    axios.defaults.timeout =
      (process.env.VUE_APP_AXIOS_TIMEOUT_LIMIT_IN_SECONDS || 20) * 1000; //20s,
    axios.defaults.timeoutErrorMessage = i18n.t("general.timeoutLimitExceeded");
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (router.history.current.name !== "login") {
          if (
            typeof error.response !== "undefined" &&
            error.response.status === 401
          ) {
            Swal.fire({
              title: i18n.t("general.sessionExpiredTitle"),
              text: i18n.t("general.sessionExpiredMessage"),
              icon: "error"
            });
            Auth.logout();
            router.push({ name: "login" });
          }
        }
        return Promise.reject(error);
      }
    );
    axios.interceptors.request.use(function (value) {
      return value;
    });

    Vue.use(VueAxios, axios);
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader(key, value) {
    // console.trace(key + " " + value);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;

    if (key !== undefined) {
      axios.defaults.headers.common[key] = value;
    }
  },
  removeHeader(key) {
    delete Vue.axios.defaults.headers.common[key];
  },
  getHeader(key) {
    return Vue.axios.defaults.headers.common[key];
  },
  removeAllHeaders() {
    delete Vue.axios.defaults.headers.common["undefined"];
    delete Vue.axios.defaults.headers.common["Authorization"];
    delete Vue.axios.defaults.headers.common["x-api-key"];
    delete Vue.axios.defaults.headers.common["x-partner-key"];
    delete Vue.axios.defaults.headers.common["x-workspace"];
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @param optionalHeaders
   * @returns {*}
   */
  get(resource, slug = "", optionalHeaders = {}) {
    let url = resource;
    if (slug && typeof slug === "string" && !slug.startsWith("?")) {
      url += "/" + slug;
    }
    return Vue.axios.get(url, optionalHeaders);
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @param optionalHeaders
   * @returns {*}
   */
  post(resource, params, optionalHeaders = {}) {
    return Vue.axios.post(`${resource}`, params, optionalHeaders);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @param optionalHeaders
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params, optionalHeaders = {}) {
    return Vue.axios.put(`${resource}/${slug}`, params, optionalHeaders);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @param optionalHeaders
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params, optionalHeaders = {}) {
    return Vue.axios.put(`${resource}`, params, optionalHeaders);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @param optionalHeaders
   * @returns {*}
   */
  delete(resource, optionalHeaders = {}) {
    return Vue.axios.delete(resource, optionalHeaders);
  },

  setCache(url, data = true, timeInMs = 5000) {
    if (this.isCached(url)) {
      return;
    }
    cache[url] = data;
    setTimeout(function () {
      cache[url] = false;
    }, timeInMs);
  },

  isCached(url) {
    if (typeof cache[url] === "undefined") {
      return false;
    }
    return cache[url];
  },

  clearCache() {
    cache = {};
  }
};

export default ApiService;
