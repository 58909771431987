import ApiService from "@/core/services/api.service";
import MenuService from "@/core/services/menu.service";
import store from "@/core/services/store";

ApiService.setHeader();

/*
ApiService.setHeader("x-api-key", store.getters.apiToken);
if (store.getters.apiTokenPartner) {
  ApiService.setHeader(
      "x-partner-key",
      store.getters.apiTokenPartner
  );
}
*/
let url = process.env.VUE_APP_API_ADMIN;
const urlTenant = process.env.VUE_APP_API_TENANT;

class Partners {
  get(id) {
    let area = MenuService.getActiveArea();

    if (
      store.getters.userType === "admin" &&
      !["areaTenant", "areaPartner"].includes(area.name)
    ) {
      return ApiService.get(url, "partners/" + id);
    }
    return ApiService.get(urlTenant, "partners/" + id);
  }
  getAll(params, cancelToken) {
    let options = new URLSearchParams(params).toString();

    let area = MenuService.getActiveArea();

    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }
    if (
      store.getters.userType === "admin" &&
      !["areaTenant", "areaPartner"].includes(area.name)
    ) {
      return ApiService.get(url, "partners?" + options, headers);
    }
    return ApiService.get(urlTenant, "partners?" + options, headers);
  }
  store(data) {
    return ApiService.post(url + "/partners", data);
  }
  update(id, data) {
    let area = MenuService.getActiveArea();

    if (
      store.getters.userType === "admin" &&
      !["areaTenant", "areaPartner"].includes(area.name)
    ) {
      return ApiService.put(url + "/partners/" + id, data);
    }
    return ApiService.put(urlTenant + "/partners/" + id, data);
  }
  delete(id) {
    return ApiService.delete(url + "/partners/" + id);
  }
}
export default new Partners();
