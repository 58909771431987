export const support = {
  client: "Mandant: {value}",
  comment: "Kommentar",
  contact: "Kontakt aufnehmen",
  contactPerson: "Ansprechpartner: {value}",
  contactPersons: "Ansprechpartner",
  email: "E-Mail-Adresse",
  files: "Dateien",
  name: "Name",
  partner: "Partner: {value}",
  requestTo: "Anfrage an {to}",
  saved: "Supportanfrage erstellt",
  subject: "Betreff",
  title: "Support"
};
