export const pimcore = {
  authentication: {
    url: "URL der Pimcore-Instanz",
    apiKey: "API Key",
    testConnection: "Verbindung zur Pimcore-Instanz testen",
    testConnectionSuccess:
      "Verbindung zur Pimcore-Instanz erfolgreich hergestellt",
    testConnectionError: "Verbindung zur Pimcore-Instanz fehlgeschlagen"
  },
  configuration: {
    resource: "Ressource",
    resourceOptions: {
      dataObject: "Datenobjekt",
      asset: "Asset",
      custom: "Custom"
    },
    customFunction: "Custom Function",
    customFunctionOptions: {
      getById: "Lade anhand der ID",
      getByPath: "Lade anhand des Pfades",
      getChildrenById: "Lade Kinder anhand der ID",
      getChildrenByPath: "Lade Kinder anhand des Pfades",
      getDirty: "Lade unverarbeitete Datensätze",
      setClean: "Markiere Datensatz als verarbeitet"
    },
    createDatastructure:
      "Datenstruktur erstellen (Schema: Pimcore%Klassenname%)",
    createDatastructureSuccess: "Datenstruktur wurde erfolgreich angelegt",
    createDatastructureError: "Datenstruktur konnte nicht angelegt werden",
    action: "Aktion",
    actionOptions: {
      create: "CREATE",
      read: "READ",
      update: "UPDATE",
      delete: "DELETE",
      write: "WRITE",
      dataStructure: "Datenstruktur anlegen"
    },
    detailed: "Datensätze vollständig zurückgeben",
    datastructureDemoId: "Demo ID",
    help: {
      detailed:
        "Objekte werden mit allen Daten zurückgegeben (andernfalls nur ID)",
      raw: "Wird diese Option aktiviert, wird der Request nicht direkt an Pimcore gesendet, sondern kann als Parameter in späteren Pimcore Elementen genutzt werden."
    },
    raw: "Rohdaten zurückgeben",
    assetSource: "Asset Herkunft",
    assetSourceOptions: {
      url: "URL",
      external: "Mandanten Storage"
    }
  },
  input: {
    id: "Objekt ID aus Pimcore",
    parents: "Liste von Elternobjekten",
    parentsValues: {
      id: "Abfrage-Aktion"
    },
    customId: "ID",
    customPath: "Pfad",
    customFilter: "Filter",
    customClass: "Klasse",
    help: {
      filter:
        "SQL WHERE Bedingung (häufig genutzte Spalten: o_id, o_parentId, o_path, o_type, o_key, o_className)",
      parentId:
        "ID des Elternobjektes, in welchem das neue Objekt angelegt werden soll",
      class:
        "Pimcore-Klassenname: Wird zur Anlage von neuen Objekten und zur Filterung von Klassen-spezifischen Attributen genutzt",
      data: "Schreibende Daten (aus Mapping)",
      key: "Eindeutiger Identifier für den Pimcore-Baum",
      isVariant: "Wird als Pimcore Variante angelegt (nicht empfohlen)"
    },
    data: "Daten",
    writeMode: "Schreibmodus",
    fileName: "Dateiname",
    offset: "Offset",
    orderKey: "Sortierung nach Feld",
    customSettings: "Benutzerdefinierte Einstellungen",
    customSettingsValues: {
      name: "Name",
      value: "Wert"
    },
    metaData: "Meta-Daten",
    metaDataValues: {
      name: "Name",
      value: "Wert"
    },
    isFolder: "Ist Ordner",
    limit: "Limit",
    order: "Sortierrichtung",
    orderOptions: {
      desc: "DESC",
      asc: "ASC"
    },
    filter: "Filter-Query",
    filterValues: {
      query: "WHERE-Bedingung"
    },
    class: "Datenobjekt-Klasse",
    key: "Eindeutiger Schlüssel",
    notPublished: "Datensatz nicht veröffentlichen",
    isVariant: "Ist Variante?",
    type: "Filter nach object, variant, folder (Mehrfachangaben möglich)",
    unpublished: "Unveröffentlicht",
    parentId: "Eltern-ID",
    imageUrl: "Bild-URL",
    imagePath: "Bild-Pfad"
  },
  error: {
    logMethod: "Logging-Stufe",
    logMethodOptions: {
      none: "None",
      log: "Log",
      warn: "Warnung",
      error: "Fehler"
    },
    logMessage: "Log-Nachricht"
  }
};
