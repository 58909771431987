// action types
export const UPDATE_TEST_PROCESS_DATA = "updateTestProcessData";

// mutation types
export const SET_TEST_PROCESS_DATA = "setTestProcessData";

export default {
  state: {
    testProcessData: ""
  },
  getters: {
    testProcessData(state) {
      return state.testProcessData ? JSON.parse(state.testProcessData) : [];
    }
  },
  actions: {
    [UPDATE_TEST_PROCESS_DATA](context, payload) {
      context.commit(SET_TEST_PROCESS_DATA, payload);
    }
  },
  mutations: {
    [SET_TEST_PROCESS_DATA](state, payload) {
      // we need to do this, because JSON.stringify ignores string key values in arrays
      const data = Object.assign({}, payload);
      state.testProcessData = JSON.stringify(data);
    }
  }
};
