export const reporting = {
  process: "Prozess",
  reporting: "Reporting",
  lastIteration: "Letzter Durchlauf",
  lastIterationStatus: "Status",
  iterationsCount: "Jobs letzter Durchlauf",
  iterationsCountError: "Davon fehlerhaft",
  generalInformation: "Allgemeine Informationen",
  seconds: "{value}  Sekunden",
  lastCycleDuration: "Dauer letzter Durchlauf",
  lastCycleDurationWithChilds: "Dauer letzter Durchlauf inklusive Kindjobs",
  averageCycleDuration: "Durchschnittliche Dauer",
  averageCycleDurationWithChilds: "Durchschnittliche Dauer inklusive Kindjobs",
  averageCycleDurationWithChildsLast7Days:
    "Durchschnittliche Dauer inklusive Kindjobs in den letzten 7 Tagen",
  lastJobError: "Letzter fehlerhafter Job",
  lastIterations: "Jobs",
  selectAll: "Alle auswählen",
  status: "Status",
  duration: "Dauer (min:sek)",
  durationApproximately: "≈ 00:01",
  childJobs: "Kindjobs",
  lastIterationCount: "Anzahl Jobs letzter Durchlauf",
  lastIterationAverageDuration: "Durchschnittliche Dauer letzter Durchlauf",
  statusCount: "Anzahl Status letzter Durchlauf",
  jobs: "Jobs {process}",
  cycle: "Durchlauf",
  noChildJobs: "Keine Kindjobs",
  selectionOther: "(+{count} andere)",
  averageDays: "Anzahl Tage für Berechnung",
  noIterations: "Keine Jobs vorhanden.",
  jobDetails: "Jobdetails",
  contentIdentifier: "Content Identifier",
  startedBy: "Gestartet von",
  startedAt: "Gestartet am",
  finishedAt: "Beendet am",
  queue: "Queue",
  parameter: "Parameter",
  details: "Details",
  logs: "Logs",
  showDetails: "Details anzeigen",
  systemEmails: "Vom System gesendete E-Mails",
  systemEmailsEmpty: "Keine Mails im Log",
  recipient: "Empfänger",
  returnValues: "Rückgabewert",
  elementInfo: "Element-Information",
  toDesigner: "Zum Designer",
  overview: "Übersicht",
  jobEvaluation: "Vollständige Status-Auswertung",
  count: "Anzahl",
  totalCycleDuration: "Gesamtdurchlauf",
  averageCycleDurationLast7Days:
    "Durchschnittliche Dauer in den letzten 7 Tagen",
  estimatedEnd: "Voraussichtliches Ende",
  nextCycle: "Nächster Durchlauf",
  total: "Summe",
  start: "Start",
  stop: "Ende",
  completeTimeEvaluation: "Vollständige Zeit-Auswertung",
  noChildJobsWereTriggered: "Es wurden keine Kind-Jobs ausgelöst",
  noSchedulerPlanned: "Kein Durchlauf geplant",
  openPayload: "Payload anzeigen",
  payloadTitle: "Payload",
  time: "Zeitraum",
  chooseTimeFrom: "Zeitraum von",
  chooseTimeTo: "Zeitraum bis"
};
