export const zip = {
  configuration: {
    archiveFile: "Archive file",
    type: "Proceeding",
    typeOptions: {
      zipFile: "Compress into ZIP file",
      unzipFile: "Unzip file"
    }
  },
  input: {
    filePath: "File path",
    help: {
      filePath: 'Please provide a relative path (e.g. "in/test.csv").',
      destinationPath: 'Please provide a relative path (e.g. "out/test.csv").'
    },
    destinationPath: "Destination path"
  },
  error: {
    error_handling: "Error handling",
    error_handlingOptions: {
      process_continues: "Workflow continues",
      process_aborts: "Workflow aborts"
    }
  }
};
