export const deepl = {
  authentication: {
    apiKey: "Auth-Key"
  },
  configuration: {
    source_lang: "Quellsprache",
    target_lang: "Zielsprache",
    source_langOptions: {
      BG: "Bulgarisch",
      CS: "Tschechisch",
      DA: "Dänisch",
      DE: "Deutsch",
      EL: "Griechisch",
      EN: "Englisch",
      ES: "Spanisch",
      ET: "Estnisch",
      FI: "Finnisch",
      FR: "Französisch",
      HU: "Ungarisch",
      ID: "Indonesisch",
      IT: "Italienisch",
      JA: "Japanisch",
      LT: "Litauisch",
      LV: "Lettisch",
      NL: "Niederländisch",
      PL: "Polnisch",
      PT: "Portugiesisch",
      RO: "Rumänisch",
      RU: "Russisch",
      SK: "Slowakisch",
      SL: "Slowenien",
      SV: "Schwedisch",
      TR: "Türkisch",
      ZH: "Chinesisch"
    },
    target_langOptions: {
      BG: "Bulgarisch",
      CS: "Tschechisch",
      DA: "Dänisch",
      DE: "Deutsch",
      EL: "Griechisch",
      "EN-GB": "Englisch (UK)",
      "EN-US": "Englisch (US)",
      ES: "Spanisch",
      ET: "Estnisch",
      FI: "Finnisch",
      FR: "Französisch",
      HU: "Ungarisch",
      ID: "Indonesisch",
      IT: "Italienisch",
      JA: "Japanisch",
      LT: "Litauisch",
      LV: "Lettisch",
      NL: "Niederländisch",
      PL: "Polnisch",
      "PT-PT": "Portugiesisch",
      "PT-BR": "Portugiesisch (Brasilianisch)",
      RO: "Rumänisch",
      RU: "Russisch",
      SK: "Slowakisch",
      SL: "Slowenien",
      SV: "Schwedisch",
      TR: "Türkisch",
      ZH: "Chinesisch"
    }
  },
  input: {
    text: "Text"
  },
  error: {
    log_level: "Log Level",
    log_levelOptions: {
      process: {
        error: "Error",
        info: "Info",
        warning: "Warning"
      }
    },
    log_method: "Logging-Stufe",
    error_handling: "Fehlerbehandlung",
    error_handlingOptions: {
      process_continues: "Workflow läuft weiter",
      process_aborts: "Workflow bricht automatisch ab"
    }
  }
};
