export const deepl = {
  authentication: {
    apiKey: "Auth-Key"
  },
  configuration: {
    source_lang: "Source language",
    target_lang: "Target language",
    source_langOptions: {
      BG: "Bulgarian",
      CS: "Czech",
      DA: "Danish",
      DE: "German",
      EL: "Greek",
      EN: "English",
      ES: "Spanish",
      ET: "Estonian",
      FI: "Finnish",
      FR: "French",
      HU: "Hungarian",
      ID: "Indonesian",
      IT: "Italian",
      JA: "Japanese",
      LT: "Lithuanian",
      LV: "Latvian",
      NL: "Dutch",
      PL: "Polish",
      PT: "Portuguese",
      RO: "Romanian",
      RU: "Russian",
      SK: "Slovak",
      SL: "Slovenian",
      SV: "Swedish",
      TR: "Turkish",
      ZH: "Chinese"
    },
    target_langOptions: {
      BG: "Bulgarian",
      CS: "Czech",
      DA: "Danish",
      DE: "German",
      EL: "Greek",
      "EN-GB": "English (British)",
      "EN-US": "English (American)",
      ES: "Spanish",
      ET: "Estonian",
      FI: "Finnish",
      FR: "French",
      HU: "Hungarian",
      ID: "Indonesian",
      IT: "Italian",
      JA: "Japanese",
      LT: "Lithuanian",
      LV: "Latvian",
      NL: "Dutch",
      PL: "Polish",
      "PT-PT": "Portuguese",
      "PT-BR": "Portuguese (Brazilian)",
      RO: "Romanian",
      RU: "Russian",
      SK: "Slovak",
      SL: "Slovenian",
      SV: "Swedish",
      TR: "Turkish",
      ZH: "Chinese"
    }
  },
  input: {
    text: "Text"
  },
  error: {
    log_level: "Log Level",
    log_levelOptions: {
      process: {
        info: "Info",
        warning: "Warning",
        error: "Error"
      }
    },
    error_handling: "Error handling",
    error_handlingOptions: {
      process_continues: "Workflow continues",
      process_aborts: "Workflow aborts"
    }
  }
};
