export const integrations = {
  addResource: "Hinzufügen",
  analyzeData: "Daten analysieren",
  authSuccess: "Authentifizierung erfolgreich",
  basicConfig: "Basiskonfiguration",
  create: "Neue Integration",
  createTitle: "Neue {connector}-Integration",
  deleteResource: "Entfernen",
  groupAll: "Alle (A-Z)",
  groupFilesApis: "Files & Services APIs",
  groupErps: "Databases ERPs",
  groupCrm: "CRM",
  groupPim: "PIM",
  groupShops: "Shops",
  loginInformation: "Zugangsdaten",
  saveIntegration: "Integration speichern",
  selectConnector: "Konnektor wählen",
  selectData: "Daten auswählen",
  selectMethod: "Methode auswählen",
  selectResource: "Resource auswählen",
  tableConfig: " Tabellenkonfiguratin",
  testAuthentication: "Authentifizierung testen",
  testAuthenticationErrorTitle: "Authentifizierung fehlgeschlagen",
  testAuthenticationErrorMessage: "<code>{msg}</code>",
  title: "Integrationen"
};
