export const processCodeEditor = {
  processCodeEditor: "Code editor",
  save: "Save",
  className: "Class name",
  temporaryClassName: "Temporary class name",
  updated: "Process code saved",
  lastModifiedBy: "Last modified by",
  lastModifiedOn: "Last modified on",
  showCommits: "Show commits",
  showDiffs: "Show differences",
  showFileContent: "Show file content",
  commitedDate: "Committed date",
  authorName: "Author name",
  authorEmail: "Author email",
  commitShortId: "Commit short id",
  totalCommits: "Total commits",
  commitsTable: "Commits table",
  fileDifferences: "File differences",
  gitFileContent: "Git file content",
  copyAboveCodeToActualCode:
    "Copy and paste the code above into the actual code",
  history: "History",
  copyParameter: "Copy the parameter data from this job to the clipboard",
  parametersJsonFormat: "Parameters (JSON format)",
  runJob: "Run",
  status: "Status",
  startedBy: "Started by",
  queue: "Queue",
  runnedTime: "Runned time",
  gitProjectNotFound:
    "We can't find the Git project for this tenant. Please make sure that the project exists and that the project slug matches the client UUID",
  noParametersFound: "No parameters found"
};
