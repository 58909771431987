export const mysql = {
  connectionTestToViewTables: "Um die Datenbanktabellen anzuzeigen, führen Sie bitte zuerst „Verbindung testen“ aus",
  databaseTables: "Datenbanktabellen",
  showDatabaseTables: "Datenbanktabellen anzeigen",
  disclaimer: "Haftungsausschluss: Alle MySQL-Abfragen werden in Ihrem eigenen Namen ausgeführt. Wir können die geänderten Daten nicht wiederherstellen.",
  parsedTwig: "Verarbeitete Template",
  authentication: {
    help: {
      testConnection: "Verbingung Testen",
      parseTwig: "Twig Parsen",
    },
    testConnection: "Verbingung Testen"
  },
  configuration: {
    parseTwig: "Twig Parsen",
    testQuery: "Ausführen (TEST) MySQL-Abfrage",
    help: {
      testQuery: "Ausführen (TEST) MySQL-Abfrage",
    }
  }
};
