export const datanorm = {
  configuration: {
    version: "Version",
    versionOptions: {
      v4: "Version 4"
    },
    method: "Method",
    methodOptions: {
      article: "Article"
    },
    title: "Title",
    description: "Description"
  },
  input: {
    articlesA: "A-Articles",
    articlesB: "B-Articles",
    dimensions: "Dimensions",
    longTexts: "Long Texts"
  },
  error: {
    log_method: "Log Priority",
    log_message: "Log Message",
    log_methodOptions: {
      error: "Error",
      log: "Info",
      none: "None",
      warn: "Warning"
    }
  }
};
