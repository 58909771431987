export const plugins = {
  plugins: "Plugins",
  label: "Name",
  description: "Beschreibung",
  status: "Installationsstatus",
  statusInstalledBy: "Installiert von {user} am {date}",
  statusNotInstalled: "Nicht installiert",
  pluginDetails: "Plugin details",
  workflowElements: "Workflow-Elemente",
  eventSubscribers: "Event-Abonnenten",
  installedAt: "Installiert am",
  updatedAt: "Aktualisiert am",
  confirmInstallTitle: "Plugin installieren?",
  confirmInstallText:
    'Sind Sie sicher, dass Sie das Plugin "{plugin}" installieren möchten?',
  confirmUninstallTitle: "Plugin deinstallieren?",
  confirmUninstallText:
    'Sind Sie sicher, dass Sie das Plugin "{plugin}" deinstallieren möchten?',
  confirmInstallButton: "Plugin installieren",
  confirmUninstallButton: "Plugin deinstallieren",
  installTooltip: "Plugin installieren",
  uninstallTooltip: "Plugin deinstallieren",
  infoTooltip: "Plugin details",
  connectors: "Konnektoren"
};
