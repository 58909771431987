export const deltaStatus = {
  configuration: {
    status: "Markieren als",
    statusOptions: {
      clean: "Verarbeitet",
      dirty: "Nicht verarbeitet"
    }
  },
  input: {
    object: "Objekt"
  },
  error: {
    error_handling: "Fehlerbehandlung",
    error_handlingOptions: {
      process_continues: "Workflow läuft weiter",
      process_aborts: "Workflow bricht automatisch ab"
    }
  }
};
