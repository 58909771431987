export const workflowDesigner = {
  active: "Active",
  backToOverview: "Back to overview",
  backToReporting: "Back to reporting",
  baseData: "Basic information",
  billingFrom: "Billing from",
  billingTo: "Billing to",
  className: "Class name",
  classNameHelp: "Unique technical name of the workflow, e.g. 'ArticleImport'.",
  classNameHint: "No white spaces or special characters allowed.",
  clipboard: "Clipboard",
  clipboardClear: "Clear clipboard",
  columnComments: "Comments",
  columnConfiguration: "Configuration",
  columnData: "Data",
  columnElements: "Elements",
  columnLogging: "Logging",
  columnParameters: "Parameters",
  columnWorkflow: "Workflow",
  comment: "Comment",
  contentIdentifier: "Content identifier",
  contentIdentifierHelp: "What does the content identifier represent? ",
  copyParameter: "Click here to copy the parameters",
  create: "Upload workflow",
  createDataStructureJsonError: "{name} do not contain a valid JSON",
  cropIcon: "Crop icon",
  cropLogo: "Crop graphic",
  dangerZone: "Danger zone",
  dangerZoneDeleteTitle: "Delete workflow",
  dangerZoneDeleteText:
    "Are you sure you want to delete the workflow '{workflow}'? This action can not be undone.",
  dangerZoneText:
    "You are in the danger area. All actions you take here are irrevocable. ",
  daysSelected: "Days selected",
  deleteWorkflow: "Delete workflow",
  deleteWorkflowSubtitle: "Deleting a workflow is irrevocable.",
  description: "Description",
  dependency: "Dependencies",
  designer: "Designer",
  designerProcess: "Designer process",
  edit: "Configure workflow",
  dontConsiderInBilling: "Don't consider in billing",
  dropAreaText: "Place a graphic here to upload ",
  dropError: "Element cannot be placed here",
  elementsApiGateway: "API-Gateway",
  elementsCommunication: "Communication",
  elementsHelper: "Helper",
  elementsConnector: "Connectors",
  elementsData: "Data saving",
  elementsMapping: "Mappings",
  elementsOperator: "Operators",
  elementsTrigger: "Triggers",
  elementsClipboard: "Clipboard",
  execute: "Execute",
  executeImmediately: "Execute workflow immediately",
  externallyTriggerable: "Externally triggerable",
  externallyTriggerableHelp:
    "Choose if workflow should be able to get triggered by public URL.",
  fromElement: "from Element {value}",
  fromJob: "from Job {value}",
  hidePreview: "Hide preview",
  icon: "Icon",
  immediateProcess: "Immediate Process",
  immediateProcessHelp: "",
  jobs: "Jobs",
  label: "Label",
  logo: "Graphic",
  modeBuild: "Configuration",
  modeCode: "Code",
  modeDebug: "Debug",
  modePlanning: "Planning",
  name: "Label",
  noChildProcesses: "This workflow has no child processes.",
  noClipboardElements: "No elements were copied to clipboard yet.",
  noElementsOfType: "There were no elements found for this type.",
  none: "None",
  noJobs: "No jobs executed",
  noProject: "Not assigned to a project",
  noSelectData: "No data available",
  openCode: "Open code editor",
  openDesigner: "Open designer",
  openJournal: "Open journal",
  openReporting: "Open reporting",
  parameter: "Parameter",
  parametersPreview: "Preview",
  processStatusInfo: "Info",
  processStatusStopped: "Job completed successfully ",
  processStatusWarning: "Jobs partially successful ",
  processStatusPending: "Job waiting for execution",
  processStatusWorking: "Job is being executed",
  processStatusAborted: "Job has been aborted",
  processStatusError: "Job error",
  projectChoose: "Choose project",
  properties: "Properties",
  operatorBranch: "Branch",
  operatorChange: "Change operator",
  operatorConnection: "Simple connection",
  queue: "Queue",
  queueChoose: "Choose queue",
  queueChooseHelp: "On which queue should the workflow be executed by default?",
  recipientEmail: "Recipient Email",
  removeAll: "Remove all elements",
  removeIcon: "Remove icon" + "",
  removeLogo: "Remove graphic",
  reportingHeader: "Reporting workflow {workflow}",
  reportingHeaderDetail: "Logs job {jobId} workflow {workflow}",
  reset: "Reset",
  searchElements: "Search elements...",
  searchElementsNotFound: "There were no matching elements of this type found.",
  selectNextElement: "Next element",
  selectPlaceholder: "Select...",
  selectWidget: "Please select a widget from designer first.",
  setEndPoint: "Set end point",
  removeEndPoint: "Remove end point",
  saveAndTest: "Save and test",
  share: "Share",
  shared: "Workflow shared!",
  showParameter: "Show parameter",
  startProcessFirst: "Start workflow to show logs.",
  tabCode: "Code",
  tabCodePhp: "PHP code",
  tabStructure: "Structure",
  tabStructureYaml: "YAML structure",
  tabVisual: "Visual",
  testWorkflowTableTitle: "Latest jobs with parameters",
  testWorkflowTableDescription:
    "Insert the parameters with which the workflow is to be started here.",
  timeTooltip:
    "Use the format <b>2d 5h 30m</b><ul>\n" +
    "  <li>d = days</li>\n" +
    "  <li>h = hours (0-23)</li>\n" +
    "  <li>m = minutes (0-59)</li>\n" +
    "</ul>",
  token: "Token",
  tokenHelp:
    "Mit Hilfe eines oder mehrerer Token lässt sich eine eindeutige URL generieren, über welche der Besitzer vom System authentifiziert werden und somit den Workflow starten kann.",
  trigger: "Trigger",
  triggerChoose: "Choose trigger",
  triggerChooseHelp:
    "In which interval should the workflow start automatically?",
  triggerConfig: "Configure trigger",
  uploadIcon: "Upload icon",
  uploadLogo: "Upload graphic",
  triggerDisabledText: "This workflow is triggered by a preceding workflow.",
  type: "Dataset-type",
  typeHelp: "What type is the data record processed in the workflow?",
  workflowCancel: "Cancel jobs",
  workflow: "Workflow",
  workflowCreate: "Create Workflow",
  workflowDesigner: "Workflow designer",
  workflowSaved: "Workflow saved!",
  workflowStart: "Start workflow",
  workflowStarted: "Workflow started",
  workflowTest: "Test workflow",
  workflowChoose: "Vorausgehender Workflow",
  clearDesignerTitle: "Remove all elements",
  clearDesignerText:
    "By confirming this action, every element in this workflow will be removed. This process can only be reset, until the workflow gets saved the next time.",
  toggleCriticalPath: "Show/hide critical path",
  toggleMap: "Show/hide minimap",
  toggleGrid: "Show/hide grid",
  toggleFullscreen: "Enable/disable fullscreen",
  zoomIn: "Zoom in",
  zoomOut: "Zoom out",
  saveAsImage: "Save as image",
  clearWorkflow: "Remove all elements",
  inactive: "inactive",
  queueLow: "Low",
  queueHigh: "High",
  queueNone: "None",
  selectElement: "Select workflow element...",
  configureElement: "Configure {label}",
  showGitHistory: "Show git history",
  gitHistory: "Git History",
  modeHistory: "Git History",
  revertToThisChange: "Revert to this change",
  areYouSureYouWantToRevert: "Are you sure you want to revert ?",
  revert: "Revert",
  input: "Input parameter",
  output: "Output parameter",
  noDebugData: "No debug data",
  contactPerson: "Responsible Developer",
  validationError: "Validation error",
  queueSignal: "Warning: Queue-Interruption",
  queueSignalInfoText:
    "The processing of the queue was interrupted. As soon as all currently running processes have been processed, the queue workers are restarted and further jobs are processed again.",
  queueCurrentProcesses: "Currently running processes:",
  queueWarning: "Queue-Interruption",
  emailRecipient: "Recipient E-Mail",
  emailNotSending: "no mailing",
  emailOnlyErrors: "only errors",
  emailOnlyWarnings: "only warnings",
  emailWarningsErrors: "errors and warnings"
};
