import Vue from "vue";
import Swal from "sweetalert2";
import i18n from "@/core/plugins/vue-i18n";
import axios from "axios";

Vue.prototype.$swal = Swal;

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  showCloseButton: true,
  /*didOpen  for next version*/ onOpen: toast => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  }
});

const Error = function (error) {
  if (axios.isCancel(error)) {
    return;
  }
  let text = error?.response?.data?.message ?? i18n.t("error.default");
  Swal.fire({
    icon: "error",
    title: i18n.t("general.caution"),
    text: text,
    confirmButtonColor: "var(--secondary)"
  });
};

Vue.prototype.$toast = Toast;
Vue.prototype.$error = Error;
