export const msbcSoapConnector = {
  authentication: {
    connectorConfig_wsdl: "WSDL",
    connectorConfig_type: "Type der Authentifizierung",
    connectorConfig_typeOptions: {
      password: "Basic Auth",
      digest: "Digest",
      none: "Keine Authentifizierung"
    },
    connectorConfig_location: "Location / Endpunkt",
    connectorConfig_username: "Benutzername",
    connectorConfig_password: "Passwort",
    connectorConfig_tokenExpires: "Ablaufdatum des ContextToken (in Minuten)",
    connectorConfig_persistToken: "ContextToken speichern",
    connectorActionTestConnection: "Verbindung zur MS Business Central testen",
    connectorActionTestConnectionSuccess: "Verbindungsaufbau zu MS Business Central erfolreich",
    connectorActionTestConnectionError: "Fehler beim Verbindungsaufbau zu MS Business Central",
    connectorActionGetRequests: "Verfügbare Requests laden",
    connectorActionGetRequestsError: "Requests konnten nicht geladen werden",
    connectorActionGetRequestsSuccess: "Requests erfolreich geladen",
    version: "Konnektor-Version"
  },
  configuration: {
    request: "Request auswählen",
    connectorConfig_businessUnitId: "Geschäftseinheit des Mandanten",
    connectorConfig_languageId: "Sprach-Code",
    connectorConfig_options: "Weitere Optionen",
    connectorConfig_optionsValues: {
      key: "Schlüssel",
      value: "Wert"
    },
    dataInput: "Dateneingabe via",
    createRequestDataStructure: "Datenstruktur aus Request erstellen",
    createRequestDataStructureSuccess: "Datenstruktur erfolgreich angelegt",
    createRequestDataStructureError: "Fehler beim Erstellen der Datenstruktur"
  },
  input: {
    data: "Daten (Output-Variable)"
  },
  output: {
    createResponseDataStructure: "Datenstruktur aus Response erstellen",
    createResponseDataStructureError: "Fehler beim Erstellen der Datenstruktur",
    createResponseDataStructureSuccess: "Datenstruktur erfolgreich angelegt"
  }
}