const _ = require("lodash");

export const SET_ROUTE_PARAMS = "setRouteParams";
export const REMOVE_ROUTE_PARAMS = "removeRouteParams";

export const CLEAR_STATE = "clearState";

const getDefaultState = () => {
  return {
    params: {
      default: {}
      // Add param collections with prefix as key
      // prefix: {
      //    page: 5,
      //    perPage: 20,
      //    searchTerm: "test"
      // }
    }
  };
};
const requestProps = ["page", "perPage", "searchTerm"];

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    params:
      state =>
      (prefix = "") => {
        prefix = prefix || "default";
        return state.params[prefix];
      },
    requestParams:
      (state, getters) =>
      (prefix = "") => {
        let params = getters.params(prefix);
        return _.pick(params, requestProps);
      },
    cancelToken:
      (state, getters) =>
      (prefix = "") => {
        let cancel = getters.params(prefix)?.cancelToken;
        return cancel ? cancel.token : undefined;
      }
  },
  actions: {
    // [SET_ROUTE_PARAMS] payload = { (String) prefix = "", (Object) params }
    [SET_ROUTE_PARAMS](context, payload) {
      payload.prefix = payload.prefix || "default";
      context.commit(SET_ROUTE_PARAMS, payload);
    },
    // [REMOVE_ROUTE_PARAMS] payload = (String) = ""
    [REMOVE_ROUTE_PARAMS](context, payload) {
      payload = payload || "default";
      context.commit(REMOVE_ROUTE_PARAMS, payload);
    },
    [CLEAR_STATE](context) {
      context.commit(CLEAR_STATE);
    }
  },
  mutations: {
    [SET_ROUTE_PARAMS](state, payload) {
      let { prefix, params } = payload;
      state.params[prefix] = params;
    },
    [REMOVE_ROUTE_PARAMS](state, payload) {
      if (payload === "default") {
        state.params.default = {};
      } else {
        delete state.params[payload];
      }
    },
    [CLEAR_STATE](state) {
      Object.assign(state, getDefaultState());
    }
  }
};
