export const soap = {
  authentication: {
    password: "Passwort",
    testAuthentication: "Verbindung testen",
    testAuthenticationSuccess: "Verbindung testen erfolgreich",
    testAuthenticationError: "Verbindung testen fehlgeschlagen",
    type: "Typ der Authentifizierung",
    typeOptions: {
      0: "Basic",
      1: "Digest",
      none: "Keine Authentifizierung"
    },
    username: "Benutzername",
    wsdl: "WSDL"
  },
  configuration: {
    cacheWsdl: "WSDL cachen",
    cacheWsdlOptions: {
      0: "Ohne",
      1: "Disk",
      2: "Speicher",
      3: "Beide"
    },
    compression: "Komprimierung",
    compressionOptions: {
      0: "Accept",
      16: "GZIP",
      32: "Deflate"
    },
    endpoint: "Endpunkt",
    functions: "Funktionen",
    location: "Location",
    replace: "Ersetzen im Request",
    search: "Suchen im Request",
    soapVersion: "Version",
    soapVersionOptions: {
      1: "Soap 1.1",
      2: "Soap 1.2"
    },
    url: "URL"
  },
  input: {
    parameter: "Parameter (json)",
    testRequest: "Request testen",
    testRequestSuccess: "Request testen erfolgreich",
    testRequestError: "Request testen fehlgeschlagen"
  }
};
