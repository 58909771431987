export const dataSets = {
  addAggregation: "Transformer hinzufügen",
  addTransformer: "+ Transformer hinzufügen",
  addAndCondition: "+ und Bedingung",
  addConditionGroup: "+ und",
  addField: "+ Feld hinzufügen",
  addFilter: "Filter hinzufügen",
  addJoin: "Join hinzufügen",
  addOrCondition: "+ oder Bedingung",
  aggregations: "Transformer",
  alias: "Alias",
  clearAggregationHelp: "Transformer leeren",
  clearConditionHelp: "Bedingung leeren",
  configuration: "Konfiguration",
  create: "Anlegen",
  createDataSet: "DataSet anlegen",
  customLimit: "Limit",
  dataSets: "DataSets",
  dataStructureAlias: "Datenstruktur-Alias",
  deleteText:
    'Möchten Sie das DataSet "{name}" wirklich löschen? Dieser Vorgang ist unwiderruflich.',
  deleteStageText: 'Möchten Sie die Stage "{name}" wirklich löschen?',
  deleteTitle: "DataSet löschen",
  deletedSuccess: "DataSet wurde erfolgreich gelöscht",
  description: "Beschreibung",
  editor: "Editor",
  fieldData: "Daten",
  fieldPlaceholder: "Feld suchen",
  fields: "Felder",
  filter: "Filter",
  foreignField: "Foreign Field",
  invalidDataStructure: "Bitte wählen Sie eine Datenstruktur aus",
  invalidField: "Bitte fügen Sie mindestens ein Feld hinzu.",
  limit: "Limit",
  localField: "Local Field",
  name: "Name",
  noDataAvailable: "Keine Daten verfügbar",
  noDataStructureSelected: "Keine Datenstrukur ausgewählt",
  noFieldsSelected: "Es muss mindestens ein Feld hinzugefügt werden",
  nullText: "[NULL]",
  on: "On",
  openEditor: "Editor öffnen",
  preview: "Vorschau",
  removeAggregationHelp: "Transformer entfernen",
  removeConditionHelp: "Bedingung entfernen",
  removeFieldHelp: "Feld entfernen",
  savedSuccess: "DataSet wurde erfolgreich gespeichert",
  schema: "Schema",
  selectAggregation: "Transformer",
  selectDataStructure: "Datenstruktur auswählen",
  selectField: "Feld auswählen",
  selectOperator: "Operator auswählen",
  selectValue: "Wert eingeben",
  testStage: "Stage testen",
  title: "Titel",
  parameter: "Parameter",
  config: "Config",
  parameterCreate: "Parameter erstellen",
  parameterCreated: "Parameter erstellt",
  parameterUpdated: "Parameter aktualisiert",
  addParameter: "Parameter hinzufügen",
  deleteParameter: 'Möchten Sie den Parameter "{name}" wirklich löschen?'
};
