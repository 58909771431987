export const dashboard = {
  aboutThisArea: "Über diesen Bereich",
  welcomeToAdmin: "Willkommen im Adminbereich",
  welcomeToPartner: "Willkommen im Partnerbereich",
  welcomeToTenant: "Willkommen im Mandantenbereich",
  welcomeToProject: "Willkommen im Projektbereich",
  adminShortDescription:
    "Verwalten Sie Partner, Mandanten und alle systemspezifischen Konfigurationsparameter.",
  partnerShortDescription:
    "Verwalten Sie Ihre Unternehmensdaten, Ihre Mandanten, partnerspezifische Konfigurationseinstellungen und vieles mehr.",
  tenantShortDescription:
    "Verwalten Sie Ihre Unternehmensdaten, Benutzer, Projekte, Einstellungen und Ihre Abrechnungen.",
  projectShortDescription:
    "Verwalten Sie Ihre Workflows, Integrationen und vieles mehr.",
  adminDescription:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus cum, doloremque doloribus explicabo impedit ipsam " +
    "libero minus. Architecto asperiores aspernatur atque blanditiis cupiditate eligendi et expedita, facere hic ipsam iusto " +
    "aborum mollitia nemo nulla odio officiis possimus quis ratione reiciendis, similique, suscipit unde vero voluptatibus.",
  partnerDescription:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus cum, doloremque doloribus explicabo impedit ipsam " +
    "libero minus. Architecto asperiores aspernatur atque blanditiis cupiditate eligendi et expedita, facere hic ipsam iusto " +
    "aborum mollitia nemo nulla odio officiis possimus quis ratione reiciendis, similique, suscipit unde vero voluptatibus.",
  tenantDescription:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus cum, doloremque doloribus explicabo impedit ipsam " +
    "libero minus. Architecto asperiores aspernatur atque blanditiis cupiditate eligendi et expedita, facere hic ipsam iusto " +
    "aborum mollitia nemo nulla odio officiis possimus quis ratione reiciendis, similique, suscipit unde vero voluptatibus.",
  projectDescription:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus cum, doloremque doloribus explicabo impedit ipsam " +
    "libero minus. Architecto asperiores aspernatur atque blanditiis cupiditate eligendi et expedita, facere hic ipsam iusto " +
    "aborum mollitia nemo nulla odio officiis possimus quis ratione reiciendis, similique, suscipit unde vero voluptatibus.",
  yediNews: "yedi News",
  hello: "Hallo",
  welcomeText: "Herzlich Willkommen zurück im yedi-Dashboard!",
  quickLinks: "Quick Links",
  loadMore: "Mehr laden",
  allNewsLoaded: "Alle News geladen.",
  noNewsYet: "Es gibt noch keine Neuigkeiten!",
  widgetAdd: "Widget hinzufügen"
};
