export const menu = {
  adminAccount: "Account",
  adminAccountData: "Company data",
  adminAccountUsers: "User administration",
  adminNews: "News",
  adminNotifications: "Notifications",
  adminPartners: "Partner administration",
  adminPartnersOverview: "Overview",
  adminSettings: "Settings",
  adminSettingsSystem: "System",
  adminSettingsServer: "Server",
  adminSettingsBilling: "Billing",
  adminSettingsEmail: "Email",
  adminSettingsNewsletter: "Newsletter",
  adminSettingsExtensions: "Extensions",
  adminSettingsPlugins: "Plugins",
  adminTenants: "Tenant administration",
  adminTenantsOverview: "Overview",
  analysis: "Analysis",
  analysisJobs: "Jobs",
  analysisJournal: "Journal",
  analysisNotifications: "Notifications",
  analysisOverview: "Overview",
  analysisQueues: "Queues",
  analysisWorkflows: "Workflows",
  analysisWorkflowsLogs: "Logs",
  areaAdmin: "Admin area",
  areaChange: "Change area",
  areaPartner: "Partner area",
  areaPlaceholder: "Select area",
  areaProject: "Project area",
  areaTenant: "Tenant area",
  dashboard: "Dashboard",
  help: "Help",
  helpCenter: "Help center",
  helpContactPerson: "Contact person",
  helpDocumentation: "Documentation",
  helpSupport: "Support",
  logout: "Logout",
  partnerAccount: "Account",
  partnerAccountAccounting: "Accounting",
  partnerAccountContracts: "Contracts",
  partnerAccountData: "Company data",
  partnerAccountUsers: "User administration",
  partnerLeads: "Leads",
  partnerProfile: "Partner profile",
  partnerProfileCertificates: "Certificates",
  partnerProfileContact: "Contact person",
  partnerProfileCredentials: "Credentials",
  partnerProfileInformation: "Profile information",
  partnerProfileServices: "Service",
  partnerTenants: "Tenant administration",
  partnerTenantsOverview: "Overview",
  partnerSettings: "Settings",
  projects: "Projects",
  projectInsightsVisualization: "Insights visualizations",
  projectIntegrations: "Integrations",
  projectIntegrationsOverview: "Overview",
  projectIntegrationsDynamicModels: "Dynamic models",
  projectDataStructures: "Data Structures",
  projectMappings: "Mappings",
  projectTextTemplates: "TextTemplates",
  projectWorkflows: "Workflows",
  projectJournal: "Journal",
  projectDataLake: "Datalake",
  projectCommunication: "Communication",
  projectDataStore: "Data Store",
  projectDataExplorer: "DataExplorer",
  projectDataSets: "DataSets",
  projectReporting: "Reporting",
  projectSettings: "Settings",
  projectSettingsChangeBatches: "Change batches",
  projectSettingsDataStructure: "Data structures",
  projectSettingsExtensions: "Extensions",
  projectSettingsMails: "Emails",
  projectSettingsProjects: "Projects",
  projectSettingsQueues: "Queues",
  projectSettingsRoles: "Roles",
  projectSettingsResources: "Resources",
  projectSettingsMailTemplates: "Email receivers",
  projectSettingsMailSettings: "Email settings",
  projectSettingsComponents: "Components",
  projectSettingsConfiguration: "Configurations",
  projectSettingsWorkflowToken: "Token",
  projectNotes: "Notes",
  projectsOverview: "Overview",
  tenantAccounting: "Accounting",
  tenantAccountingInformation: "General information",
  tenantAccountingAddress: "Invoice address",
  tenantAccountingInvoices: "Invoice archive",
  tenantAccountingPayments: "Payment methods",
  tenantAccountingTicketing: "Ticketing",
  tenantContracts: "Contracts",
  tenantContractsDocuments: "Contract documents",
  tenantContractsStatus: "Contract status",
  tenantData: "Company data",
  tenantUserAdministration: "User administration",
  tenantSettings: "Settings",
  tools: "Tools",
  toolsDataLake: "Data lake",
  toolsDataLakeDynamicModels: "Dynamic models",
  toolsDataStore: "Data store",
  toolsMarketplaceConnection: "Marketplace connection",
  toolsOrderManagement: "Order management",
  welcome: "Welcome",
  toolsQueryEditor: "Query-Editor",
  workflows: "Workflows",
  workflowsComponents: "Components",
  workflowsConnectors: "Connectors",
  workflowsDesigner: "Designer",
  workflowsDynamicModels: "Dynamic Models",
  workflowsMapping: "Mapping",
  workflowsOverview: "Overview",
  workflowsStaging: "Staging",
  adminSettingsCountries: "Countries",
  adminSettingsMailTemplates: "Email templates",
  adminMediaManagement: "Media Management",
  adminSettingsDesignerElements: "Designer elements",
  adminSettingsDataStore: "Datastore"
};
