export const settings = {
  name: "Name",
  settings: "Einstellungen",
  settingCreate: "Einstellung erstellen",
  nameEnter: "Namen eingeben",
  label: "Bezeichnung",
  type: "Typ",
  value: "Wert",
  settingCreated: "Einstellung erstellt",
  settingUpdate: "Einstellung bearbeiten",
  settingUpdated: "Einstellung aktualisiert",
  settingsUpdated: "Einstellungen gespeichert",
  settingDeleteMessage:
    'Möchten Sie die "{setting}" wirklich löschen? Dieser Vorgang ist unwiderruflich.',
  settingDeleteTitle: "Einstellung löschen",
  settingDeleted: "Einstellung erfolgreich gelöscht",
  mailTemplates: "E-Mail Vorlagen",
  baseSettings: "Basiseinstellungen",
  numberRanges: "Nummernkreise",
  tenantNumberRange: "Nummernkreis für Kundennummern",
  partnerNumberRange: "Nummernkreis für Partnernnummern",
  loginSecurity: "Anmeldesicherheit",
  settingNameUpdated: '"{setting}" wurde aktualisiert',
  settingNameNotUpdated: '"{setting}" kann nicht aktualisiert werden',
  languages: "Sprachen",
  countries: "Länder",
  securityMaxAttempts: "Maximale Versuche bis zur Drosselung",
  securityLockMinutesAfterMaxAttempts: "Drosselung für X Minuten",
  securityMaxAttemptsBeforeLock: "Maximale Versuche vor dem Sperren des Kontos",
  securityCheckIp: "Blockiere alle IPs außer denen in der Benutzer-Whitelist",
  securityCheckRouteIp: "Blockiere alle IPs außer denen in der Route-Whitelist",
  securityTwoFaCheck: "Zwei-Faktor-Authentifizierung aktiviert",
  securityTwoFaCodeExpireAfterMinutes:
    "Gültigkeit in Minuten für die Zwei-Faktor-Authentifizierungscode",
  twoFaDeviceExpireAfterDays:
    "Gültigkeit in Tagen für die gespeicherten Geräte",
  workspaceDomain: "Arbeitsbereichsdomäne",
  system: "System",
  server: "Server",
  email: "E-Mail",
  newsletter: "Newsletter",
  extensions: "Erweiterungen",
  workflowElements: "Workflow Elemente",
  dataStructure: "Datenstrukturen",
  billing: "Abrechnung",
  archiving: "Archivierung",
  keepLogForDays: "Logs archivieren",
  day: "Tag",
  days: "Tage",
  month: "Monat",
  months: "Monate",
  zipLogArchives: "Archiv-Dateien nach einem Monat in ZIP-Archiv packen",
  keepLogArchiveForMonths: "Log Archive aufbewahren",
  processIterationNumberRange: "Nummernkreis Prozessdurchläufe",
  processLogNumberRange: "Nummernkreis Prozess-Logs",
  supervisorManagment: "Supervisor Verwaltung",
  editIps: "IP-Adressen bearbeiten",
  supervisorRamAll: "RAM Verbrauch",
  supervisorActiveTenantsActiveQueues: "Aktive Queues aktiver Tenants",
  supervisorActiveTenantsAllQueues: "Alle Queues aktiver Tenants",
  supervisorAllTenantsActiveQueues: "Aktive Queues aller Tenants",
  supervisorAllTenantsAllQueues: "Alle Queues aller Tenants",
  supervisorRamTenants: "RAM Verbrauch pro Tenant",
  supervisorActiveQueueMemory: "Aktive Tenant Queues",
  supervisorTotalQueueMemory: "Alle Tenant Queues"
};
