export const mappingTransformers = {
  StringReplace: {
    search: "Search for",
    replace: "Replace with"
  },
  CaseConvert: {
    type: "Type",
    upper: "Convert to uppercase",
    lower: "Conversion to lower case",
    method_upper: "Upper Method",
    method_lower: "Lower Method",
    strtoupper: "strtoupper",
    ucfirst: "ucfirst",
    ucwords: "ucwords",
    strtolower: "strtolower",
    lcfirst: "lcfirst"
  },
  CharTrimmer: {
    type: "Type",
    trim: "Trim",
    rtrim: "Right Trim",
    ltrim: "Left Trim",
    character: "Character"
  },
  HtmlEntities: {
    type: "Type",
    htmlentities: "htmlentities",
    htmlspecialchars: "htmlspecialchars",
    html_entity_decode: "html_entity_decode",
    htmlspecialchars_decode: "htmlspecialchars_decode"
  },
  DateFormatter: {
    timestamp: "Timestamp",
    date: "Date",
    type: "Type",
    date_format: "Date format"
  },
  NumberFormatter: {
    decimals: "Number of decimals",
    decimal_separator: "Decimal separator",
    thousands_separator: "Thousands separator"
  },
  Pad: {
    length: "Length",
    pad_string: "Pad string",
    pad_type: "Type",
    1: "Pad right",
    0: "Pad left",
    2: "Pad both"
  },
  StringRepeater: {
    type: "Type",
    input: "input",
    string: "String Text",
    string_text: "String text",
    repeat_times: "Repeat times"
  },
  CharPosition: {
    type: "Type",
    search: "Search",
    offset: "Offset",
    strpos: "strpos",
    stripos: "stripos",
    strripos: "strripos",
    strrpos: "strrpos"
  },
  StringPart: {
    offset: "Offset",
    length: "length"
  },
  Round: {
    round_type: "Round Type",
    ceil: "ceil",
    floor: "floor"
  },
  ExtremeValue: {
    extreme_type: "Type",
    min: "min",
    max: "max"
  },
  TagStripper: {
    allowed_tags: "Allowed tags"
  },
  Concat: {
    char: "Char"
  },
  Cast: {
    type: "Type",
    int: "int",
    float: "float",
    bool: "bool",
    string: "string"
  },
  Sort: {
    direction: "Direction",
    asc: "Ascendent",
    desc: "Descending",
    sortBy: "Sort by",
    collection: "Collection"
  },
  BasicArithmetic: {
    type: "Type",
    add: "Add",
    subtract: "Subtract",
    divide: "Divide",
    multiply: "Multiply",
    modulo: "Modulo",
    moduloFirstField: "Input 1",
    moduloSecondField: "Input 2"
  },
  Collection: {
    type: "Art der Zusammenführung",
    multiply: "Multiplikation",
    sum: "Summe",
    assign: "Zuordnung",
    collection: "Collection",
    valueCount: "Anzahl",
    uniqueValues: "Einzigartige Werte"
  },
  ValueAssignment: {
    dataType: "Data type",
    string: "string",
    float: "float",
    int: "int",
    bool: "bool",
    text: "text",
    date: "date",
    null: "null",
    json: "json",
    stringInput: "String",
    intInput: "Number",
    floatInput: "Float",
    boolInput: "Bool",
    true: "true",
    false: "false",
    jsonInput: "JSON",
    textInput: "Text Input",
    dateInput: "Use the function NOW() for the date"
  }
};
