export const notes = {
  notes: "Notes",
  title: "Title",
  note: "Note",
  noteCreate: "Create note",
  noteEdit: "Edit note",
  type: "Type",
  typeId: "Type ID",
  createdText: "Note '{title}' has been created",
  updatedText: "Note '{title}' has been updated",
  deleteNoteQuestion: "Are you sure you want to delete the note '{title}'?",
  deletedText: "The note '{title}' has been deleted"
};
