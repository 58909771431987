export const csvBatch = {
  configuration: {
    archiveCsvFile: "Archive CSV-file",
    batchSize: "Batch size",
    dataStructure: "Datastructure",
    delimiter: "Delimiter",
    encoding: "Encoding",
    help: {
      type: "CSV file evaluation based on a data structure or a manual configuration."
    },
    limit: "Limit",
    type: "Type",
    typeOptions: {
      dataStructure: "Datastructure",
      manually: "Manually"
    }
  },
  input: {
    csvPath: "CSV-Pfad",
    help: {
      csvPath: 'Please enter a relative path (e.g. "in/test.csv").'
    }
  },
  error: {
    error_handling: "Error handling",
    error_handlingOptions: {
      process_continues: "Workflow continues",
      process_aborts: "Workflow aborts"
    }
  }
};
