export const fileAccess = {
  configuration: {
    type: "Typ",
    typeOptions: {
      read: "Lesen",
      write: "Schreiben"
    }
  },
  input: {
    content: "Inhalt",
    filePath: "Verzeichnis",
    fileName: "Dateiname",
    searchPattern: "Suchmuster",
    testData: "Suchen",
    testDataSuccess: "Daten erfolgreich getestet",
    rootPath: "Root path"
  },
  fileManager: "Dateimanager",
  fileManagerOpen: "Dateimanager öffnen",
  applySelectedFileTree: "Übernehmen"
};
