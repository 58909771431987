<template>
  <div></div>
</template>

<script>
import { bus } from "@/main";
import JwtService from "@/core/services/jwt.service";
import Pusher from "pusher-js";

export default {
  data() {
    return {
      pusher: null,
      userInfo: null,
      loggedIn: false
    };
  },
  created() {
    this.$root.$refs.Pusher = this;
  },
  mounted() {
    this.checkAuth();
    this.subscribe();

    bus.$on("loggedIn", this.onLoggedIn);
    bus.$on("loggedOut", this.onLoggedOut);
  },
  beforeDestroy() {
    bus.$off("loggedIn", this.onLoggedIn);
    bus.$off("loggedOut", this.onLoggedOut);
  },
  methods: {
    onLoggedIn(user) {
      this.userInfo = user;
      this.loggedIn = true;
      this.subscribe();
    },
    onLoggedOut() {
      this.loggedIn = false;
      if (!this.pusher) {
        return;
      }
      this.pusher.disconnect();
      this.pusher = null;
    },
    subscribe() {
      if (
        this.pusher ||
        !this.loggedIn ||
        !this.userInfo ||
        !this.userInfo.uuid
      ) {
        return false;
      }
      this.pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        wsHost: process.env.VUE_APP_PUSHER_HOST,
        wsPort: process.env.VUE_APP_PUSHER_PORT,
        forceTLS: false,
        encrypted: true,
        disableStats: true,
        enabledTransports: ["ws", "wss"]
      });
      this.pusher.subscribe(this.userInfo.uuid);
      this.pusher.bind("silent", ws => {
        bus.$emit("silent_notification", ws.data);
      });
    },
    checkAuth() {
      this.loggedIn = !!JwtService.getToken();
      this.userInfo = this.$store.getters.userData ?? null;
    }
  }
};
</script>
