import Vue from "vue";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}

export const LOAD_CONFIG_VALUES = "loadConfigValues";

export const SET_CUSTOM_VARIABLES = "setCustomVariables";
export const REMOVE_CUSTOM_VARIABLES = "removeCustomVariables";

export default {
  namespaced: true,
  state: {
    configValues: [],
    custom: {}
  },
  getters: {
    configValues: state => {
      return state.configValues;
    },
    configValueById: state => id => {
      return state.configValues.find(cv => cv.id === id);
    },
    configValueByName: state => name => {
      return state.configValues.find(cv => cv.name === name);
    },
    customVariables: state => name => {
      return state.custom[name] ?? [];
    }
  },
  actions: {
    [LOAD_CONFIG_VALUES](context) {
      ApiService.get(url, "config_values?noPagination=true", {
        headers: {
          "x-api-key": store.getters.apiToken
        }
      })
        .then(response => {
          context.commit(LOAD_CONFIG_VALUES, response.data.data);
        })
        .catch(error => {
          console.error(error);
        });
    },
    /**
     * Sets a set of custom variables by key
     * @param context
     * @param {Object} payload
     * @param {string} payload.name - Unique identifier for custom variables
     * @param {any} payload.variables - Your custom variables
     */
    [SET_CUSTOM_VARIABLES](context, payload) {
      context.commit(SET_CUSTOM_VARIABLES, payload);
    },
    /**
     * Remove a set of custom variables
     * @param context
     * @param {string} payload - Name of custom variables set
     */
    [REMOVE_CUSTOM_VARIABLES](context, payload) {
      context.commit(REMOVE_CUSTOM_VARIABLES, payload);
    }
  },
  mutations: {
    [LOAD_CONFIG_VALUES](state, payload) {
      state.configValues = payload;
    },
    [SET_CUSTOM_VARIABLES](state, payload) {
      Vue.set(state.custom, payload.name, payload.variables);
    },
    [REMOVE_CUSTOM_VARIABLES](state, payload) {
      Vue.delete(state, payload);
    }
  }
};
