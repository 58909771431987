export const dashboard = {
  aboutThisArea: "About this area",
  welcomeToAdmin: "Welcome to the admin area",
  welcomeToPartner: "Welcome to the partner area",
  welcomeToTenant: "Welcome to the tenant area",
  welcomeToProject: "Welcome to the project area",
  adminShortDescription:
    "Manage partner, tenants and system configuration parameters.",
  partnerShortDescription:
    "Manage company data, your tenants, partner specific configuration und much more.",
  tenantShortDescription:
    "Manage company data, users, projects, settings and your billings.",
  projectShortDescription: "Manage your workflows and processes.",
  adminDescription:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus cum, doloremque doloribus explicabo impedit ipsam " +
    "libero minus. Architecto asperiores aspernatur atque blanditiis cupiditate eligendi et expedita, facere hic ipsam iusto " +
    "aborum mollitia nemo nulla odio officiis possimus quis ratione reiciendis, similique, suscipit unde vero voluptatibus.",
  partnerDescription:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus cum, doloremque doloribus explicabo impedit ipsam " +
    "libero minus. Architecto asperiores aspernatur atque blanditiis cupiditate eligendi et expedita, facere hic ipsam iusto " +
    "aborum mollitia nemo nulla odio officiis possimus quis ratione reiciendis, similique, suscipit unde vero voluptatibus.",
  tenantDescription:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus cum, doloremque doloribus explicabo impedit ipsam " +
    "libero minus. Architecto asperiores aspernatur atque blanditiis cupiditate eligendi et expedita, facere hic ipsam iusto " +
    "aborum mollitia nemo nulla odio officiis possimus quis ratione reiciendis, similique, suscipit unde vero voluptatibus.",
  projectDescription:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus cum, doloremque doloribus explicabo impedit ipsam " +
    "libero minus. Architecto asperiores aspernatur atque blanditiis cupiditate eligendi et expedita, facere hic ipsam iusto " +
    "aborum mollitia nemo nulla odio officiis possimus quis ratione reiciendis, similique, suscipit unde vero voluptatibus.",
  yediNews: "yedi News",
  hello: "Hello",
  welcomeText: "Welcome back to yedi dashboard!",
  quickLinks: "Quick links",
  loadMore: "Load more",
  allNewsLoaded: "All news loaded.",
  noNewsYet: "No news yet!",
  widgetAdd: "Add widget"
};
