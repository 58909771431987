export const soapConnector = {
  authentication: {
    connectorActionGetRequests: "Funktionen laden",
    connectorActionGetRequestsSuccess: "Funktionen erfolgreich geladen ",
    connectorActionGetRequestsError: "Laden der Funktionen fehlgeschlagen",
    connectorClass: "Konnektorklasse",
    connectorConfig_wsdl: "WSDL",
    testConnection: "Verbindung testen",
    testConnectionSuccess: "Verbindung testen erfolgreich",
    testConnectionError: "Verbindung testen fehlgeschlagen"
  },
  output: {
    createResponseDataStructure: "Datenstruktur erstellen",
    createResponseDataStructureSuccess: "Datenstruktur erstellen erfolgreich",
    createResponseDataStructureError: "Datenstruktur erstellen fehlgeschlagen"
  },
  configuration: {
    connectorConfig_options: "Optionen",
    connectorConfig_optionsValues: {
      key: "Key",
      value: "Wert"
    },
    createRequestDataStructure: "Datenstruktur erstellen",
    createRequestDataStructureSuccess: "Datenstruktur erstellen erfolgreich",
    createRequestDataStructureError: "Datenstruktur erstellen fehlgeschlagen",
    request: "Funktion"
  }
};
