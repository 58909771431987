export const user = {
  cancelCreate: "Cancel user creation",
  actions: "Actions",
  admin: "Admin",
  adminAdministration: "Admin administration",
  adminDeleteMessage:
    'Do you really want to delete the user "{user}"? This process is irreversible.',
  adminDeleteTitle: "Delete user",
  adminsManagement: "Admins Management",
  avatar: "Avatar",
  avatarChange: "Change avatar",
  avatarRemove: "Remove avatar",
  baseData: "Base data",
  company: "Company",
  cropProfilePicture: "Crop profile picture",
  dataProvideCorrectly: "Please provide correct data!",
  dropFilesAnywhereToUpload: "Drop files anywhere to upload",
  dropFilesToUpload: "Drop files to upload",
  email: "E-Mail",
  enterEmail: "Please enter the user email address",
  enterFirstName: "Please enter the user first name",
  enterLastName: "Please enter the user last name",
  enterPassword: "Please enter the user password",
  enterPasswordConfirmation: "Please enter the same password again",
  enterSalutation: "Please enter the form of salute",
  enterTitle: "Please enter the title",
  firstName: "Firstname",
  imageUpload: "Image upload",
  informationContact: "Contact information",
  informationPersonal: "Personal information",
  informationPersonalUpdate: "Change personal information",
  informationUser: "User information",
  language: "Language",
  lastName: "Lastname",
  mail: "Email address",
  mailEnter: "Enter email address",
  mailShort: "Email",
  myProfile: "My Profile",
  myProfileDesc: "Account settings and more",
  name: "Name",
  nameEnter: "Enter name",
  notAPicture: "Your choice is not a picture",
  password: "Password",
  passwordChangeDescription: "Change password for account",
  passwordChangeTitle: "Change password",
  passwordConfirmation: "Password confirmation",
  passwordCurrentRequired: "Current password required",
  passwordEnter: "Enter password",
  passwordNew: "New password",
  passwordNewConfirm: "Confirm new password",
  passwordNewConfirmRequired: "Confirmation of new password is required",
  passwordNewRequired: "New password required",
  passwordNotMatching: "Given passwords do not match.",
  passwordWrong: "Wrong password",
  profile: "Profile",
  profilePicture: "Profile picture",
  removeProfilePicture: "Remove profile picture",
  required: "Required",
  reviewAndSubmit: "Review and submit",
  role: "Role",
  salutation: "Salutation",
  saveImageChanges: "Save image changes",
  selectLanguage: "Please select which language will be assigned to the user",
  selectRole: "Please select which role will be assigned to the user",
  sendWelcomeEmail: "Send welcome email",
  title: "Title",
  updateBaseDataAndProfilePicture: "Update base data and profile picture",
  updateProfile: "Update profile",
  uploadAndCropImage: "Upload and crop image",
  uploadProfilePicture: "Upload profile picture",
  user: "User",
  adminCreate: "Create user",
  adminCreated: "User created",
  adminDeleted: "User deleted successfully",
  adminUpdate: "Update user",
  adminUpdated: "User updated",
  validFileTypes: "Valid file types",
  allUsers: "All users"
};
