export const xml = {
  configuration: {
    help: {
      type: "Analyze xml file by data structure or by configuration"
    },
    selector: "Target XML tag",
    forceArrayOn: "Force array on",
    forceArrayOnValues: {
      name: "XML-Tag"
    },
    dataStructure: "Data structure",
    type: "Analysis",
    typeOptions: {
      manually: "Manually",
      dataStructure: "Data structure"
    },
    archiveXmlFile: "Archive xml file"
  },
  input: {
    help: {
      xmlPath: 'Please provide a relative path (e.g. "in/test.xml").'
    },
    xmlPath: "XML file"
  },
  error: {
    error_handling: "Error handling",
    error_handlingOptions: {
      process_continues: "Workflow continues",
      process_aborts: "Workflow aborts"
    }
  }
};
