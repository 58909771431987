import Partners from "@/components/Admins/Partners/partners";
import Clients from "@/components/Admins/Clients/clients";
import Projects from "@/components/Projects/Settings/Projects/project";
import ApiService from "@/core/services/api.service";

// action types
export const UPDATE_USER_TYPE = "updateUserType";

export const UPDATE_SELECTED_PARTNER = "updateSelectedPartner";
export const UPDATE_SELECTED_PARTNER_BY_ID = "updateSelectedPartnerById";
export const UPDATE_SELECTED_CLIENT = "updateSelectedClient";
export const UPDATE_SELECTED_CLIENT_BY_ID = "updateSelectedClientById";
export const UPDATE_SELECTED_PROJECT = "updateSelectedProject";

export const UPDATE_API_TOKEN = "updateApiToken";
export const UPDATE_API_TOKEN_PARTNER = "updateApiTokenPartner";

export const LOAD_PARTNERS = "loadPartners";
export const LOAD_CLIENTS = "loadClients";
export const LOAD_PROJECTS = "loadProjects";

// mutation types
export const SET_PARTNERS = "setPartners";
export const SET_CLIENTS = "setClients";
export const SET_PROJECTS = "setProjects";

export const SET_USER_TYPE = "setUserType";
export const SET_SELECTED_PARTNER = "setSelectedPartner";
export const SET_SELECTED_CLIENT = "setSelectedClient";
export const SET_SELECTED_PROJECT = "setSelectedProject";

export const SET_API_TOKEN = "setApiToken";
export const SET_API_TOKEN_PARTNER = "setApiTokenPartner";

export const SET_BUSY_PARTNERS = "setBusyPartners";
export const SET_BUSY_CLIENTS = "setBusyClients";
export const SET_BUSY_PROJECTS = "setBusyProjects";

export const APP_VERSION = process.env.VUE_APP_VERSION;

export default {
  state: {
    partners: [],
    clients: [],
    projects: [],

    userType: "",
    selectedPartner: null,
    selectedClient: null,
    selectedProject: null,

    skipProject: false,

    apiToken: "",
    apiTokenPartner: "",

    isBusy: {
      partners: false,
      clients: false,
      projects: false
    }
  },
  getters: {
    partners(state) {
      return state.partners;
    },
    clients(state) {
      return state.clients;
    },
    projects(state) {
      return state.projects;
    },

    userType(state) {
      return state.userType;
    },
    selectedPartner(state) {
      return state.selectedPartner;
    },
    selectedClient(state) {
      return state.selectedClient;
    },
    selectedProject(state) {
      return state.selectedProject;
    },
    skipProject(state) {
      return state.skipProject;
    },
    apiToken(state) {
      return state.apiToken;
    },
    apiTokenPartner(state) {
      return state.apiTokenPartner;
    },
    busyPartners(state) {
      return state.isBusy.partners;
    },
    busyClients(state) {
      return state.isBusy.clients;
    },
    busyProjects(state) {
      return state.isBusy.projects;
    }
  },
  actions: {
    [UPDATE_USER_TYPE](context, payload) {
      context.commit(SET_USER_TYPE, payload);
    },
    [UPDATE_SELECTED_PARTNER](context, payload) {
      context.commit(SET_SELECTED_PARTNER, payload);
      this.dispatch(LOAD_CLIENTS);
    },
    async [UPDATE_SELECTED_PARTNER_BY_ID](context, payload) {
      const partner = this.getters.partners.find(p => p.id === payload);
      context.commit(SET_SELECTED_PARTNER, partner);
      await this.dispatch(LOAD_CLIENTS);
    },
    [UPDATE_SELECTED_CLIENT](context, payload) {
      context.commit(SET_SELECTED_CLIENT, payload);
      this.dispatch(LOAD_PROJECTS);
    },
    [UPDATE_SELECTED_CLIENT_BY_ID](context, payload) {
      const client = this.getters.clients.find(c => c.id === payload);
      context.commit(SET_SELECTED_CLIENT, client);
      this.dispatch(LOAD_PROJECTS);
    },
    [UPDATE_SELECTED_PROJECT](context, payload) {
      context.commit(SET_SELECTED_PROJECT, payload);
    },
    [UPDATE_API_TOKEN](context, payload) {
      context.commit(SET_API_TOKEN, payload);
    },
    [UPDATE_API_TOKEN_PARTNER](context, payload) {
      context.commit(UPDATE_API_TOKEN_PARTNER, payload);
    },

    [LOAD_PARTNERS](context) {
      context.commit(SET_BUSY_PARTNERS, true);

      const params = {
        title: "",
        page: 1,
        noPagination: true
      };
      Partners.getAll(params)
        .then(response => {
          context.commit(SET_PARTNERS, response.data.data);
          context.commit(SET_BUSY_PARTNERS, false);

          if (this.getters.selectedPartner) {
            this.dispatch(LOAD_CLIENTS);
          }
        })
        .catch(() => {
          context.commit(SET_BUSY_PARTNERS, false);
        });
    },
    async [LOAD_CLIENTS](context) {
      if (!this.getters.selectedPartner) {
        context.commit(SET_SELECTED_CLIENT, null);
        context.commit(SET_CLIENTS, []);
        context.commit(SET_PROJECTS, []);
        context.commit(SET_SELECTED_PROJECT, null);
        return;
      }
      context.commit(SET_BUSY_CLIENTS, true);
      const params = {
        title: "",
        page: 1,
        noPagination: true
      };
      await Clients.getAll(params, this.getters.selectedPartner.id)
        .then(response => {
          context.commit(SET_CLIENTS, response.data.data);
          context.commit(SET_BUSY_CLIENTS, false);

          if (this.getters.selectedClient) {
            this.dispatch(LOAD_PROJECTS);
          }
        })
        .catch(() => {
          context.commit(SET_BUSY_CLIENTS, false);
        });
    },
    [LOAD_PROJECTS](context, setProject = true) {
      if (!this.getters.selectedClient && APP_VERSION < 2) {
        context.commit(SET_PROJECTS, []);
        context.commit(SET_SELECTED_PROJECT, null);
        return;
      }
      context.commit(SET_BUSY_PROJECTS, true);
      const params = {
        title: "",
        page: 1,
        noPagination: true
      };
      let apiKey = "Bearer " + this.getters.selectedClient?.api_token ?? '';

      Projects.getAll(params, apiKey)
        .then(response => {
          context.commit(SET_PROJECTS, response.data.data);
          if (setProject && response.data.data.length === 1) {
            let project = response.data.data[0];
            project.autoSet = true;
            context.commit(SET_SELECTED_PROJECT, project);
          }
          context.commit(SET_BUSY_PROJECTS, false);
        })
        .catch(() => {
          context.commit(SET_BUSY_PROJECTS, false);
        });
    }
  },
  mutations: {
    [SET_PARTNERS](state, payload) {
      state.partners = payload;
    },
    [SET_CLIENTS](state, payload) {
      state.clients = payload;
    },
    [SET_PROJECTS](state, payload) {
      state.projects = payload;
    },

    [SET_BUSY_PARTNERS](state, payload) {
      state.isBusy.partners = payload;
    },
    [SET_BUSY_CLIENTS](state, payload) {
      state.isBusy.clients = payload;
    },
    [SET_BUSY_PROJECTS](state, payload) {
      state.isBusy.projects = payload;
    },
    [SET_API_TOKEN](state, payload) {
      state.apiToken = payload;
    },
    [SET_API_TOKEN_PARTNER](state, payload) {
      state.apiTokenPartner = payload;
    },

    [SET_USER_TYPE](state, payload) {
      state.userType = payload;
    },
    [SET_SELECTED_PARTNER](state, payload) {
      if (payload) {
        payload.partnerId = payload.id;
        payload.partnerName = payload.name;

        if (state.selectedPartner && state.selectedPartner.id !== payload.id) {
          this.commit(SET_SELECTED_CLIENT, null);
          this.commit(SET_SELECTED_PROJECT, null);
        }
      }
      state.selectedPartner = payload;

      if (!payload) {
        state.apiTokenPartner = "";
        ApiService.setHeader("x-api-key", "");
        return;
      }

      state.apiTokenPartner = "Bearer " + payload.api_token;
      state.apiToken = "Bearer " + payload.api_token;
      ApiService.setHeader("x-api-key", "Bearer " + payload.api_token);
      ApiService.setHeader("x-partner-key", "Bearer " + payload.api_token);
    },
    [SET_SELECTED_CLIENT](state, payload) {
      if (payload) {
        payload.clientId = payload.id;
        payload.clientName = payload.name;

        if (state.selectedClient && state.selectedClient.id !== payload.id) {
          this.commit(SET_SELECTED_PROJECT, null);
        }
      }

      state.selectedClient = payload;

      if (!payload) {
        state.apiToken = state.apiTokenPartner;
        ApiService.setHeader(
          "x-api-key",
          ApiService.getHeader("x-partner-key")
        );
        return;
      }

      state.apiToken = "Bearer " + payload.api_token;
      ApiService.setHeader("x-api-key", "Bearer " + payload.api_token);
    },
    [SET_SELECTED_PROJECT](state, payload) {
      if (payload) {
        payload.shortName = payload.short;
      }

      state.selectedProject = payload;

      if (!payload) {
        state.skipProject = true;
        return;
      }

      state.skipProject = false;
    }
  }
};
