export const time = {
  configuration: {
    schedule_config: "Config",
    schedule_config_dayOfMonth: "Tag",
    schedule_config_dayOfWeek: "Wochentag",
    schedule_config_hour: "Stunde",
    schedule_config_minute: "Minute",
    schedule_config_minuteOptions: {
      "*": "Jede Minute",
      everyXMinutes: "Jede X Minuten"
    },
    schedule_config_month: "Monat",
    schedule_config_monthOptions: {
      "*": "Jeden Monat",
      1: "Januar",
      2: "Februar",
      3: "März",
      4: "April",
      5: "Mai",
      6: "Juni",
      7: "Juli",
      8: "August",
      9: "September",
      10: "Oktober",
      11: "November",
      12: "Dezember"
    },
    schedule_config_dayOfMonthOptions: {
      "*": "Jeden Tag im Monat"
    },
    schedule_config_dayOfWeekOptions: {
      "*": "Jeden Tag in der Woche",
      1: "Montag",
      2: "Dienstag",
      3: "Mittwoch",
      4: "Donnerstag",
      5: "Freitag",
      6: "Samstag",
      7: "Sonntag"
    },
    schedule_config_hourOptions: {
      "*": "Jede Stunde",
      everyXHours: "Jede X Stunden"
    },
    schedule_config_everyXMinutes: "Alle X Minuten Config",
    schedule_config_everyXHours: "Alle X Stunden Config",
    schedule_config_everyXHoursOptions: {
      "*": "Jede Minute",
      everyXMinutes: "Jede x Minute"
    },
    schedule_configValues: {
      day: "Tag",
      days: "Tage",
      endTime: "Endzeit",
      expression: "Ausdruck",
      first: "Erster Zeitpunkt",
      offset: "Offset",
      position: "Position",
      second: "Zweiter Zeitpunkt",
      startTime: "Startzeit",
      time: "Zeit",
      timezone: "Zeitzone",
      value: "Wert",
      minutes: "Minuten",
      hours: "Stunden"
    },
    schedule_method: "Method",
    schedule_methodOptions: {
      cron: "Cronjob",
      dailyAt: "Täglich zu bestimmter Uhrzeit",
      everyXHours: "Alle x Stunden",
      everyXMinutes: "Alle x Minuten",
      hourlyAt: "Stündlich zu bestimmter Uhrzeit",
      twiceDaily: "Zwei mal täglich"
    }
  }
};
