export const countries = {
  countries: "Länder",
  countryCreate: "Land anlegen",
  countryCreated: "Land wurde erfolgreich angelegt",
  countryCreateText: "Ein neues Land zur Übersicht hinzufügen.",
  countryDelete: "Land löschen",
  countryDeleted: "Land wurde erfolgreich entfernt",
  countryDeleteText:
    'Möchten Sie das Land "{name}" wirklich unwiderruflich löschen?',
  countryUpdate: "Land bearbeiten",
  countryUpdated: "Land wurde erfolgreich aktualisiert",
  countryUpdateText: "Ein Land überarbeiten.",
  disableTax: "Steuer deaktivieren",
  euMember: "EU-Mitglied",
  iso: "ISO-Code",
  name: "Bezeichnung",
  nameInternational: "Internationale Bezeichnung",
  tax: "Steuersatz (in %)"
};
