export const externalTrigger = {
  authentication: {
    code: "Code",
    token: "Token",
    type: "Typ",
    typeOptions: {
      token: "Token",
      auth: "Auth"
    },
    url: "URL"
  }
};
