import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import {
  UPDATE_LANGUAGE,
  UPDATE_PERSONAL_INFO,
  UPDATE_USER_DATA
} from "@/core/services/store/profile.module";
import {
  UPDATE_SELECTED_CLIENT,
  UPDATE_SELECTED_PARTNER,
  UPDATE_USER_TYPE
} from "@/core/services/store/switchArea.module";
import { bus } from "@/main";

let url = process.env.VUE_APP_API_ADMIN;
let urlTenant = process.env.VUE_APP_API_TENANT;

if (process.env.VUE_APP_VERSION >= 2) {
  urlTenant = url;
}

const adminWorkspace = process.env.VUE_APP_ADMIN_WORKSPACE;

class Auth {
  async adminLoginCheck(authData) {
    this.resetUser();
    let returnData = {
      success: false,
      data: ""
    };
    await ApiService.post(url + "/auth/login", authData)
      .then(response => {
        returnData.success = true;
        returnData.data = response.data;
      })
      .catch(error => {
        returnData = {
          success: false,
          data: error.response.data
        };
      });

    if (returnData.success) {
      JwtService.saveToken(returnData.data.meta.token);
      await store.dispatch(UPDATE_USER_DATA, returnData.data.data);
      await store.dispatch(
        UPDATE_LANGUAGE,
        returnData.data.data.language.short
      );
      await store.dispatch(UPDATE_USER_TYPE, "admin");

      this.updateUserData(returnData.data.data);
      ApiService.setHeader();

      ApiService.setHeader("x-api-key", store.getters.apiToken);
      ApiService.setHeader("x-partner-key", store.getters.apiTokenPartner);
    }
    return returnData;
  }
  async login(authData) {
    this.resetUser();
    let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}
    let workspace = authData.email.split("@");
    workspace = workspace[1];
    var returnData = {
      success: false,
      data: ""
    };
    ApiService.setHeader("x-workspace", workspace);
    await ApiService.post(url + "/auth/login", authData)
      .then(response => {
        returnData.success = true;
        returnData.data = response.data;
        returnData.headers = response.headers;
        returnData.tenant = returnData.data.meta.tenant;
      })
      .catch(error => {
        returnData = {
          success: false,
          data: error.response.data
        };
      });

    if (returnData.success) {
      JwtService.saveToken(returnData.data.meta.token);
      returnData.tenant.tenantable.api_token = returnData.tenant.api_token;
      await store.dispatch(UPDATE_USER_DATA, returnData.data.data);
      await store.dispatch(
        UPDATE_LANGUAGE,
        returnData.data.data.language.short
      );
      if (returnData.tenant.tenantable_type.toLowerCase().includes("partner")) {
        await store.dispatch(UPDATE_USER_TYPE, "partner");
        await store.dispatch(
          UPDATE_SELECTED_PARTNER,
          returnData.tenant.tenantable
        );
        ApiService.setHeader("x-partner-key", returnData.headers["x-api-key"]);
      } else {
        await store.dispatch(UPDATE_USER_TYPE, "client");
        await store.dispatch(UPDATE_SELECTED_CLIENT, returnData.tenant);
      }
      ApiService.setHeader("x-api-key", returnData.headers["x-api-key"]);
      this.updateUserData(returnData.data.data);
    }
    return returnData;
  }
  verify() {
    let returnData = {
      success: false,
      user: "",
      token: ""
    };
    if (JwtService.getToken()) {
      returnData.token = JwtService.getToken();
      returnData.success = true;
    } else {
      JwtService.destroyToken();
    }
    this.updateUserData();
    return returnData;
  }
  logout() {
    this.resetUser();
    bus.$emit("loggedOut");
    JwtService.destroyToken();
  }
  resetUser() {
    // reset user data
    localStorage.removeItem("x-workspace");
    localStorage.removeItem("x-api-key");
    localStorage.removeItem("x-api-key-partner");

    localStorage.removeItem("selectedClientData");
    localStorage.removeItem("selectedPartnerData");
    localStorage.removeItem("selectedProjectData");

    localStorage.removeItem("skipProject");
    ApiService.removeAllHeaders();
  }
  updateUserData(userData) {
    if (typeof userData === "undefined") {
      return;
    }
    const id = userData.id;
    const uuid = userData.uuid;
    const name = userData.core_data?.firstname ?? userData.name;
    const surname = userData.core_data?.lastname ?? "";
    const phone = "";
    const email = userData.email;
    const profile_picture =
      userData.profile_picture ?? "/media/users/blank.png";
    const language = userData.language.label;
    const language_id = userData.language.id;

    const storeUserData = {
      id,
      uuid,
      name,
      surname,
      phone,
      email,
      profile_picture,
      language,
      language_id
    };
    store.dispatch(UPDATE_PERSONAL_INFO, storeUserData);
    bus.$emit("loggedIn", userData);
  }
  async getMeTenant(optionalHeaders = {}) {
    return await ApiService.get(urlTenant, "auth/me/tenant", optionalHeaders);
  }
  getUserType() {
    return store.getters.userType;
  }
  async twoFaCheck(authData) {
    if (authData.workspace === process.env.VUE_APP_ADMIN_WORKSPACE) {
      return this.adminLoginCheck(authData);
    }
    return this.login(authData);
  }
  getApiVersion() {
    return ApiService.get(url);
  }
  sendPasswordResetLink(data) {
    let isAdmin = this.checkIsAdminWorkspace(data.email);
    if (!isAdmin) {
      let workspace = data.email.split("@");
      workspace = workspace[1];
      ApiService.setHeader("x-workspace", workspace);
    }
    return ApiService.post(
      (isAdmin ? url : urlTenant) + "/auth/password/email",
      data
    );
  }
  resetPassword(data) {
    let isAdmin = this.checkIsAdminWorkspace(data.email);
    if (!isAdmin) {
      let workspace = data.email.split("@");
      workspace = workspace[1];
      ApiService.setHeader("x-workspace", workspace);
    }

    return ApiService.post(
      (isAdmin ? url : urlTenant) + "/auth/password/reset",
      data
    );
  }
  checkIsAdminWorkspace(email) {
    let workspace = email.split("@")[1];
    return workspace === adminWorkspace;
  }
}

export default new Auth();
