export const dataExplorer = {
  confirm: "Bestätigen",
  cancel: "Abbrechen",
  dataExplorer: "DataExplorer",
  dataExplorerResources: "DataExplorer - Ressourcen",
  dataStructures: "Datenstrukturen",
  dataSets: "DataSets",
  dataStructure: "Datenstruktur",
  dataSet: "DataSet",
  noDescription: "Keine Beschreibung vorhanden",
  details: "Details",
  preview: "Vorschau",
  schema: "Schema",
  entriesCount: "Datensätze",
  dirtyEntriesCount: "Unverarbeitete Datensätze",
  lastUpdated: "Letzte Änderung",
  editDataSet: "DataSet bearbeiten",
  editDataStructure: "Datenstruktur bearbeiten",
  truncateDataStructure: "Datenstruktur leeren",
  setCleanDataStructure: "Alle Einträge auf verarbeitet setzen",
  setDirtyDataStructure: "Alle Einträge auf unverarbeitet setzen",
  setCleanDataStructureEntry: "Einträg auf verarbeitet setzen",
  setDirtyDataStructureEntry: "Einträg auf unverarbeitet setzen",
  truncateText:
    'Möchten Sie die Datenstruktur "{name}" wirklich leeren? Dieser Vorgang ist unwiderruflich.',
  truncateTitle: "Datenstruktur leeren",
  setCleanText:
    'Möchten Sie wirklich alle Datensätze der Datenstruktur "{name}" auf verarbeitet setzen? Dieser Vorgang ist unwiderruflich.',
  setCleanSuccess: "Einträge auf verarbeitet gesetzt",
  setCleanTitle: "Datenstruktur bearbeiten",
  setDirtyText:
    'Möchten Sie wirklich alle Datensätze der Datenstruktur "{name}" auf unverarbeitet setzen? Dieser Vorgang ist unwiderruflich.',
  setDirtySuccess: "Einträge auf unverarbeitet gesetzt",
  setDirtyTitle: "Datenstruktur bearbeiten",
  filter: "Filter",
  dirty: "Unverarbeitet",
  clean: "Verarbeitet",
  noFilter: "Filter entfernen",
  openDetails: "Daten einsehen",
  deletedSuccess: "Alle Einträge wurde erfolgreich gelöscht",
  dataStructureFromRequest: "Datenstruktur (Request)"
};
