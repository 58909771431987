export const serverInfo = {
  buffered: "Puffer",
  clientDb: "Kunden Datenbank",
  free: "Frei",
  idle: "Idle",
  nice: "Nice",
  sys: "Sys",
  total: "Insgesamt",
  usageCpu: "CPU Auslastung",
  usageDisk: "Festplatten Auslastung",
  usageRam: "RAM Auslastung",
  used: "Belegt",
  user: "User"
};
