export const grantedIps = {
  active: "Aktiv",
  addNewEntry: "Neuen Eintrag zur IP-Whitelist hinzufügen",
  confirmDeleteTitle: "Route erteilten IP-Eintrag löschen",
  confirmDeleteTextR: "Möchten Sie die IPs wirklich löschen?",
  deleteSuccessText: "IP-Eintrag erfolgreich gelöscht",
  description: "Beschreibung",
  grantedIpCreated: "Gewährter IP-Eintrag erstellt",
  grantedIpsNeededToSave:
    "Um das Blockiere alle IPs außer denen in der Benutzer-Whitelist zu aktivieren, muss mindestens eine IP-Adresse eingetragen sein",
  grantedIpUpdated: "Gewährter IP-Eintrag aktualisiert",
  grantedIps: "Gewährte IP-Adressen",
  ipAddresses: "IP-Adressen",
  ipAddressesToolTip:
    "Tragen Sie hier die IP-Adressen ein, für die der Zugriff gewährt werden soll. Tragen Sie ein *-Symbol ein, um den Zugriff für alle IP-Adressen zu gewähren.",
  public: "Benutzer-Beschränkung deaktivieren",
  publicTooltip:
    "Soll die Freigabe nur für bestimmte Benutzer oder öffentlich erfolgen?",
  route: "Beschränken auf Route",
  routeTooltip:
    "Geben Sie hier den freizugebenden Pfad ohne Host-Anteil an (z.B. /route/myRoute)",
  saveTitle: "Benutzer-Whitelist aktivieren",
  saveWarning:
    "Ihre aktuell genutzte IP-Adresse ist nicht in der Whitelist. Bitte beachten Sie, dass Sie sich durch das Aktivieren der Whitelist-Option aus dem System aussperren können.",
  updateEntry: "Eintrag aktualisieren",
  user: "Beschränken auf Benutzer",
  userTooltip: "Wählen Sie hier die freizugebenden Benutzer aus"
};
