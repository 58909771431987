export const email = {
  authentication: {
    host: "Host",
    password: "Passwort",
    port: "Port",
    smtpAuth: "SMTP",
    ssl: "SSL",
    type: "Typ der Authentifizierung",
    typeOptions: {
      smtp: "SMTP",
      system: "System"
    },
    username: "Benutzername"
  },
  configuration: {
    from_name: "Absender Name",
    from_mail: "Absender E-Mail",
    reply_name: "Antwort an Name",
    reply_mail: "Antwort an E-Mail",
    template: "Mailtemplate"
  },
  input: {
    subject: "Betreff",
    to: "Empfänger",
    toValues: {
      address: "Adresse",
      name: "Name"
    },
    cc: "CC",
    ccValues: {
      address: "Adresse",
      name: "Name"
    },
    bcc: "BCC",
    bccValues: {
      address: "Adresse",
      name: "Name"
    },
    content: "Inhalt",
    attachments: "Anhänge"
  },
  output: {},
  error: {
    log_method: "Log Priorität",
    log_methodOptions: {
      error: "error",
      log: "log",
      none: "none",
      warn: "warn"
    },
    log_levelOptions: {
      0: "0 - Debugging deaktivieren",
      1: "1 - Vom Client gesendete Nachrichten ausgeben.",
      2: "2 - als 1 plus vom Server empfangene Antworten (dies ist die nützlichste Einstellung).",
      3: "3 - als 2, plus weitere Informationen über die anfängliche Verbindung - diese Ebene kann bei der Diagnose von STARTTLS-Fehlern helfen.",
      4: "4 - als 3, plus noch Informationen auf niedrigerer Ebene, sehr ausführlich, nicht zum Debuggen von SMTP verwenden, nur Probleme auf niedriger Ebene."
    },
    log_message: "Log Nachricht",
    trigger_process: "Workflow",
    log_level: "Log Level"
  }
};
