import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

import auth from "@/components/General/Auth/auth";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import languages from "./languages.module";
import profile from "./profile.module";
import menu from "./menu.module";
import dataStructure from "./dataStructure.module";
import workflowDesigner from "./workflowDesigner.module";
import switchArea from "./switchArea.module";
import process from "./process.module";
import processDebug from "./processDebug.module";
import dataSets from "./dataSets.module";
import mapping from "./mapping.module";
import mappingTransformer from "./mappingTransformer.module";
import route from "./route.module";
import variables from "./variables.module";

const ls = new SecureLS({ isCompression: true });

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    languages,
    profile,
    menu,
    dataStructure,
    workflowDesigner,
    switchArea,
    process,
    processDebug,
    dataSets,
    mapping: mapping,
    mappingTransformer: mappingTransformer,
    route: route,
    variables: variables
  },
  plugins: [
    createPersistedState({
      paths: [
        "switchArea.userType",
        "switchArea.selectedPartner",
        "switchArea.selectedClient",
        "switchArea.selectedProject",
        "switchArea.skipProject",
        "switchArea.apiToken",
        "switchArea.apiTokenPartner",
        "profile.userData",
        "profile.user_personal_info",
        "process.testProcessData"
      ],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ]
});
