export const role = {
  description: "Description",
  name: "Name",
  nameDisplay: "Display name",
  roleCreate: "Create role",
  roleCreated: "Role created",
  roleDeletedTitle: "Role deleted!",
  roleDeletedDescription: "Role was deleted successfully!",
  roleDeleteTitle: "Delete Role",
  roleDeleteDescription:
    'Do you really want to delete the role "{name}"? This process is irreversible.',
  roleUpdate: "Update role",
  roleUpdated: "Role updated",
  roles: "Roles",
  selectClient: "Please select a tenant first."
};
