export const shopware5Connector = {
  authentication: {
    connectorConfig_url: "Host",
    connectorConfig_username: "Username",
    connectorConfig_password: "API key",
    connectorActionTestConnection: "Test connection to shopware 5 instance",
    connectorActionTestConnectionSuccess: "Connection successfully",
    connectorActionTestConnectionError: "Connection attempt failed",
    version: "Connector version",
    connectorConfig_apiVersion: "Version of shopware instance"
  },
  configuration: {
    request: "Request",
    requestConfig_queryParameters: "Query parameters",
    requestConfig_queryParametersValues: {
      key: "Key",
      value: "Value"
    },
    requestConfig_headers: "Header parameters",
    requestConfig_headersValues: {
      key: "Key",
      value: "Value"
    },
    requestConfig_additionalConfig: "Additional parameters",
    requestConfig_additionalConfigValues: {
      key: "Key",
      value: "Value"
    },
    dataInput: "Provide data via",
    createRequestDataStructure: "Create datastructure from request",
    createRequestDataStructureSuccess: "Datastructure created successfully",
    createRequestDataStructureError: "Failed to create datastructure",
    requestConfig_id: "Id",
    requestConfig_useNumberAsId: "Use number as Id"
  },
  output: {
    createResponseDataStructure: "Create datastructure from response",
    createResponseDataStructureError: "Failed to create datastructure",
    createResponseDataStructureSuccess: "Datastructure created successfully"
  }
};
