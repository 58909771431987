export const internalTrigger = {
  input: {
    parameter: "Parameter",
    parameterValues: {
      key: "Name",
      value: "Wert"
    }
  },
  configuration: {
    process: "Prozess",
    reloadProcesses: "Prozessliste aktualisieren",
    delayType: "Künstliche Verzögerung",
    delayTypeOptions: {
      delay: "Zeit",
      fixedTime: "Bestimmte Zeit"
    },
    delay: "Verzögerung",
    delayOptions: {
      0: "Keine Verzögerung",
      300: "5 Minuten",
      900: "15 Minuten",
      1800: "30 Minuten",
      3600: "1 Std.",
      7200: "2 Std.",
      14400: "4 Std.",
      28800: "8 Std."
    },
    delayDate: "Feste Zeit"
  }
};
