export const processManager = {
  processManager: "Process Manager",
  name: "Name",
  deleteConfirm: 'Delete process "{process}" ?',
  deleteConfirmText:
    'Do you really want to delete the process "{process}"? You cannot restore this after delete.',
  deleteSucessText: "Process {process} has been deleted",
  editProcess: "Edit Process",
  nameEnter: "Enter name",
  className: "Class name",
  frequency: "Frequency",
  leadTime: "Lead time",
  externallyTriggerable: "Externally triggerable",
  externalTrigger: "External trigger",
  lastRunned: "Last runned",
  lastExecutedOn: "Last executed on",
  clickToggleMoreDetails: "Click to toggle more details",
  baseClassPath: "Base class path",
  processUpdated: "Process Updated",
  processProcUpdated: 'Process "{process}" has been updated',
  temporaryClassName: "Temporary class name",
  done: "Done",
  processCreate: "Create process",
  processCreated: "Process created",
  jsonAsParameter: "JSON can be given as a parameter here",
  test: "Test",
  processTestStarted: "Process test started",
  bulkDeleteProcessIdJobs:
    "All Jobs for the Process '{process}' will be deleted.",
  queue: "Queue",
  deleteProcessTooltip: "Cancel jobs in this process",
  runProcessTooltip: "Run process",
  testProcessTooltip: "Test process",
  processStarted: "Process started",
  yesCancel: "Confirm cancellation",
  noCancel: "Abort cancellation",
  requeue: "Requeue job",
  jobCancel: "Cancel job",
  jobRequeued: "Job was successfully requeued!",
  invalidJson: "Invalid JSON",
  testProcess: 'Test process "{name}"',
  testProcessNoName: "Test process",
  immediateProcess: "Immediate Process",
  copyAboveCodeToActualCode:
    "Copy and paste the code above into the actual code"
};
