export const communication = {
  bcc: "BCC",
  cc: "CC",
  code: "Code",
  content: "Inhalt",
  dateFrom: "Datum von",
  dateTo: "Datum bis",
  datetime: "Datum/Uhrzeit",
  details: "Details",
  email: "E-Mail Adresse",
  filter: "Filter",
  information: "Allgemeine Information",
  mailableClassname: "Klassenname",
  parameter: "Parameter",
  preview: "Vorschau",
  receiver: "Empfänger",
  recipient: "Empfänger",
  replyTo: "Antwort an",
  search: "Suchen",
  selectedFilter: "Ausgewählte Filter",
  selectFilter: "Filter auswählen",
  sender: "Sender",
  subject: "Betreff",
  title: "Kommunikation",
  toDetail: "Zur Detailseite"
};
