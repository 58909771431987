export const journal = {
  entry: "Eintrag",
  title: "Journal",
  general: "Allgemein",
  jobReportingDetails: "Job Bericht Details",
  logs: "Logs",
  jobsTimeline: "Jobs timeline",
  contentIdentifier: "Inhaltskennung",
  periodFilter: "Zeitraum Filter",
  workflowFilter: "Workflow Filter",
  workflow: "Workflow",
  selectWorkflow: "Alle Workflows",
  statusFilter: "Status Filter",
  selectStatus: "Alle",
  noDataFound: "Keine Daten mit den ausgewählten Suchkriterien gefunden",
  period: "Zeitraum",
  twentyFourHours: "24 Std.",
  lastWeek: "Letzte Woche",
  lastMonth: "Letzten Monat",
  searchFilter: "Suchfilter",
  enterContentIdentifier: "Eintrag eingeben",
  searchLogs: "Logs durchsuchen"
};
