export const projects = {
  deleted: "Projekt '{name}' gelöscht.",
  description: "Beschreibung",
  finishedAt: "Enddatum",
  generalInformation: "Information",
  image: "Bild",
  informationProject: "Projektinformation",
  loadImage: "Bild laden",
  name: "Name",
  noDateSelected: "Kein Datum ausgewählt",
  noImageFound: "Kein Bild gefunden",
  removeProject: "{name} entfernen?",
  project: "Projekt",
  projectCreate: "Projekt anlegen",
  saved: "Projekt '{name}' gespeichert.",
  short: "Kürzel",
  startedAt: "Startdatum",
  title: "Projekte",
  url: "URL",
  workflows: "Workflows"
};
