export const workflowToken = {
  codeSnippet: "Code Snippet",
  create: "Token anlegen",
  createTitle: "Token anlegen",
  deleted: "Token '{name}' gelöscht.",
  details: "Tokendetails",
  name: "Name",
  noTokens: "Keine Token gefunden",
  remove: "Token '{name}' entfernen?",
  saved: "Token '{name}' gespeichert.",
  title: "Token",
  token: "Token",
  tokenPlaceholder: "Bitte wählen Sie mindestens einen Token aus",
  whitelistIps: "Whitelist IP's",
  whitelistingActive: "IP-Überprüfung aktiviert"
};
