export const textTemplates = {
  textTemplates: "TextTemplates",
  type: "Typ",
  textTemplateCreate: "TextTemplate erstellen",
  textTemplateDeleted: "TextTemplate wurde gelöscht",
  deleteTextTemplateTitle: 'TextTemplate "{title}" löschen?',
  deleteTextTemplateText:
    "Möchten Sie das TextTemplate wirklich endgültig löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.",
  textTemplateSaved: "TextTemplate wurde gespeichert",
  textTemplateUpdated: "TextTemplate wurde aktualisiert",
  textTemplateLabel: "Bezeichnung",
  textTemplateSave: "Speichern",
  variables: "Parameter",
  string: "Text",
  edit: "TextTemplate Bearbeiten",
  dataStructure: "DataStructure",
  dataStructureList: "DataStructure Liste",
  variablesHelp:
    "Parameter können im TextTemplate genutzt werden, um dynamische Daten auszugeben.",
  addVariable: "Parameter hinzufügen",
  detailsBasicConfiguration: "Basiskonfiguration",
  templateSaved: "Template wurde gespeichert!",
  download: "Herunterladen",
  valid: "Datei valide",
  invalid: "Datei nicht valide",
  otherFileType: "Sonstige",
  name: "Name",
  datastructure: "Datenstruktur"
};
